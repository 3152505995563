import React from "react";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";

import offer from "../../assets/docs/oferta.pdf";
import privacy from "../../assets/docs/politics.pdf";

export default function Footer() {
    const navigate = useNavigate();
    const {t} = useTranslation();

    return (
        <footer>
            <div className="fotter-area d-dark-bg footer__space">
                <div className="footer__top pt-80 pb-15">
                    <div className="container">
                        <div className="row">
                            <div className="col-xl-3 col-lg-3 col-md-3 col-sm-3 ">
                                <div className="footer__widget">
                                    <div className="footer__widget-title">
                                        <h4>{t("footer.customers")} </h4>
                                    </div>
                                    <div className="footer__widget-content">
                                        <div className="footer__link">
                                            <ul>
                                                <li>
                                                    <p onClick={() => navigate("/payments")}>
                                                        {t("footer.waysPayment")}
                                                    </p>
                                                </li>
                                                <li>
                                                    <p onClick={() => navigate("/return-product")}>
                                                        {t("footer.returnProduct")}
                                                    </p>
                                                </li>
                                                <li>
                                                    <p onClick={() => navigate("/rules")}>
                                                        {t("footer.rules")}
                                                    </p>
                                                </li>
                                                <li>
                                                    <p onClick={() => navigate("/delivery")}>
                                                        {t("footer.deliveryCondition")}
                                                    </p>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-3 col-lg-3 col-md-3 col-sm-3 ">
                                <div className="footer__widget">
                                    <div className="footer__widget-title">
                                        <h4>{t("footer.company")}</h4>
                                    </div>
                                    <div className="footer__widget-content">
                                        <div className="footer__link">
                                            <ul>
                                                <li>
                                                    <p onClick={() => navigate("/about")}>
                                                        {t("footer.aboutUs")}
                                                    </p>
                                                </li>
                                                <li>
                                                    <p onClick={() => navigate("/contacts")}>
                                                        {t("footer.contact")}
                                                    </p>
                                                </li>
                                                <li>
                                                    <p onClick={() => navigate("/vacancies")}>
                                                        {t("footer.vacancies")}
                                                    </p>
                                                </li>
                                                <li>
                                                    <a className="download" href={offer} download>
                                                        {t("offer")}
                                                    </a>
                                                </li>
                                                <li>
                                                    <a className="download" href={privacy} download>
                                                        {t("privacy")}
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-3 col-lg-3 col-md-3 col-sm-3 ">
                                <div className="footer__widget">
                                    <div className="footer__widget-title">
                                        <h4>{t("footer.weSocialMedia")}</h4>
                                    </div>
                                    <div className="footer__widget-content">
                                        <div className="footer__link">
                                            <div className="row">
                                                <li className="col-2">
                                                    <a href="https://instagram.com/1q.market/">
                                                        <img
                                                            className="footer__social"
                                                            src={require("../../assets/icons/Insta.png")}
                                                            alt=""
                                                        />
                                                    </a>
                                                </li>
                                                <li className="col-2">
                                                    <a href="https://wa.me/message/RKFKXZ2XEVZGL1">
                                                        <img
                                                            className="footer__social"
                                                            src={require("../../assets/icons/whatsapp.png")}
                                                            alt=""
                                                        />
                                                    </a>
                                                </li>
                                                <li className="col-2">
                                                    <a href="http://t.me/QTACompany">
                                                        <img
                                                            className="footer__social"
                                                            src={require("../../assets/icons/telegram.png")}
                                                            alt=""
                                                        />
                                                    </a>
                                                </li>
                                                <li className="col-2">
                                                    <a href="https://www.youtube.com/">
                                                        <img
                                                            className="footer__social"
                                                            src={require("../../assets/icons/youtube.png")}
                                                            alt=""
                                                        />
                                                    </a>
                                                </li>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-3 col-lg-3 col-md-3 col-sm-3 ">
                                <div className="footer__widget">
                                    <div className="footer__widget-title">
                                        <h4>{t("footer.mobileApps")}</h4>
                                    </div>
                                    <div className="footer__widget-content">
                                        <div className="footer__link">
                                            <ul>
                                                <li>
                                                    <a href="https://play.google.com/store/apps/developer?id=QTA+Company">
                                                        {" "}
                                                        <img
                                                            className="footer__apps"
                                                            src={require("../../assets/icons/google-play.png")}
                                                            alt=""
                                                        />
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="https://apps.apple.com/kz/app/1q/id1667609992">
                                                        {" "}
                                                        <img
                                                            className="footer__apps"
                                                            src={require("../../assets/icons/app-store.png")}
                                                            alt=""
                                                        />
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="text-center footer__widget-title">
                            <h4>{t("footer.footerTitle")}</h4>
                            <div className="footer__link">
                                <a href="faq.html">
                                    {" "}
                                    <img
                                        className="footer__cart"
                                        src={require("../../assets/icons/visa-logo-2.jpg")}
                                        alt=""
                                    />
                                </a>

                                <a href="faq.html">
                                    <img
                                        className="footer__cart"
                                        src={require("../../assets/icons/mastercard.jpg")}
                                        alt=""
                                    />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
}
