import React, { useEffect, useState } from "react";
import Header from "../../components/Header";
import "./Favorite.scss";
import { Modal, Button } from "react-bootstrap";
import { useNavigate, useLocation } from "react-router-dom";
import Loader from "../../components/Loader";
import API from "../../api/apiController";
import { ACTIVE_BACK_LINK } from "../../utils/constants";
import { ACTIVE_FAILURE_LINK } from "../../utils/constants";
import { useTranslation } from "react-i18next";

export default function Cart() {
  const [loading, setLoading] = useState(false);
  const [products, setProducts] = useState([]);
  const [modalShow, setModalShow] = React.useState(false);
  const [balance, setBalance] = useState(0);
  const [notification, setNotification] = React.useState("");
  const [cards, setCards] = useState([]);
  const [amount, setAmount] = useState(0);
  const [errMsg, setErrMsg] = React.useState("");
  const [flat, setFlat] = useState("");
  const [house, setHouse] = useState("");
  const [street, setStreet] = useState(null);
  const [modal, setModal] = useState(false);
  const { t } = useTranslation();
  const lang = localStorage.getItem("lang");

  const [checked, setChecked] = useState([]);
  const [price, setPrice] = useState(0);
  const [selectAll, setSelectAll] = useState(false);
  const navigate = useNavigate();
  const [photoArr, setPhotoArr] = useState([]);

  const deleteCart = (item, event) => {
    event.preventDefault();
    event.stopPropagation();
    let params = {
      productId: item.product.id,
    };
    let newList = [];
    API.shopCart
      .deleteCart(params)
      .then(async (response) => {
        console.log("checked", checked);
        console.log("id", item.shopCardId);

        if (checked.includes(item.shopCardId.toString())) {
          setPrice(price - item.product.price * item.count);
          console.log(
            "checked.indexOf(item.shopCardId)",
            checked.indexOf(item.shopCardId.toString())
          );
          newList = checked.filter((id) => id !== item.shopCardId.toString());
          console.log(newList);
          setChecked(checked.filter((id) => id !== item.shopCardId.toString()));
          console.log(checked);
        } else {
          getShopCart();
        }
      })
      .finally(getShopCart);
  };

  useEffect(() => {
    setLoading(true);
    fetchData();
    API.user
      .getBalance((response) => response.json())
      .then((responseJson) => {
        setBalance(responseJson.data.data.shopWalletAmount);
      });
    API.pay.getCards().then((response) => {
      setCards(response.data.data);
    });
    getShopCart();
  }, []);

  const getShopCart = async () => {
    console.log(checked);
    await API.shopCart.getAll().then(async (response) => {
      setProducts(response.data.data.infoList);
      getProductsPhoto(response.data.data.infoList);
      setAmount(response.data.data.amount);
      setLoading(false);
    });
  };

  const getProductsPhoto = (products) => {
    console.log("response.data.data.infoList", products);
    products.map((product) => {
      const params = {
        id: product.product.id,
      };
      let arr = [];
      API.shop.getProductsPhoto(params).then((res) => {
        const obj = {
          id: product.id,
          photo: res.data.data,
        };
        arr.push(obj);
        setPhotoArr(arr);
        product.product.logoPhoto = res.data.data;
      });
    });
  };

  const fetchData = async () => {
    await API.user.getAddress().then((address) => {
      console.log(address.data.data);
      setStreet(address.data.data.street);
      setFlat(address.data.data.flat);
      setHouse(address.data.data.house);
    });
  };

  const isAdress = () => {
    return street || flat || house;
  };

  const save = async (flat, house, street) => {
    const data = {
      flat: flat,
      house: house,
      street: street,
    };
    await API.user.sendAddress(data).then((res) => {
      console.log(res.data);
      setModal(false);
      fetchData();
    });
  };

  const updateDate = (type, msg) => {
    if (type === "success") {
      setNotification("success");
      setTimeout(() => {
        setNotification("");
      }, 5000);
    } else if (type === "error") {
      msg === "Insufficient funds"
        ? setErrMsg(t("cart.notFunds"))
        : setErrMsg(t("error"));

      setNotification("error");
      setTimeout(() => {
        setNotification("");
      }, 5000);
    }
    API.user
      .getBalance((response) => response.json())
      .then((responseJson) => {
        setBalance(responseJson.data.data.shopWalletAmount);
      });
    API.pay.getCards().then((response) => {
      setCards(response.data.data);
    });
    getShopCart();
    setPrice(0);
    setModalShow(false);
  };

  // Add/Remove checked item from list
  const handleCheck = (item, event) => {
    console.log(item);
    var updatedList = [...checked];
    if (event.target.checked) {
      updatedList = [...checked, event.target.value];
      setPrice(price + item.product.price * item.count);
    } else {
      updatedList.splice(checked.indexOf(event.target.value), 1);
      setPrice(price - item.product.price * item.count);
    }

    setChecked(updatedList);
    console.log(checked);
  };

  var isChecked = (item) => {
    return checked.includes(item.toString())
      ? "card products__item products__checked"
      : "card products__item ";
  };

  const selectAllFunc = () => {
    setSelectAll(!selectAll);
    let newList = [];
    let newPrice = 0;
    if (!selectAll) {
      for (let i = 0; i < products.length; i++) {
        console.log(products[i].product.price);
        newList = [...newList, products[i].shopCardId.toString()];
        newPrice = newPrice + products[i].product.price * products[i].count;
      }
    }
    setPrice(newPrice);
    setChecked(newList);
    console.log(checked);
  };

  const formatPrice = (price) => {
    let formattedPrice = new Intl.NumberFormat().format(price);
    return formattedPrice.replaceAll(",", " ");
  };

  return (
    <>
      {notification === "success" && (
        <div class="myAlert-top alert alert-success">
          <strong>{t("cart.succesBuy")}</strong>
        </div>
      )}
      {notification === "error" && (
        <div class="myAlert-top alert alert-danger">
          <strong>{errMsg}</strong>
        </div>
      )}
      <Header />
      <div className="container history cart">
        <h1>{t("cart.cart")}</h1>
        <div class="form-check products__all-select">
          <input
            class="form-check-input big-checkbox"
            type="checkbox"
            value={selectAll}
            onChange={() => {
              selectAllFunc();
            }}
            id="flexCheckIndeterminate"
          />
          <label
            class="form-check-label products__all"
            for="flexCheckIndeterminate"
          >
            <h4>{t("cart.selectAll")}</h4>
          </label>
        </div>
        <div class="row row-cols-1 row-cols-md-5 g-4 products">
          {loading && <Loader />}
          {products.map((category, idx) => (
            <div
              key={idx}
              onClick={() => {
                navigate("/details", { state: { id: category.product.id } });
              }}
              className={isChecked(category.shopCardId)}
            >
              <img
                src={
                  category.product.logoPhoto
                    ? `data:image/jpeg;base64,${category.product.logoPhoto}`
                    : require("../../assets/notPhoto.jpg")
                }
                className="card-img-top products__img"
                alt="..."
              />
              <div class="card-body products_info">
                <div>
                  <h5 class="card-title  text-break">
                    {category.product[`name${lang}`]}
                  </h5>
                  <h5 class="">
                    {t("cart.cost")} : {formatPrice(category.product.price)}{" "}
                    {t("tenge")}
                  </h5>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <h5 class="">
                      {t("cart.count")} : {category.count}
                    </h5>
                    <input
                      value={category.shopCardId}
                      type="checkbox"
                      checked={
                        checked.includes(category.shopCardId.toString())
                          ? true
                          : false
                      }
                      className="checked"
                      onChange={(e) => handleCheck(category, e)}
                      onClick={(event) => event.stopPropagation()}
                    />
                  </div>
                </div>
                <div className="products__btn">
                  <div>
                    <button
                      onClick={(event) => {
                        deleteCart(category, event);
                      }}
                      type="button"
                      className="btn btn-outline-danger"
                    >
                      {t("cart.delete")}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          ))}
          {!loading && !products?.length && (
            <div className="empty">
              {" "}
              <h1> {t("emptyPage")}</h1>
            </div>
          )}
        </div>
        {products?.length && (
          <div className="cart__cover">
            {" "}
            {isAdress() && (
              <h5>{street + ", " + house + (flat ? ", " + flat : "")}</h5>
            )}
            <h5
              onClick={() => setModal(true)}
              style={{ color: "blue", cursor: "pointer" }}
            >
              {isAdress() ? t("adress.changeAdress") : t("adress.addAdress")}
            </h5>
            <h5>
              {t("cart.allCost")}: {formatPrice(price)} тенге
            </h5>
            <button
              onClick={() => (isAdress() ? setModalShow(true) : setModal(true))}
              style={{ backgroundColor: !checked.length ? "grey" : "" }}
              disabled={!checked.length}
              type="button"
              className="btn btn-primary cart__btn"
            >
              {t("pay")}
            </button>
            <BuySubscribeModal
              show={modalShow}
              onHide={() => setModalShow(false)}
              buyProducts={checked}
              cards={cards}
              balance={balance}
              sendDataToParent={updateDate}
            />
            <ModalAddress
              show={modal}
              flat={flat}
              street={street}
              house={house}
              onHide={() => setModal(false)}
              sendDataToParent={save}
            />
          </div>
        )}
      </div>
    </>
  );
}

function ModalAddress(props) {
  const [flat, setFlat] = useState(props.flat);
  const [house, setHouse] = useState(props.house);
  const [street, setStreet] = useState(props.street);
  const { t } = useTranslation();
  return (
    <>
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {t("adress.adress")}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="container">
            <div class="mb-3">
              <label class="form-label">{t("adress.adressDelivery")}</label>
              <input
                type="text"
                class="form-control"
                value={street}
                placeholder={t("adress.adressDeliveryPlaceholder")}
                onChange={(event) => {
                  setStreet(event.target.value);
                }}
              />
            </div>
            <div class="mb-3">
              <label class="form-label">{t("adress.house")}</label>
              <input
                type="text"
                class="form-control"
                value={house}
                placeholder={t("adress.housePlaceholder")}
                onChange={(event) => {
                  setHouse(event.target.value);
                }}
              />
            </div>
            <div class="mb-3">
              <label class="form-label">{t("adress.flat")}</label>
              <input
                type="text"
                class="form-control"
                value={flat}
                placeholder={t("adress.flatPlaceholder")}
                onChange={(event) => {
                  setFlat(event.target.value);
                }}
              />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={() => {
              props.sendDataToParent(flat, house, street);
            }}
          >
            {t("save")}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

function BuySubscribeModal(props) {
  const [type, setType] = React.useState("");
  const [typeCart, setTypeCart] = React.useState("");
  const [ID, setID] = useState(null);
  const [notification, setNotification] = React.useState("");
  const { t } = useTranslation();
  const lang = localStorage.getItem("lang");

  const gender = [
    {
      name: t("payWithWallet"),
      code: "wallet",
    },
    {
      name: t("payCard"),
      code: "cart",
    },
  ];

  const cartTypes = [
    {
      name: t("paySaveCart"),
      code: "saveCart",
    },
    {
      name: t("payOtherCard"),
      code: "otherCart",
    },
  ];

  const payViaWallet = () => {
    console.log("dd", props.buyProducts);
    const params = {
      idList: props.buyProducts,
    };
    API.shopCart
      .payWithWallet(params)
      .then((response) => {
        props.sendDataToParent("success");
        console.log(response.data);
      })
      .catch((err) => {
        props.sendDataToParent(
          "error",
          err.response.data.errors[0][`value${lang}`]
        );
        console.log(err);
      });
  };

  const getDataPay = async () => {
    const params = {
      idList: props.buyProducts,
    };
    await API.shopCart.payWithCard(params).then(async (response) => {
      let data = response.data.data.payData;
      let token = response.data.data.token;
      let type = "shop";
      await localStorage.setItem("type", type);
      var createPaymentObject = function () {
        data.auth = token;
        response.data.data.payData.backLink = ACTIVE_BACK_LINK;
        response.data.data.payData.failureBackLink = ACTIVE_FAILURE_LINK;
        return data;
      };

      let a = await window.halyk.pay(await createPaymentObject());
      console.log(a);
    });
  };

  const paySaveCards = async () => {
    let data = {
      cardID: ID.ID,
    };
    const params = {
      idList: props.buyProducts,
    };
    console.log(ID);
    await API.shopCart
      .payWithSaveCard(data, params)
      .then((response) => {
        if (response.status == "200") {
          props.sendDataToParent("success");
        }
      })
      .catch((error) => {
        props.sendDataToParent("error");
        console.log(error);
      });
  };
  const buySubscribe = () => {
    if (type === "wallet") {
      payViaWallet();
    } else if (type === "cart" && typeCart === "saveCart") {
      console.log("savecart");
      paySaveCards();
    } else if (type === "cart" && typeCart === "otherCart") {
      console.log("otherCart");
      getDataPay();
    }
  };
  return (
    <>
      {" "}
      {notification === "success" && (
        <div class="myAlert-top alert alert-success">
          <strong>{t("cart.succesBuy")}</strong>
        </div>
      )}
      {notification === "error" && (
        <div class="myAlert-top alert alert-danger">
          <strong>{t("error")}</strong>
        </div>
      )}
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h4>
            {t("personal.balance")} <b>{props.balance}</b> {t("tenge")}
          </h4>
          <div class="mb-3">
            <label class="form-label"> {t("personal.wayPay")} </label>
            <select
              onChange={(e) => {
                setType(e.target.value);
              }}
              value={type}
              class="form-control"
              id="cars"
            >
              <option value=""></option>
              {gender.map((city) => (
                <option value={city.code}>{city.name}</option>
              ))}
            </select>
          </div>
          {type === "cart" && (
            <div class="mb-3">
              <label class="form-label">{t("personal.chooseCart")}</label>
              <select
                onChange={(e) => {
                  setTypeCart(e.target.value);
                }}
                value={typeCart}
                class="form-control"
                id="cars"
              >
                <option value=""></option>
                {cartTypes.map((city) => (
                  <option value={city.code}>{city.name}</option>
                ))}
              </select>
            </div>
          )}
          {typeCart === "saveCart" && (
            <div class="mb-3">
              {props.cards?.length ? (
                <>
                  <label class="form-label">{t("personal.chooseCart")}</label>
                  <select
                    onChange={(e) => {
                      setID(e.target.value);
                    }}
                    value={ID}
                    class="form-control"
                    id="cars"
                  >
                    <option value=""></option>
                    {props.cards.map((city) => (
                      <option value={city}>{city.CardMask}</option>
                    ))}
                  </select>
                </>
              ) : (
                <span>{t("personal.notSaveCart")}</span>
              )}
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={() => {
              buySubscribe();
            }}
          >
            {t("pay")}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
