import axios from "axios";
import axiosInstance from "./axiosInstance";
const baseURL = "https://backend.1-q.kz/";

const _retrieveData = async () => {
  return localStorage.getItem("token");
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  auth: {
    login(data) {
      return axiosInstance.post(`auth/login`, data, {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
        },
      });
    },
    register(data) {
      return axiosInstance.post(`auth/register`, data, {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
        },
      });
    },
    recover(params) {
      return axiosInstance.get(`user/recover/password`, { params });
    },
    getCountry() {
      return axiosInstance.get(`dictionary/country/all`, {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
        },
      });
    },
    getCity() {
      return axiosInstance.get(`dictionary/city/byParentId?parentId=1`, {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
        },
      });
    },
  },
  user: {
    async changePassword(params) {
      let query = Object.keys(params)
        .map((k) => encodeURIComponent(k) + "=" + encodeURIComponent(params[k]))
        .join("&");

      let url = `user/recover/password?` + query;
      return axiosInstance.get(url, {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
        },
      });
    },
    async getAddress() {
      let token = await _retrieveData();
      return axiosInstance.get(`user/address`, {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          Authorization: "Bearer " + token,
        },
      });
    },
    async sendAddress(data) {
      let token = await _retrieveData();
      console.log("api", data);
      return axiosInstance.post(`user/add/address`, data, {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          Authorization: "Bearer " + token,
        },
      });
    },
    async addTokenExpo(params) {
      let token = await _retrieveData();
      let query = Object.keys(params)
          .map((k) => encodeURIComponent(k) + "=" + encodeURIComponent(params[k]))
          .join("&");
      let url = `user/expo/token?` + query;
      return axiosInstance.put(url, null, {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          Authorization: "Bearer " + token,
        },
      });
    },
    async deleteToken() {
      let token = await _retrieveData();
      return axiosInstance.delete(`user/expo/delete`, {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          Authorization: "Bearer " + token,
        },
      });
    },
    async getBalance() {
      let token = await _retrieveData();
      return axiosInstance.get(`user/balance`, {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          Authorization: "Bearer " + token,
        },
      });
    },
    async getProducts(params, paginationParams) {
      let token = await _retrieveData();
      let query = Object.keys(params)
        .map((k) => encodeURIComponent(k) + "=" + encodeURIComponent(params[k]))
        .join("&");
      let url = `product/by/categoryId?` + query;
      return axiosInstance.get(url, paginationParams, {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          Authorization: "Bearer " + token,
        },
      });
    },
    async getStocksPhoto(params) {
      let token = await _retrieveData();
      let query = Object.keys(params)
        .map((k) => encodeURIComponent(k) + "=" + encodeURIComponent(params[k]))
        .join("&");
      let url = `product/photo?` + query;
      return axiosInstance.get(url, {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          Authorization: "Bearer " + token,
        },
      });
    },
    async sendDeliveryPhoto(data) {
      let token = await _retrieveData();
      return axiosInstance.post(`product/delivery-photo/save`, data, {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          Authorization: "Bearer " + token,
        },
      });
    },
    async getAdminProducts(params, paginationParams) {
      let token = await _retrieveData();
      let query = Object.keys(params)
        .map((k) => encodeURIComponent(k) + "=" + encodeURIComponent(params[k]))
        .join("&");
      let url = `product/admin/by/categoryId?` + query;
      return axiosInstance.post(url, paginationParams, {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          Authorization: "Bearer " + token,
        },
      });
    },
    async getCatalogs(params, paginationParams) {
      let token = await _retrieveData();
      let query = Object.keys(params)
        .map((k) => encodeURIComponent(k) + "=" + encodeURIComponent(params[k]))
        .join("&");
      let url = `${baseURL}category/all?` + query;
      return axiosInstance.post(url, paginationParams, {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          Authorization: "Bearer " + token,
        },
      });
    },
    async getSubCatalogs(params, paginationParams) {
      let token = await _retrieveData();
      let query = Object.keys(params)
        .map((k) => encodeURIComponent(k) + "=" + encodeURIComponent(params[k]))
        .join("&");
      let url = `category/subcategories?` + query;
      return axiosInstance.post(url, paginationParams, {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          Authorization: "Bearer " + token,
        },
      });
    },
    async getPhoto(params) {
      let token = await _retrieveData();
      let query = Object.keys(params)
        .map((k) => encodeURIComponent(k) + "=" + encodeURIComponent(params[k]))
        .join("&");
      let url = `category/photo?` + query;
      return axiosInstance.get(url, {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          Authorization: "Bearer " + token,
        },
      });
    },
    async getProducts(params) {
      let token = await _retrieveData();
      let query = Object.keys(params)
        .map((k) => encodeURIComponent(k) + "=" + encodeURIComponent(params[k]))
        .join("&");
      let url = `product/by/categoryId?` + query;
      return axiosInstance.post(
        url,
        {},
        {
          headers: {
            "Content-Type": "application/json;charset=UTF-8",
            Authorization: "Bearer " + token,
          },
        }
      );
    },
    async getDetailsProduct(params) {
      let token = await _retrieveData();
      let query = Object.keys(params)
        .map((k) => encodeURIComponent(k) + "=" + encodeURIComponent(params[k]))
        .join("&");
      let url = `product/by/id?` + query;
      return axiosInstance.get(url, {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          Authorization: "Bearer " + token,
        },
      });
    },
    async getFavorites() {
      let token = await _retrieveData();
      return axiosInstance.get(`user/favorites`, {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          Authorization: "Bearer " + token,
        },
      });
    },
    async getFIO(params) {
      let token = await _retrieveData();
      let query = Object.keys(params)
        .map((k) => encodeURIComponent(k) + "=" + encodeURIComponent(params[k]))
        .join("&");
      let url = `user/fio?` + query;
      return axiosInstance.get(url, {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          Authorization: "Bearer " + token,
        },
      });
    },
    async getLastWinners() {
      let token = await _retrieveData();
      return axiosInstance.get(`epay/winners`, {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          Authorization: "Bearer " + token,
        },
      });
    },
    async addFavorites(params) {
      let token = await _retrieveData();
      let query = Object.keys(params)
        .map((k) => encodeURIComponent(k) + "=" + encodeURIComponent(params[k]))
        .join("&");
      let url = `user/favorites/add?` + query;
      return axiosInstance.put(url, null, {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          Authorization: "Bearer " + token,
        },
      });
    },
    async deleteFavorites(params) {
      let token = await _retrieveData();
      let query = Object.keys(params)
        .map((k) => encodeURIComponent(k) + "=" + encodeURIComponent(params[k]))
        .join("&");
      let url = `user/favorites/delete?` + query;
      return axiosInstance.delete(url, {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          Authorization: "Bearer " + token,
        },
      });
    },
    async getDraws(params) {
      let token = await _retrieveData();
      let query = Object.keys(params)
        .map((k) => encodeURIComponent(k) + "=" + encodeURIComponent(params[k]))
        .join("&");
      let url = `user/draws?` + query;
      return axiosInstance.get(url, {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          Authorization: "Bearer " + token,
        },
      });
    },
    async changeUserPassword(data) {
      let token = await _retrieveData();
      return axiosInstance.post(`user/change/password`, data, {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          Authorization: "Bearer " + token,
        },
      });
    },
    async sendPersonalData(data) {
      let token = await _retrieveData();
      console.log("api", data);
      return axios.post(`${baseURL}user/add/personal-data`, data, {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          Authorization: "Bearer " + token,
        },
      });
    },
    async getPersonalData(data) {
      let token = await _retrieveData();
      return axios.get(`${baseURL}user/personal-data`, {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          Authorization: "Bearer " + token,
        },
      });
    },
  },
  admin: {
    async getUsers(data) {
      let token = await _retrieveData();
      return axiosInstance.post(`user/super-admin/byCriteria`, data, {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          Authorization: "Bearer " + token,
        },
      });
    },
    async updateUser(data) {
      let token = await _retrieveData();
      return axiosInstance.post(`user/update`, data, {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          Authorization: "Bearer " + token,
        },
      });
    },
    async blockUnblock(params) {
      let token = await _retrieveData();
      let query = Object.keys(params)
        .map((k) => encodeURIComponent(k) + "=" + encodeURIComponent(params[k]))
        .join("&");
      let url = `user/block-unblock?` + query;
      return axiosInstance.get(url, {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          Authorization: "Bearer " + token,
        },
      });
    },
    async createCatalogs(data) {
      let token = await _retrieveData();
      return axiosInstance.post(`category/save`, data, {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          Authorization: "Bearer " + token,
        },
      });
    },
    async deleteCatalogs(params) {
      let token = await _retrieveData();
      let query = Object.keys(params)
        .map((k) => encodeURIComponent(k) + "=" + encodeURIComponent(params[k]))
        .join("&");
      let url = `category/delete?` + query;
      return axiosInstance.post(url, null, {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          Authorization: "Bearer " + token,
        },
      });
    },
    async getBanner(params) {
      let token = await _retrieveData();

      let query = Object.keys(params)
        .map((k) => encodeURIComponent(k) + "=" + encodeURIComponent(params[k]))
        .join("&");
      let url = `banner/all?` + query;
      return axiosInstance.get(url, {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          Authorization: "Bearer " + token,
        },
      });
    },
    async uploadBanner(data) {
      let token = await _retrieveData();
      return axiosInstance.post(`banner/save`, data, {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          Authorization: "Bearer " + token,
        },
      });
    },
    async saveProduct(data) {
      let token = await _retrieveData();
      return axiosInstance.post(`product/save`, data, {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          Authorization: "Bearer " + token,
        },
      });
    },
    async deleteProducts(params) {
      let token = await _retrieveData();
      let query = Object.keys(params)
        .map((k) => encodeURIComponent(k) + "=" + encodeURIComponent(params[k]))
        .join("&");
      let url = `product/delete?` + query;
      return axiosInstance.post(url, null, {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          Authorization: "Bearer " + token,
        },
      });
    },
    async changePassword(data) {
      let token = await _retrieveData();
      return axiosInstance.post(`user/super-admin/change/password`, data, {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          Authorization: "Bearer " + token,
        },
      });
    },
    async issueProduct(params, data) {
      let token = await _retrieveData();
      let query = Object.keys(params)
        .map((k) => encodeURIComponent(k) + "=" + encodeURIComponent(params[k]))
        .join("&");
      let url = `product/issue?` + query;
      console.log(url);
      console.log(data);
      return axiosInstance.post(url, data, {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          Authorization: "Bearer " + token,
        },
      });
    },
  },
  pay: {
    async getCards() {
      let token = await _retrieveData();
      return axiosInstance.get(`epay/card-list`, {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          Authorization: "Bearer " + token,
        },
      });
    },
    async paySaveCard(data) {
      let token = await _retrieveData();
      return axiosInstance.post(`shop/epay/pay-with-saved-card`, data, {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          Authorization: "Bearer " + token,
        },
      });
    },
    async getPayData(params) {
      let token = await _retrieveData();
      let query = Object.keys(params)
        .map((k) => encodeURIComponent(k) + "=" + encodeURIComponent(params[k]))
        .join("&");
      let url = `shop/epay/pay-data?` + query;
      return axiosInstance.get(url, {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          Authorization: "Bearer " + token,
        },
      });
    },
    async participate(params) {
      let token = await _retrieveData();
      let query = Object.keys(params)
        .map((k) => encodeURIComponent(k) + "=" + encodeURIComponent(params[k]))
        .join("&");
      let url = `shop/epay/confirm ?` + query;
      console.log(url);
      return axiosInstance.get(url, {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          Authorization: "Bearer " + token,
        },
      });
    },
    async cancelParticipate(invoiceId) {
      let token = await _retrieveData();
      let params = {
        invoiceId: invoiceId,
      };
      let query = Object.keys(params)
        .map((k) => encodeURIComponent(k) + "=" + encodeURIComponent(params[k]))
        .join("&");
      let url = `shop/epay/cancel?` + query;
      return axiosInstance.get(url, {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          Authorization: "Bearer " + token,
        },
      });
    },
    async payWallet(params) {
      let token = await _retrieveData();
      let query = Object.keys(params)
        .map((k) => encodeURIComponent(k) + "=" + encodeURIComponent(params[k]))
        .join("&");
      let url = `shop/epay/pay-via-wallet?` + query;
      return axiosInstance.get(url, {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          Authorization: "Bearer " + token,
        },
      });
    },
    async replenishData(params) {
      let token = await _retrieveData();
      let query = Object.keys(params)
        .map((k) => encodeURIComponent(k) + "=" + encodeURIComponent(params[k]))
        .join("&");
      let url = `shop/epay/replenish-data?` + query;
      return axiosInstance.get(url, {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          Authorization: "Bearer " + token,
        },
      });
    },
    async replenishWithSavedCard(data) {
      let token = await _retrieveData();
      return axiosInstance.post(`shop/epay/replenish-with-saved-card`, data, {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          Authorization: "Bearer " + token,
        },
      });
    },
    async getQrCode(params) {
      let token = await _retrieveData();
      let query = Object.keys(params)
        .map((k) => encodeURIComponent(k) + "=" + encodeURIComponent(params[k]))
        .join("&");
      let url = `epay/get-qr-code?` + query;
      return axiosInstance.get(url, {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          Authorization: "Bearer " + token,
        },
      });
    },
  },
  epay: {
    async getPayData() {
      let token = await _retrieveData();
      let url = `epay/pay-data`;
      return axiosInstance.get(url, {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          Authorization: "Bearer " + token,
        },
      });
    },
    async payViaWallet() {
      let token = await _retrieveData();
      let url = `epay/pay-via-wallet`;
      return axiosInstance.get(url, {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          Authorization: "Bearer " + token,
        },
      });
    },
    async subscribePurchase(params) {
      let token = await _retrieveData();
      let query = Object.keys(params)
        .map((k) => encodeURIComponent(k) + "=" + encodeURIComponent(params[k]))
        .join("&");
      return axiosInstance.get(`epay/confirm?` + query, {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          Authorization: "Bearer " + token,
        },
      });
    },
    async buy(params) {
      let token = await _retrieveData();
      let query = Object.keys(params)
        .map((k) => encodeURIComponent(k) + "=" + encodeURIComponent(params[k]))
        .join("&");
      console.log(query);
      return axiosInstance.get(`certificate/buy?` + query, {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          Authorization: "Bearer " + token,
        },
      });
    },
    async cancel(invoiceId) {
      let token = await _retrieveData();
      let params = {
        invoiceId: invoiceId,
      };
      let query = Object.keys(params)
        .map((k) => encodeURIComponent(k) + "=" + encodeURIComponent(params[k]))
        .join("&");
      let url = `epay/cancel?` + query;
      return axiosInstance.get(url, {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          Authorization: "Bearer " + token,
        },
      });
    },
  },
  statistics: {
    async userStatistics() {
      let token = await _retrieveData();
      return axiosInstance.get(`statistics/get-users`, {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          Authorization: "Bearer " + token,
        },
      });
    },
    async reportPoints() {
      let token = await _retrieveData();
      return axiosInstance.get(`statistics/get-score-report`, {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          Authorization: "Bearer " + token,
        },
      });
    },
    async getWinners(params) {
      let token = await _retrieveData();
      let query = Object.keys(params)
          .map((k) => encodeURIComponent(k) + "=" + encodeURIComponent(params[k]))
          .join("&");
      let url = `statistics/get-winners?` + query;
      return axiosInstance.get(url, {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          Authorization: "Bearer " + token,
        },
      });
    },
    async getParticipant(params) {
      let token = await _retrieveData();
      let url = `statistics/get-participant?startDate=${params.startDate}&endDate=${params.endDate}`;
      return axiosInstance.get(url, {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          Authorization: "Bearer " + token,
        },
      });
    },
    async reportShop(params) {
      let token = await _retrieveData();
      console.log(token);
      let query = Object.keys(params)
          .map((k) => encodeURIComponent(k) + "=" + encodeURIComponent(params[k]))
          .join("&");
      let url = `/reports/online-shop?` + query;
      console.log(url);
      return axiosInstance.get(url, {
        responseType: "blob",
        headers: {
          "Content-Type": "application/vnd.ms-excel",
          Authorization: "Bearer " + token,
        },
      });
    },
    async reportStock(params) {
      let token = await _retrieveData();
      console.log(token);
      let query = Object.keys(params)
          .map((k) => encodeURIComponent(k) + "=" + encodeURIComponent(params[k]))
          .join("&");
      let url = `/reports/stock?` + query;
      return axiosInstance.get(url, {
        responseType: "blob",
        headers: {
          "Content-Type": "application/vnd.ms-excel",
          Authorization: "Bearer " + token,
        },
      });
    },
  },
  certificate: {
    async getCertificate(params) {
      let token = await _retrieveData();
      let query = Object.keys(params)
          .map((k) => encodeURIComponent(k) + "=" + encodeURIComponent(params[k]))
          .join("&");
      let url = `certificate/by-product-user?` + query;
      return axiosInstance.get(url, {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          Authorization: "Bearer " + token,
        },
      });
    },
  },
  shop: {
    async getCategory(params, paginationParams) {
      let token = await _retrieveData();
      let query = Object.keys(params)
          .map((k) => encodeURIComponent(k) + "=" + encodeURIComponent(params[k]))
          .join("&");
      let url = `${baseURL}shop/category/all?` + query;
      return axiosInstance.post(url, paginationParams, {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          Authorization: "Bearer " + token,
        },
      });
    },
    async getSubCategory(params, paginationParams) {
      let token = await _retrieveData();
      let query = Object.keys(params)
          .map((k) => encodeURIComponent(k) + "=" + encodeURIComponent(params[k]))
          .join("&");
      let url = `/shop/category/subcategories?` + query;
      return axiosInstance.post(url, paginationParams, {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          Authorization: "Bearer " + token,
        },
      });
    },
    async getPhotoCategory(params) {
      let token = await _retrieveData();
      let query = Object.keys(params)
          .map((k) => encodeURIComponent(k) + "=" + encodeURIComponent(params[k]))
          .join("&");
      let url = `/shop/category/photo?` + query;
      return axiosInstance.get(url, {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          Authorization: "Bearer " + token,
        },
      });
    },
    async getProducts(params, paginationParams) {
      let token = await _retrieveData();
      let query = Object.keys(params)
          .map((k) => encodeURIComponent(k) + "=" + encodeURIComponent(params[k]))
          .join("&");
      let url = `shop/product/by/categoryId?` + query;
      return axiosInstance.post(url, paginationParams, {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          Authorization: "Bearer " + token,
        },
      });
    },
    async getProductsPhoto(params) {
      let token = await _retrieveData();
      let query = Object.keys(params)
          .map((k) => encodeURIComponent(k) + "=" + encodeURIComponent(params[k]))
          .join("&");
      let url = `shop/product/photo?` + query;
      return axiosInstance.get(url, {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          Authorization: "Bearer " + token,
        },
      });
    },
    async getFavorites() {
      let token = await _retrieveData();
      return axiosInstance.get(`shop/product/favorites`, {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          Authorization: "Bearer " + token,
        },
      });
    },
    async addFavorites(params) {
      let token = await _retrieveData();
      let query = Object.keys(params)
          .map((k) => encodeURIComponent(k) + "=" + encodeURIComponent(params[k]))
          .join("&");
      let url = `shop/product/favorites/add?` + query;
      return axiosInstance.put(url, null, {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          Authorization: "Bearer " + token,
        },
      });
    },
    async deleteFavorites(params) {
      let token = await _retrieveData();
      let query = Object.keys(params)
          .map((k) => encodeURIComponent(k) + "=" + encodeURIComponent(params[k]))
          .join("&");
      let url = `shop/product/favorites/delete?` + query;
      return axiosInstance.put(url, null, {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          Authorization: "Bearer " + token,
        },
      });
    },
    async getDetailsProduct(params) {
      let token = await _retrieveData();
      let query = Object.keys(params)
          .map((k) => encodeURIComponent(k) + "=" + encodeURIComponent(params[k]))
          .join("&");
      let url = `shop/product/by/id?` + query;
      return axiosInstance.get(url, {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          Authorization: "Bearer " + token,
        },
      });
    },
    async getPurchased(params) {
      let token = await _retrieveData();
      let query = Object.keys(params)
        .map((k) => encodeURIComponent(k) + "=" + encodeURIComponent(params[k]))
        .join("&");
      let url = `shop/product/purchased?` + query;
      return axiosInstance.get(url, {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          Authorization: "Bearer " + token,
        },
      });
    },
  },
  shopCart: {
    async getAll() {
      let token = await _retrieveData();
      return axiosInstance.get(`shop-cart`, {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          Authorization: "Bearer " + token,
        },
      });
    },
    async add(data) {
      let token = await _retrieveData();
      return axiosInstance.post(`shop-cart/add`, data, {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          Authorization: "Bearer " + token,
        },
      });
    },
    async deleteCart(params) {
      let token = await _retrieveData();
      let query = Object.keys(params)
          .map((k) => encodeURIComponent(k) + "=" + encodeURIComponent(params[k]))
          .join("&");
      let url = `shop-cart/delete?` + query;
      console.log(url);
      return axiosInstance.get(url, {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          Authorization: "Bearer " + token,
        },
      });
    },
    async payWithCard(params) {
      console.log("ids", params);
      let token = await _retrieveData();
      return axiosInstance.post(`shop-cart/pay-with-card`, params, {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          Authorization: "Bearer " + token,
        },
      });
    },
    async payWithWallet(params) {
      let token = await _retrieveData();

      return axiosInstance.post(`shop-cart/pay-with-wallet`, params, {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          Authorization: "Bearer " + token,
        },
      });
    },
    async payWithSaveCard(params, data) {
      console.log("ids", data);
      let token = await _retrieveData();
      let query = Object.keys(params)
          .map((k) => encodeURIComponent(k) + "=" + encodeURIComponent(params[k]))
          .join("&");
      let url = `shop-cart/pay-with-saved-card?` + query;
      console.log(url);
      return axiosInstance.post(url, data, {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          Authorization: "Bearer " + token,
        },
      });
    },
  },
  order: {
    async getQR(params) {
      let token = await _retrieveData();
      let query = Object.keys(params)
          .map((k) => encodeURIComponent(k) + "=" + encodeURIComponent(params[k]))
          .join("&");
      let url = `order/qr?` + query;
      return axiosInstance.get(url, {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          Authorization: "Bearer " + token,
        },
      });
    },
    async issueProduct(params, data) {
      let token = await _retrieveData();
      let query = Object.keys(params)
          .map((k) => encodeURIComponent(k) + "=" + encodeURIComponent(params[k]))
          .join("&");
      let url = `order/issue?` + query;
      console.log(url);
      console.log(data);
      return axiosInstance.post(url, data, {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          Authorization: "Bearer " + token,
        },
      });
    },
  },
  push: {
    async getAllNotifications() {
      let token = await _retrieveData();
      return axiosInstance.get("/push-notification/all", {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          Authorization: "Bearer " + token,
        },
      });
    },
  },
};
