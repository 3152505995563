import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Carousel from "react-bootstrap/Carousel";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import API from "../../api/apiController";
import Header from "../../components/Header";
import { Declination } from "../../utils/helpers";
import "./DetailsProduct.scss";

export default function DetailsProduct() {
  const token = localStorage.getItem("token");
  const auth = localStorage.getItem("auth");
  const { t } = useTranslation();
  const [product, setProduct] = useState([]);
  const [count, setCount] = useState(1);
  const [modalShow, setModalShow] = React.useState(false);
  const [modalBuyCert, setModalBuyCert] = React.useState(false);
  const [countId, setCountId] = useState(null);
  const [notification, setNotification] = React.useState("");
  const [favoriteShopsId, setFavoriteShopsId] = useState([]);
  const [errMsg, setErrMsg] = React.useState("");
  const [currentId, setCurrentId] = useState(null);
  const [flat, setFlat] = useState("");
  const [house, setHouse] = useState("");
  const [street, setStreet] = useState(null);
  const [modal, setModal] = useState(false);
  const lang = localStorage.getItem("lang");

  const { state } = useLocation();
  useEffect(() => {
    updateDate();
    fetchData().then();
    token || getFavoritesProduct();
  }, []);

  const fetchData = async () => {
    await API.user.getAddress().then((address) => {
      setStreet(address.data.data.street);
      setFlat(address.data.data.flat);
      setHouse(address.data.data.house);
    });
  };

  const getProductDetails = (params) => {
    API.shop.getDetailsProduct(params).then((response) => {
      setProduct(response?.data?.data);
    });
  };

  const getStockDetails = (params) => {
    API.user.getDetailsProduct(params).then((response) => {
      setProduct(response.data.data);
    });
  };

  const updateDate = () => {
    setModalShow(false);
    setModalBuyCert(false);
    let params = {
      id: state.id,
    };
    state?.type === "stock"
      ? getStockDetails(params)
      : getProductDetails(params);
  };

  const addCertificate = async (item) => {
    if (item.id !== countId) {
      setCount(1);
      setCountId(item?.id);
    } else {
      item?.remain > count ? setCount(count + 1) : setCount(count);
    }
  };

  const addProduct = async (item) => {
    if (item.id !== countId) {
      setCount(1);
      setCountId(item.id);
    } else {
      if (count < item.userLimitRemain && count < item.certificateRemain) {
        setCount(count + 1);
      } else {
        setCount(count);
      }
    }
  };

  const minusCertificate = async (item) => {
    if (item.id !== countId) {
      setCount(1);
      setCountId(item.id);
    } else {
      setCount(count - 1);
      if (count <= 1) {
        setCount(1);
      }
    }
  };

  const pressLike = (id, event) => {
    event.preventDefault();
    event.stopPropagation();
    let params = {
      productId: id,
    };
    if (favoriteShopsId.includes(id)) {
      API.shop
        .deleteFavorites(params)
        .then(async (response) => await getFavoritesProduct());
    } else {
      API.shop
        .addFavorites(params)
        .then(async (response) => await getFavoritesProduct());
    }
  };

  const pressLikeStock = (id, event) => {
    event.preventDefault();
    event.stopPropagation();
    let params = {
      productId: id,
    };
    if (favoriteShopsId.includes(id)) {
      API.user
        .deleteFavorites(params)
        .then(async (response) => await getFavoritesProduct());
    } else {
      API.user
        .addFavorites(params)
        .then(async (response) => await getFavoritesProduct());
    }
  };

  const getFavoritesProduct = async () => {
    let favoritesId = [];
    let favorites = [];
    if (state?.type === "stock") {
      await API.user
        .getFavorites()
        .then(async (response) => (favorites = response.data.data));
      favorites.map((el) => favoritesId.push(el.id));
      setFavoriteShopsId(favoritesId);
    } else {
      await API.shop
        .getFavorites()
        .then(async (response) => (favorites = response.data.data));
      favorites.map((el) => favoritesId.push(el.id));
      setFavoriteShopsId(favoritesId);
    }
  };

  const buyCertificate = async (flat, house, street) => {
    const params = {
      number: 1,
      productId: currentId,
    };
    const data = {
      flat: flat,
      house: house,
      street: street,
    };
    await API.user.sendAddress(data).then((res) => {
      setModal(false);
      fetchData();
    });

    await API.epay
      .buy(params)
      .then(async (res) => {
        updateDate();
        setCurrentId(null);
        setNotification("success");
        setTimeout(() => {
          setNotification("");
        }, 5000);
      })
      .catch((err) => {
        setNotification("error");
        setErrMsg(err.response.data.errors[0][`value${lang}`]);
        setTimeout(() => {
          setNotification("");
        }, 5000);
        console.log("err", err);
      });
  };

  const addShopCart = async (id, enable, count, event) => {
    event.preventDefault();
    event.stopPropagation();
    const params = {
      count: count,
      productId: id,
    };
    let paramsProducts = {
      categoryId: state?.id,
    };
    console.log(params);
    !enable
      ? await API.shopCart.add(params).then(async (res) => {
          updateDate();
          setNotification("addCart");
          setTimeout(() => {
            setNotification("");
          }, 5000);
        })
      : await API.shopCart.deleteCart(params).then((res) => {
          updateDate();
          setNotification("deleteCart");
          setTimeout(() => {
            setNotification("");
          }, 5000);
        });
    console.log(id);
  };

  const formatPrice = (price) => {
    let formattedPrice = new Intl.NumberFormat().format(price);
    return formattedPrice.replaceAll(",", " ");
  };

  return (
    <>
      {notification === "addCart" && (
        <div className="myAlert-top alert alert-success">
          <strong>{t("notifications.addedCart")}</strong>
        </div>
      )}
      {notification === "deleteCart" && (
        <div className="myAlert-top alert alert-danger">
          <strong>{t("notifications.deltedCart")}</strong>
        </div>
      )}
      {notification === "success" && (
        <div className="myAlert-top alert alert-success">
          <strong>{t("youParticipate")}</strong>
        </div>
      )}
      {notification === "error" && (
        <div className="myAlert-top alert alert-danger">
          <strong>{errMsg}!</strong>
        </div>
      )}
      <Header />
      <main>
        <div className="product-details">
          <div className="container">
            <div className="row">
              <div className="col-xl-6 product__slide">
                <Carousel>
                  <Carousel.Item>
                    <img
                      className="d-block w-75 h-75 product__photo"
                      src={`data:image/jpeg;base64,${product.firstPhoto}`}
                      alt="First slide"
                    />
                    <Carousel.Caption></Carousel.Caption>
                  </Carousel.Item>
                  <Carousel.Item>
                    <img
                      className="d-block w-75 h-75 product__photo"
                      src={`data:image/jpeg;base64,${product.secondPhoto}`}
                      alt="Second slide"
                    />

                    <Carousel.Caption></Carousel.Caption>
                  </Carousel.Item>
                  <Carousel.Item>
                    <img
                      className="d-block w-75 h-75 product__photo"
                      src={`data:image/jpeg;base64,${product.thirdPhoto}`}
                      alt="Third slide"
                    />

                    <Carousel.Caption></Carousel.Caption>
                  </Carousel.Item>
                </Carousel>
              </div>
              <div className="col-xl-6">
                <div className="product__details-content">
                  <img
                    className="product__heart"
                    onClick={(event) => {
                      state?.type
                        ? pressLikeStock(product.id, event)
                        : pressLike(product.id, event);
                    }}
                    src={
                      favoriteShopsId.includes(product?.id)
                        ? require("../../assets/icons/heart-fill.png")
                        : require("../../assets/icons/heart.png")
                    }
                    alt=""
                  />
                  <h6 style={{ width: "500px" }}>{product[`name${lang}`]}</h6>
                  <div className="price mb-10">
                    <span>
                      {!state?.type
                        ? t("history.cost")
                        : t("history.participate")}{" "}
                      {formatPrice(product.price)}{" "}
                      {state?.type ? Declination(product.price) : "₸"}
                      {product?.discountPrice && (
                        <span
                          style={{
                            color: "gray",
                            textDecoration: "line-through",
                            fontSize: "16px",
                            marginLeft: "10px",
                            marginBottom: "20px",
                          }}
                        >
                          {formatPrice(product?.discountPrice)} ₸
                        </span>
                      )}
                    </span>
                  </div>
                  <div
                    className="features-des mb-20 mt-10"
                    style={{ whiteSpace: "pre-wrap" }}
                  >
                    <h5>
                      {product?.[`description${lang}`]?.replace(/;/g, ";\n")}
                    </h5>
                  </div>
                  {state?.type && (
                    <div className="features-des mb-20 mt-10">
                      <h4>
                        {t("stockEnd")} {product.drawDate?.replace("T", " ")}
                      </h4>
                    </div>
                  )}
                  {state?.type && token && (
                    <div className="product-stock mb-40">
                      {product.userLimit !== product.userLimitRemain &&
                        token && (
                          <p className="products__participate">
                            {t("youParticipate")}
                          </p>
                        )}
                    </div>
                  )}
                  {state?.type !== "stock" ? (
                    <div className="cart-option mb-15">
                      <div className="products__buy-group">
                        <div className="input-group details-group">
                          <button
                            className="btn btn-outline-secondary"
                            type="button"
                            id="button-addon1"
                            onClick={() => minusCertificate(product)}
                          >
                            -
                          </button>
                          <input
                            type="text"
                            className="form-control"
                            value={countId === product.id ? count : 1}
                            onChange={(e) => setCount(e.target.value)}
                            placeholder=""
                            aria-label="Example text with button addon"
                            aria-describedby="button-addon1"
                          />
                          <button
                            className="btn btn-outline-secondary"
                            type="button"
                            id="button-addon1"
                            onClick={() => addCertificate(product)}
                          >
                            +
                          </button>
                        </div>
                        <button
                          onClick={(event) => {
                            if (token || auth !== 'false') {
                              addShopCart(
                                product.id,
                                product.inCart,
                                count,
                                event
                              );
                            } else {
                              window.location.replace("/#/login");
                            }
                          }}
                          type="button"
                          disabled={!product.remain}
                          className={
                            product.inCart
                              ? "btn btn-success buy"
                              : "btn btn-primary buy"
                          }
                        >
                          {product.inCart
                            ? t("cart.inCart")
                            : t("cart.addCart")}
                        </button>
                      </div>
                    </div>
                  ) : (
                    <div className="products__buy">
                      <button
                        onClick={() => {
                          if (token) {
                            setModal(true);
                            setCurrentId(product.id);
                          } else {
                            window.location.replace("/#/login");
                          }
                        }}
                        disabled={
                          product.userLimit !== product.userLimitRemain && token
                        }
                        type="button"
                        className="btn btn-primary buy"
                      >
                        {t("favorites.participate")}
                      </button>
                    </div>
                  )}
                </div>
                <ModalAddress
                  show={modal}
                  flat={flat}
                  street={street}
                  house={house}
                  onHide={() => setModal(false)}
                  sendDataToParent={buyCertificate}
                />
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
}

function ModalAddress(props) {
  const [flat, setFlat] = useState(props.flat);
  const [house, setHouse] = useState(props.house);
  const [street, setStreet] = useState(props.street);
  const { t } = useTranslation();

  useEffect(() => {
    fetchData().then();
  }, []);

  const fetchData = async () => {
    await API.user.getAddress().then((address) => {
      setStreet(address.data.data.street);
      setFlat(address.data.data.flat);
      setHouse(address.data.data.house);
    });
  };
  return (
    <>
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {t("adress.adress")}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="container">
            <div className="mb-3">
              <label className="form-label">{t("adress.adressDelivery")}</label>
              <input
                type="text"
                className="form-control"
                value={street}
                placeholder={t("adress.adressDeliveryPlaceholder")}
                onChange={(event) => {
                  setStreet(event.target.value);
                }}
              />
            </div>
            <div className="mb-3">
              <label className="form-label">{t("adress.house")}</label>
              <input
                type="text"
                className="form-control"
                value={house}
                placeholder={t("adress.housePlaceholder")}
                onChange={(event) => {
                  setHouse(event.target.value);
                }}
              />
            </div>
            <div className="mb-3">
              <label className="form-label">{t("adress.flat")}</label>
              <input
                type="text"
                className="form-control"
                value={flat}
                placeholder={t("adress.flatPlaceholder")}
                onChange={(event) => {
                  setFlat(event.target.value);
                }}
              />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={() => {
              props.sendDataToParent(flat, house, street);
            }}
            disabled={!house || !street}
          >
            {t("favorites.participate")}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
