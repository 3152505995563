import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import PopUp from "../Pop-up";
import HeaderIcon from "../HeaderIcon";
import { useTranslation } from "react-i18next";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import FavoriteIcon from "@mui/icons-material/Favorite";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import { AdminPanelSettings } from "@mui/icons-material";
import "./Header.scss";

export default function Header() {
  const { t } = useTranslation();
  const [city, setCity] = useState("")
  const token = localStorage.getItem("token");
  const name = localStorage.getItem("name");
  const role = localStorage.getItem("role");

  useEffect(() => {
   
    setCity(localStorage.getItem("city"))
  }, []);

  return (
    <div className="header">
      <div className="container header__inner ">
        <div className="header__top">
          <div className="header__top-left">
            <PopUp typeSort="Язык" typesOption="lngTypes" />

            {city && <span className="">{t("city")}: {city}</span>}
          </div>
          <div className="header__top-right d-none">
            <ul>
              <li>
                <a href="#">О нас</a>
              </li>
              <li>
                <a href="#">Доставка</a>
              </li>
              <li>
                <a href="#">Контакты</a>
              </li>
              <li>
                <a href="#">FAQs</a>
              </li>
            </ul>
          </div>
        </div>
        <hr />
        <div className=" row align-items-center header__main">
          <div className="col-xl-3 col-lg-3 col-md-4 col-sm-4 logo-block">
            <Link to="/" href="#">
              <img className="logo" src={require("../../assets/icons/logo.png")} alt="" />
            </Link>
          </div>
          <div className="col-xl-4 col-lg-5 col-md-8 col-sm-8 header__main-right">
            <div className=" header__icons">
              {token ? (
                <HeaderIcon
                  statusImage={AccountCircleIcon}
                  textIcon={name}
                  textInfo=""
                  path="/personal"
                />
              ) : (
                <HeaderIcon
                  statusImage={AccountCircleIcon}
                  textIcon={t("header.login")}
                  textInfo=""
                  path="/login"
                />
              )}
              {(role === "SUPER_ADMIN_ROLE" || role === "ADMIN_ROLE") ? (
                <HeaderIcon
                  statusImage={AdminPanelSettings}
                  textIcon="Админ"
                  textInfo=""
                  path="/admin"
                />
              ) : (
                <HeaderIcon
                  statusImage={FavoriteIcon}
                  textIcon={t("header.favorite")}
                  textInfo=""
                  path="/favorite"
                />
              )}

              <HeaderIcon
                statusImage={ShoppingCartIcon}
                textIcon={t("header.cart")}
                textInfo=""
                path="/cart"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
