import React, { useState, useEffect } from "react";
import Header from "../../components/Header";
import "./PersonalData.scss";
import { useNavigate } from "react-router-dom";
import API from "../../api/apiController";
import { useTranslation } from "react-i18next";

export default function Personal() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [fullName, setFullName] = useState("");
  const [name, setName] = useState("");
  const [fatherland, setFatherland] = useState("");
  const [age, setAge] = useState(null);
  const [numberPhone, setNumberPhone] = useState("");
  const [userPhone, setUserPhone] = useState("");
  const [address, setAddress] = useState("");
  const [house, setHouse] = useState("");
  const [flat, setFlat] = useState("");
  const { t } = useTranslation();

  const fetchData = async () => {
    await API.user.getPersonalData().then((res) => {
      const user = res.data.data;
      setFullName(user.surname);
      setName(user.name);
      setFatherland(user.patronymic);
      setAge(user.age?.toString());
      setNumberPhone(user.phoneNumber.split('+77').join(''));
      setUserPhone(user.phoneNumber)
      setAddress(user.street);
      setHouse(user.house);
      setFlat(user.flat);
      console.log("res", user.phoneNumber);
    });
  };

  const save = () => {
    const data = {
      age: Number(age),
      flat: flat,
      house: house,
      name: name,
      patronymic: fatherland,
      phoneNumber: userPhone.split(' ').join('').split('(').join('').split(')').join('').split('-').join(''),
      street: address,
      surname: fullName,
    };

    API.user
      .sendPersonalData(data)
      .then((res) => {
        console.log(res)
        navigate('/personal')
      })
      .catch((err) => {
        console.log(err);
      });

    console.log("data", data);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const disabledBtn = () => {
    const disabled = fullName && name && age && numberPhone && address && house;
    return !disabled;
  }

  React.useEffect(() => {
    setNumberPhone(numberPhone);
    let number;
    number = "+77".concat(numberPhone);
    setUserPhone(number)
  }, [numberPhone]);

  const handleInput = (e) => {
    //const formattedPhoneNumber = formatPhoneNumber(e);
    if (e.length > 9) {
    } else {
      setNumberPhone(e)
      let number;
      number = "+77".concat(numberPhone);
      setUserPhone(number)
      console.log(number)
    }
    console.log(numberPhone);
  };


  return (
    <>
      <Header />
      <div className="container">
        <div class="mb-3">
          <label class="form-label">{t("registration.surname")}</label>
          <input
            type="text"
            class="form-control"
            value={fullName}
            placeholder={t("registration.surnamePlaceholder")}
            onChange={(event) => setFullName(event.target.value)}
          />
        </div>
        <div class="mb-3">
          <label class="form-label">{t("registration.name")}</label>
          <input
            type="text"
            class="form-control"
            value={name}
            placeholder={t("registration.namePlaceholder")}
            onChange={(event) => setName(event.target.value)}
          />
        </div>
        <div class="mb-3">
          <label class="form-label">{t("registration.fatherland")}</label>
          <input
            type="text"
            class="form-control"
            value={fatherland}
            placeholder={t("registration.fatherlandPlaceholder")}
            onChange={(event) => setFatherland(event.target.value)}
          />
        </div>
        <div class="mb-3">
          <label class="form-label">{t("registration.yourAge")}</label>
          <input
            type="number"
            class="form-control"
            value={age}
            placeholder={t("registration.yourAgePlaceholder")}
            onChange={(event) => setAge(event.target.value)}
          />
        </div>
        <div class="mb-3">
          <label class="form-label">{t("registration.number")}</label>
          <div
            class="form-control select phoneInput"
            style={{ display: "flex" }}
          >
            <span> +7 7</span>
            <input
              type="number"
              style={{ borderColor: "white", marginLeft: '-5px', marginTop: '1px'}}
              value={numberPhone}
              placeholder={t("registration.numberPlaceholder")}
              onChange={(event) => handleInput(event.target.value)}
            />
          </div>
        </div>
        <div class="mb-3">
          <label class="form-label">{t("adress.adressDelivery")}</label>
          <input
            type="text"
            class="form-control"
            value={address}
            placeholder={t("adress.adressDeliveryPlaceholder")}
            onChange={(event) => setAddress(event.target.value)}
          />
        </div>
        <div class="mb-3">
          <label class="form-label">{t("adress.house")}</label>
          <input
            type="text"
            class="form-control"
            value={house}
            placeholder={t("adress.housePlaceholder")}
            onChange={(event) => setHouse(event.target.value)}
          />
        </div>
        <div class="mb-3">
          <label class="form-label">{t("adress.flat")}</label>
          <input
            type="text"
            class="form-control"
            value={flat}
            placeholder={t("adress.flatPlaceholder")}
            onChange={(event) => setFlat(event.target.value)}
          />
        </div>
        <button style={{ backgroundColor: disabledBtn() ? 'grey' : '' }} onClick={() => save()} disabled={disabledBtn()} class="tp-in-btn w-100 mb-3">{t("save")}</button>
      </div>

    </>
  );
}
