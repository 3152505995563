import React from "react";
import Header from "../../components/Header";
import "./styles.scss";
import { useTranslation } from "react-i18next";

export default function Payments() {
  const { t } = useTranslation();

  return (
    <>
      <Header />
      <div className="container info">
        <h1>{t("footer.rules")} </h1>
        <div>
          <ul>
            <li>{t("rule.rule-personal")}</li>
            <li>
              {t("rule.rule-acts")}
              <ul>
                <li>{t("rule.rule-acts-1")}</li>
                <li>{t("rule.rule-acts-2")}</li>
              </ul>
              <li>{t("rule.rule-shop")}</li>
              <li>{t("rule.rule-pay")} </li>
              <li>{t("rule.rule-bank")} </li>
              <li>{t("rule.rule-delivery")}</li>
              <li> {t("rule.rule-cost")}</li>
              <li>{t("rule.rule-safe")}</li>
              <li>
                {t("rule.rule-order-1")}
                <img
                  src={require("../../assets/1QLogo.png")}
                  width="30"
                  height="30"
                  alt=""
                />
                {t("rule.rule-order-2")}
              </li>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
}
