import React, { useEffect, useState } from "react";
import Header from "../../components/Header";
import axios from "axios";
import "./AdminCatalogs.scss";
import { Modal, Button } from "react-bootstrap";
import { useNavigate, useLocation } from "react-router-dom";
import Loader from "../../components/Loader";
import API from "../../api/apiController";
import { useTranslation } from "react-i18next";

export default function AdminCatalogs() {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const token = localStorage.getItem("token");
  const [categories, setCategories] = useState([]);
  const [notification, setNotification] = React.useState("");
  const [currentCategory, setCurrentCategory] = useState(null);
  const [photoArr, setPhotoArr] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize] = useState(5);
  const [totalPages, setTotalPages] = useState(0);
  const navigate = useNavigate();
  const { state } = useLocation();
  const baseURL = "https://backend.1-q.kz/";
  useEffect(() => {
    setCategories([]);
    setCurrentPage(1)
    setLoading(true);
    getCatalogs(1)
   
    console.log('currentPage',currentPage)
    console.log(state);
  }, [state]);
  const getCatalogs = (page) => {
    setCategories([]);
    setLoading(true);
    const params = {
      id: state?.id,
      active: false
    };
    console.log('admin', page )
    setCurrentPage(page);

    const paramsShop = {
      active: false
    }
    
    const paginationParams = {
      pageNumber: page ? page - 1 : currentPage - 1,
      pageSize: 10,
    };
    state?.id
      ? API.shop.getSubCategory(params, paginationParams).then((response) => {
          setTotalPages(response.data.data.totalPages);
          setCategories(response.data.data.data.data);
          getPhotoCategories(response.data.data.data.data);
          setLoading(false);
        })
      : API.shop.getCategory(paramsShop, paginationParams).then((response) => {
          setTotalPages(response.data.data.totalPages);
          setCategories(response.data.data.data.data);
          getPhotoCategories(response.data.data.data.data);
          console.log("erer");
          setLoading(false);
        });
  };

  const filterCategory = (data) => {
    const newCategory = data.filter((el) => el.parentId === state?.id);
    setCategories(newCategory);
  };
  const getPhotoCategories = (categories) => {
    categories.map(category => {
      const params = {
        id: category.id,
      }
      let arr = []
      API.shop.getPhotoCategory(params)
      .then((res) => {
        const obj = {
          id:category.id,
          photo: res.data.data
        }
        arr.push(obj)
        setPhotoArr(arr)
        category.photo = res.data.data;
      });
    })
  };

  const filterCatalogsParent = (data) => {
    const newCatalogList = data.filter((el) => el.parentId === null);
    setCategories(newCatalogList);
  };
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = (item) => {
    setCurrentCategory(item);
    setShow(true);
  };

  const deleteCatalog = async (id) => {
    console.log(id);
    console.log(token);
    await axios
      .get(`${baseURL}shop/category/delete`, {
        params: {
          categoryId: id,
        },
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          Authorization: "Bearer " + token,
        },
      })
      .then((res) => {
        console.log(res.data.data);
        setNotification("success");
        setTimeout(() => {
          setNotification("");
        }, 5000);
      })
      .catch((err) => {
        setShow(false);
        setNotification("error");
        setTimeout(() => {
          setNotification("");
        }, 5000);
      });
    setCurrentCategory(null);
    setShow(false);
  };
  return (
    <>
      {notification === "success" && (
        <div class="myAlert-top alert alert-success">
          <strong>Категория удалена!</strong>
        </div>
      )}
      {notification === "error" && (
        <div class="myAlert-top alert alert-danger">
          <strong> {t("admin.cantDelete")}</strong>
        </div>
      )}
      <Header />
      <div className="container">
        <h1>{(state?.type && state?.id) ? 'Подкатегории товаров и услуг' : t("admin.catalogs")}</h1>

        {(!state?.type || (state?.type === "sub" && state?.id)) ? (
          <div className="category__btn">
            <button
              onClick={() => {
                navigate("/admin/create-catalog", { state: { id: state?.id } });
              }}
              type="button"
              className="btn btn-success"
            >
              {(state?.type && state?.id) ? 'Создать подкатегорию' : t("admin.createCatalogs")}
            </button>
            <button
              onClick={() => {
                state?.type && state?.id ? navigate('/admin/catalogs', {
                  state: { type: state?.type, back:'back'},
                }) : navigate('/admin');;
                
              }}
              type="button"
              className="btn btn-primary"
            >
              {t("returnBack")}
            </button>
          </div>
        ) : <div className="banners-btn">
          <button
            onClick={() => {navigate('/admin');;
            }}
            type="button"
            className="btn btn-primary"
          >
            {t("returnBack")}
          </button>
        </div>}

        {loading && <Loader />}
        {categories.map((category) => (
          <div className="card category ">
            <div className="card-body category__item">
              <div className="category__info">
                <img
                  src={category.photo ? `data:image/jpeg;base64,${category.photo}` : require('../../assets/notPhoto.jpg')}
                  className="card-img-top category__img"
                  alt="..."
                />
                {(state?.type === "sub" || state?.type === "product") &&
                  !state?.id ? (
                  <h3
                    onClick={() => {
                      state?.id
                        ? navigate("/admin/products", {
                          state: { type: state?.type, id: category.id },
                        })
                        : navigate("/admin/catalogs", {
                          state: { type: state?.type, id: category.id },
                        });
                    }}
                    className="card-titlec category__title"
                  >
                    {category.nameRu}
                  </h3>
                ) : state?.type === "product" ? (
                  <h3
                    onClick={() => {
                      navigate("/admin/products", {
                        state: { type: state?.type, id: category.id, backId: state?.id },
                      });
                    }}
                    className="card-title category__title"
                  >
                    {category.nameRu}
                  </h3>
                ) : (
                  <h3 className="card-title">{category.nameRu}</h3>
                )}
              </div>

              {(!state?.type || (state?.id && state?.type != "product")) && (
                <div className="category__edit">
                  <button
                    onClick={() => {
                      navigate("/admin/create-catalog", {
                        state: {
                          categoryId: category.id,
                          category: category,
                        },
                      });
                    }}
                    type="button"
                    className="btn btn-primary"
                  >
                    {t("edit")}
                  </button>
                  <>
                    <Button
                      variant="btn btn-danger"
                      onClick={() => handleShow(category)}
                    >
                      {t("delete")}
                    </Button>

                    <Modal show={show} onHide={handleClose}>
                      <Modal.Header closeButton>
                        <Modal.Title>{t("admin.deleteCatalog")}</Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        {t("admin.deleteCatalogQuestion")} {currentCategory?.nameRu}?
                      </Modal.Body>
                      <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                          {t("cancel")}
                        </Button>
                        <Button
                          variant="btn btn-danger"
                          onClick={() => deleteCatalog(currentCategory.id)}
                        >
                          {t("delete")}
                        </Button>
                      </Modal.Footer>
                    </Modal>
                  </>
                </div>
              )}
            </div>
          </div>
        ))}
        {!loading && !categories?.length && (
          <div className="empty">
            {" "}
            <h1>Пустая страница</h1>
          </div>
        )}
                {totalPages && (
          <nav aria-label="Page navigation example">
            <ul class="pagination">
              <li class="page-item">
                <button
                  class="page-link"
                  disabled={currentPage === 1}
                  aria-label="Previous"
                  onClick={() => getCatalogs(currentPage - 1)}
                >
                  <span aria-hidden="true">&laquo;</span>
                </button>
              </li>
              {categories &&
                Array.from(Array(totalPages)).map((page, i) => (
                  <li class="page-item">
                    <button
                      class="page-link"
                      style={
                        currentPage === i + 1
                          ? { backgroundColor: "blue", color: "white" }
                          : {}
                      }
                      onClick={() => getCatalogs(i + 1)}
                    >
                      {i + 1}
                    </button>
                  </li>
                ))}
              <li class="page-item">
                <button
                  class="page-link"
                  aria-label="Next"
                  disabled={currentPage === totalPages}
                  onClick={() => getCatalogs(currentPage + 1)}
                >
                  <span aria-hidden="true">&raquo;</span>
                </button>
              </li>
            </ul>
          </nav>
        )}
      </div>
    </>
  );
}
