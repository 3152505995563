const BASE_URL_PRODUCTION = "https://backend.1-q.kz/";
const BASE_URL_DEVELOPMENT = "https://backend.1-q.kz/";

const BACK_LINK_PRODUCTION = "https://1-q.kz/index.html#/pay-shop-success";
const BACK_LINK_TEST =  "https://backend.1-q.kz/index.html#/pay-shop-success";
const BACK_LINK_DEVELOPMENT = "http://localhost:3000/#/pay-shop-success";

const FAILURE_LINK_PRODUCTION = "https://1-q.kz/index.html#/pay-error";
const FAILURE_LINK_TEST = "https://backend.1-q.kz/index.html#/pay-error";
const FAILURE_LINK_DEVELOPMENT = "http://localhost:3000/#/pay-error";

export const ACTIVE_BASE_URL = BASE_URL_PRODUCTION;
export const ACTIVE_BACK_LINK = BACK_LINK_PRODUCTION;
export const ACTIVE_FAILURE_LINK = FAILURE_LINK_PRODUCTION;