import React from "react";
import Header from "../../components/Header";
import "./styles.scss";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

export default function PayError() {
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <>
      <Header />
      <div className="container pay">
        <h1 className="text-center">{t("error")}</h1>

        <img  className="pay__icon" src={require("../../assets/icons/error.png")} alt="" />
        <span onClick={() => navigate('/personal')} className="pay__link">
          {t("goProfile")}
        </span>
      </div>
    </>
  );
}
