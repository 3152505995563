import React, { useEffect, useState } from "react";
import Header from "../../components/Header";
import API from "../../api/apiController";
import "./LastWinners.scss";
import Loader from "../../components/Loader";
import { Modal } from "react-bootstrap";
import { useNavigate, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

export default function LastWinners() {
  const [loading, setLoading] = useState(false);
  const [winners, setWinners] = useState([]);
  const [modalShow, setModalShow] = React.useState(false);
  const [currentUser, setCurrentUser] = useState(null);
  const { state } = useLocation();
  const { t } = useTranslation();
  const lang = localStorage.getItem('lang')
  
  useEffect(() => {
    getLastWinners();
  }, [state]);
  const getLastWinners = async () => {
    setLoading(true);
    API.user.getLastWinners().then(res => {
      console.log(res.data)
      setWinners(res.data.data)
      setLoading(false);
    })
  };

  const ageDeclination = (age) => {
    if (!age) return;
    let arr = [t("ageDeclination.god"), t("ageDeclination.goda"), t("ageDeclination.let")];
    return age + " " + arr[(age % 100 > 4 && age % 100 < 20) ? 2 : [2, 0, 1, 1, 1, 2][(age % 10 < 5) ? age % 10 : 5]] + ",";
  }
  return (
    <>
      <Header />
      <div className="container">
        <h1> {t("happyParticipate")}</h1>
        {loading && <Loader />}
        {winners.map((winner) => (
          <div className="card category">
            <div className="card-body category__item">
              <div className="category__info">
                <img
                  onClick={() => { setModalShow(true); setCurrentUser(winner) }}
                  src={winner.photo ? `data:image/jpeg;base64,${winner?.photo}` : require('../../assets/notPhoto.jpg')}
                  className="card-img-top category__img winners__photo-small"
                  alt="..."
                />
                <div>
                  <h2 className="card-title">{winner.productName}</h2>
                  <h4>{winner.name}, {ageDeclination(winner.age)} {winner[`cityName${lang}`]}</h4>
                </div>
              </div>
              <ShowImage
                show={modalShow}
                currentUser={currentUser}
                onHide={() => setModalShow(false)}
              />
            </div>
          </div>
        ))}
        {!loading && !winners?.length && (
          <div className="empty">
            {" "}
            <h1>{t("emptyPage")}</h1>
          </div>
        )}
      </div>
    </>
  );
}

function ShowImage(props) {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Фото
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <img
          src={props.currentUser?.photo ? `data:image/jpeg;base64,${props.currentUser?.photo}` : require('../../assets/notPhoto.jpg')}
          className="winner__photo"
          alt="..."
        />
      </Modal.Body>
    </Modal>
  );
}