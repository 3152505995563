import React from "react";
import Header from "../../components/Header";
import "./styles.scss";
import { useTranslation } from "react-i18next";

export default function Payments() {
  const { t } = useTranslation();
  return (
    <>
      <Header />
      <div className="container info">
        <h1>{t("footer.returnProduct")} </h1>
        <div>
        {t("returnProduct.title")}

        </div>
        <div>
          <h3>
          {t("returnProduct.returnList")}
          </h3>
          <ul>
            <li>
            {t("returnProduct.independentReturn")} <br /><br/>
            {t("returnProduct.independentReturnInfo")} 
            </li>
            <li>
            {t("returnProduct.deliveryReturn")} <br /><br/>
            {t("returnProduct.deliveryReturnInfo")} 
            </li>
          </ul>
          <h3> {t("returnProduct.rule")} 
          </h3>
          <div>
          {t("returnProduct.rule-1")} <br/><br/>
          {t("returnProduct.rule-2")} <br/><br/>
          {t("returnProduct.rule-3")} <br/><br/>
            <u>{t("returnProduct.attention")} </u>. {t("returnProduct.rule-3")} 

          </div>
        </div>
      </div>
    </>
  );
}
