import React, { useState, useEffect } from "react";
import Header from "../../components/Header";
import API from "../../api/apiController";
import EmptyBlock from "../../components/EmptyBlock";
import Loader from "../../components/Loader";
import { useNavigate, useLocation } from "react-router-dom";
import "./Catalogs.scss";
import { useTranslation } from "react-i18next";

export default function Catalogs() {
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { state } = useLocation();
  const [categories, setCategories] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize] = useState(5);
  const [totalPages, setTotalPages] = useState(0);
  const [photoArr, setPhotoArr] = useState([]);
  const lang = localStorage.getItem("lang");
  useEffect(() => {
    setLoading(true);
    paginate();
  }, []);

  const paginate = (page) => {
    console.log("paginate", currentPage);
    setLoading(true);
    setCategories([]);
    setCurrentPage(page);
    const paginationParams = {
      pageNumber: page ? page - 1 : currentPage - 1,
      pageSize: 10,
    };

    const params = {
      id: state?.id,
      active: true,
    };
    API.shop.getSubCategory(params, paginationParams).then((res) => {
      console.log(res.data.data);
      setTotalPages(res.data.data.totalPages);
      filterCatalogsParent(res.data.data.data.data);
      setLoading(false);
    });
  };

  const filterCatalogsParent = (data) => {
    const newCatalogList = data.filter((el) => el.hasProducts);
    // getPhotoCategories(data)
    setCategories(data);
  };

  const getPhotoCategories = (categories) => {
    categories.map((category) => {
      const params = {
        id: category.id,
      };
      let arr = [];
      API.shop.getPhotoCategory(params).then((res) => {
        const obj = {
          id: category.id,
          photo: res.data.data,
        };
        arr.push(obj);
        setPhotoArr(arr);
        category.photo = res.data.data;
      });
    });
    console.log("df", photoArr);
  };
  return (
    <>
      <Header />

      <div className="container ">
        <div className="back">
          <button
            onClick={() => {
              navigate("/");
            }}
            type="button"
            className="btn btn-primary"
          >
            {t("returnBack")}
          </button>
        </div>
        <div class="row row-cols-1 row-cols-md-5 g-4 categories">
          {loading && <Loader />}
          {categories &&
            categories.map((category) => (
              <div
                key={category.id}
                class="card categories__item"
                onClick={() => {
                  navigate("/products", {
                    state: { id: category.id, backId: state?.id },
                  });
                }}
              >
                <div class="card-body categories__body">
                  <h5 class="text-center card-title  text-break">
                    {category[`name${lang}`]}
                  </h5>
                  <img
                    src={`https://backend.1-q.kz/shop/category/image?id=${category.id}`}
                    className="card-img-top categories__img"
                    alt="..."
                  />
                </div>
              </div>
            ))}
          {!loading && !categories?.length && <EmptyBlock />}
        </div>
        {totalPages && (
          <nav aria-label="Page navigation example">
            <ul class="pagination">
              <li class="page-item">
                <button
                  class="page-link"
                  disabled={currentPage === 1}
                  aria-label="Previous"
                  onClick={() => paginate(currentPage - 1)}
                >
                  <span aria-hidden="true">&laquo;</span>
                </button>
              </li>
              {categories &&
                Array.from(Array(totalPages)).map((page, i) => (
                  <li class="page-item">
                    <button
                      class="page-link"
                      style={
                        currentPage === i + 1
                          ? { backgroundColor: "blue", color: "white" }
                          : {}
                      }
                      onClick={() => paginate(i + 1)}
                    >
                      {i + 1}
                    </button>
                  </li>
                ))}
              <li class="page-item">
                <button
                  class="page-link"
                  aria-label="Next"
                  disabled={currentPage === totalPages}
                  onClick={() => paginate(currentPage + 1)}
                >
                  <span aria-hidden="true">&raquo;</span>
                </button>
              </li>
            </ul>
          </nav>
        )}
      </div>
    </>
  );
}
