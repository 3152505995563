import React, { useState } from "react";
import Header from "../../components/Header";
import "./Admin.scss";
import { Modal, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import API from "../../api/apiController";
import { saveAs } from 'file-saver';

export default function Admin() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const role = localStorage.getItem("role");
  const [modal, setModal] = useState(false);
  const [modalParticipant, setModalParticipant] = useState(false);
  const [typeReport, setTypeReport] = useState("");

  const formatDate = (dateTime) => {
    const date = new Date(dateTime);
    const formattedDate = date.toISOString().slice(0, 19).replace('T', ' ');
    return formattedDate;
  };

  const closeModal = (startDate, endDate, typeReport) => {
    console.log("report", typeReport);
    const params = {
      startDate: startDate,
      endDate: endDate,
    };
    console.log(params)
    switch (typeReport) {
      case "shop": // if (x === 'value1')
        API.statistics.reportShop(params).then((res) => {
          downloadBlob(res);
        });
        break;
      case "stocks": // if (x === 'value2')
        API.statistics.reportStock(params).then((res) => {
          downloadBlob(res);
        });
        break;
      case "participant":
        const formattedStartDate = formatDate(startDate);
        const formattedEndDate = formatDate(endDate);
        const paramsParticipant = {
          startDate: formattedStartDate,
          endDate: formattedEndDate,
        }
      API.statistics.getParticipant(paramsParticipant).then((res) => {
        console.log("resParticipant:", res)
        downloadDocString(res);
      });
      setModalParticipant(false)
      break;
      case "favorite":
        API.statistics.reportShop(params).then((res) => {
        });
        break;
      default:
        setModal(false);
    }

    console.log(params);
    setModal(false);
    setModalParticipant(false)
  };

  const downloadUsersReport = () => {
    API.statistics.userStatistics().then((res) => {
      downloadDocString(res);
    });
  }

  const downloadFavoritesReport = () => {
    API.statistics.reportPoints().then((res) => {
      downloadDocString(res);
    });
  }

  const downloadBlob = (doc) => {
    const url = window.URL.createObjectURL(new Blob([doc.data]));
    const link = document.createElement("a");

    link.href = url;
    link.setAttribute("download", `${Date.now()}.xlsx`);

    document.body.appendChild(link);
    link.click();

    link.remove();
  }

  const downloadDocString = (doc) => {
    let  file = `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${doc.data.data}`;
    setTimeout(() => {
      saveAs(file,  `${Date.now()}.xlsx`);
    }, 1300);
  };
  return (
    <>
      <Header />
      <div className="container admin">
        <h3> {t("admin.administration")}</h3>
        {role === "SUPER_ADMIN_ROLE" && (
          <div className="card items">
            <div className="card-body">
              <h5 className="card-title">{t("admin.administration")}</h5>
              <button
                onClick={() => {
                  navigate("/admin/users");
                }}
                className="btn btn-primary"
              >
                {t("admin.controlUsers")}
              </button>
            </div>
          </div>
        )}
        <div className="card items">
          <div className="card-body">
            <h5 className="card-title">{t("admin.banners")}</h5>
            <button
              onClick={() => {
                navigate("/admin/banners");
              }}
              className="btn btn-primary"
            >
              {t("admin.editBanners")}
            </button>
          </div>
        </div>
        <div className="card items">
          <div className="card-body">
            <h5 className="card-title">{t("admin.push")}</h5>
            <button
                onClick={() => {
                  navigate("/admin/notifications");
                }}
                className="btn btn-primary"
            >
              {t("admin.pushNotif")}
            </button>
          </div>
        </div>
        <h3>{t("admin.promotionalGoods")}</h3>
        <div className="card items">
          <div className="card-body">
            <h5 className="card-title">{t("admin.catalogsStocks")}</h5>
            <button
              onClick={() => {
                navigate("/admin/catalogs-stocks");
              }}
              className="btn btn-primary"
            >
              {t("admin.createCatalogsStocks")}
            </button>
          </div>
        </div>
        <div className="card items">
          <div className="card-body">
            <h5 className="card-title">{t("admin.subCatalogsStocks")}</h5>
            <button
              onClick={() => {
                navigate("/admin/catalogs-stocks", {
                  state: { type: "sub", id: null },
                });
              }}
              className="btn btn-primary"
            >
              {t("admin.createSubCatalogsStocks")}
            </button>
          </div>
        </div>
        <div className="card items">
          <div className="card-body">
            <h5 className="card-title">{t("admin.stocks")}</h5>
            <button
              onClick={() => {
                navigate("/admin/catalogs-stocks", {
                  state: { type: "product", id: null },
                });
              }}
              className="btn btn-primary"
            >
              {t("admin.createStocks")}
            </button>
          </div>
        </div>
        <h3>{t("admin.goodsForShop")}</h3>
        <div className="card items">
          <div className="card-body">
            <h5 className="card-title">{t("admin.catalogs")}</h5>
            <button
              onClick={() => {
                navigate("/admin/catalogs");
              }}
              className="btn btn-primary"
            >
              {t("admin.createCatalogs")}
            </button>
          </div>
        </div>
        <div className="card items">
          <div className="card-body">
            <h5 className="card-title">{t("admin.subCatalogs")}</h5>
            <button
              onClick={() => {
                navigate("/admin/catalogs", {
                  state: { type: "sub", id: null },
                });
              }}
              className="btn btn-primary"
            >
              {t("admin.createSubCatalogs")}
            </button>
          </div>
        </div>
        <div className="card items">
          <div className="card-body">
            <h5 className="card-title">{t("admin.goods")}</h5>
            <button
              onClick={() => {
                navigate("/admin/catalogs", {
                  state: { type: "product", id: null },
                });
              }}
              className="btn btn-primary"
            >
              {t("admin.createGoods")}
            </button>
          </div>
        </div>
        <h3>Отчеты</h3>
        <div className="card items">
          <div className="card-body">
            <h5 className="card-title">Отчет интернет-магазина</h5>
            <button
              onClick={() => {
                setModal(true);
                setTypeReport("shop");
              }}
              className="btn btn-primary"
            >
              Скачать отчет
            </button>
          </div>
        </div>
        <div className="card items">
          <div className="card-body">
            <h5 className="card-title">Отчет по акциям</h5>
            <button
              onClick={() => {
                setModal(true);
                setTypeReport("stocks");
              }}
              className="btn btn-primary"
            >
              Скачать отчет
            </button>
          </div>
        </div>
        <div className="card items">
          <div className="card-body">
            <h5 className="card-title">Список участников в розыгрышах</h5>
            <button
              onClick={() => {
                setModalParticipant(true);
                setTypeReport("participant");
              }}
              className="btn btn-primary"
            >
              Скачать отчет
            </button>
          </div>
        </div>
        <div className="card items">
          <div className="card-body">
            <h5 className="card-title">Список пользователей</h5>
            <button
              onClick={() => {
                downloadUsersReport()
              }}
              className="btn btn-primary"
            >
              Скачать отчет
            </button>
          </div>
        </div>
        <div className="card items">
          <div className="card-body">
            <h5 className="card-title">Отчет по баллам</h5>
            <button
              onClick={() => {
                downloadFavoritesReport()
              }}
              className="btn btn-primary"
            >
              Скачать отчет
            </button>
          </div>
        </div>
        <ModalReport
          show={modal}
          onHide={() => setModal(false)}
          typeReport={typeReport}
          sendDataToParent={closeModal}
        />
        <ModalParticipant
          show={modalParticipant}
          onHide={() => setModalParticipant(false)}
          typeReport={typeReport}
          sendDataToParent={closeModal}
        />
      </div>
    </>
  );
}

function ModalReport(props) {
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  return (
    <>
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">Отчет</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="container">
            <div className="mb-3">
              <label className="form-label">От</label>
              <input
                type="date"
                id="party"
                name="partydate"
                className="form-control"
                value={startDate}
                onChange={(event) => setStartDate(event.target.value)}
              />
            </div>
            <div className="mb-3">
              <label className="form-label">До</label>
              <input
                type="date"
                id="party"
                name="partydate"
                className="form-control"
                value={endDate}
                onChange={(event) => setEndDate(event.target.value)}
              />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={() => {
              props.sendDataToParent(startDate, endDate, props.typeReport);
            }}
            disabled={!endDate || !startDate}
          >
            Скачать
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

function ModalParticipant(props) {
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  return (
    <>
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">Отчет</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="container">
            <div className="mb-3">
              <label className="form-label">От</label>
              <input
                type="date"
                step="1"
                id="party"
                name="partydate"
                className="form-control"
                value={startDate}
                onChange={(event) => setStartDate(event.target.value)}
              />
            </div>
            <div className="mb-3">
              <label className="form-label">До</label>
              <input
                type="date"
                step="1"
                id="party"
                name="partydate"
                className="form-control"
                value={endDate}
                onChange={(event) => setEndDate(event.target.value)}
              />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={() => {
              props.sendDataToParent(startDate, endDate, props.typeReport);
            }}
            disabled={!endDate || !startDate}
          >
            Скачать
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
