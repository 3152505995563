import React from "react";
import "./Login.scss";

import { useRef, useState, useEffect } from "react";

import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import axios from "axios";
import Header from "../../components/Header";
import API from "../../api/apiController";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import offer from "../../assets/docs/oferta.pdf";
import privacy from "../../assets/docs/politics.pdf";

const ages = [
  { code: 14 },
  { code: 15 },
  { code: 16 },
  { code: 17 },
  { code: 18 },
  { code: 19 },
  { code: 20 },
  { code: 21 },
  { code: 22 },
  { code: 23 },
  { code: 24 },
  { code: 25 },
  { code: 26 },
  { code: 27 },
  { code: 28 },
  { code: 29 },
  { code: 30 },
  { code: 31 },
  { code: 32 },
  { code: 33 },
  { code: 34 },
  { code: 35 },
  { code: 36 },
  { code: 37 },
  { code: 38 },
  { code: 39 },
  { code: 40 },
  { code: 41 },
  { code: 42 },
  { code: 43 },
  { code: 44 },
  { code: 45 },
  { code: 46 },
  { code: 47 },
  { code: 48 },
  { code: 49 },
  { code: 50 },
  { code: 51 },
  { code: 52 },
  { code: 53 },
  { code: 54 },
  { code: 55 },
  { code: 56 },
  { code: 57 },
  { code: 58 },
  { code: 59 },
  { code: 60 },
  { code: 61 },
  { code: 62 },
  { code: 63 },
  { code: 64 },
  { code: 65 },
  { code: 66 },
  { code: 67 },
  { code: 68 },
  { code: 69 },
  { code: 70 },
];

export default function Login() {
  const userRef = useRef();
  const errRef = useRef();
  const { t } = useTranslation();

  const lastLogin = localStorage.getItem("login");

  const [cityId, setCityId] = useState([]);
  const [login, setUser] = useState("");
  const [password, setPwd] = useState("");
  const [errMsg, setErrMsg] = useState("");
  const [repeatPassword, setRepeatPassword] = useState("");
  const [loginRegister, setLoginRegister] = useState("");
  const [notification, setNotification] = React.useState("");
  const [age, setAge] = useState(null);
  const [userPassword, setUserPassword] = useState(null);
  const [userRepeatPassword, setUserRepeatPassword] = useState(null);
  const [userCityId, setUserCityId] = useState(null);
  const [userGender, setUserGender] = useState(null);
  const [name, setName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [successRegister, setSuccessRegister] = useState(false);
  const [validPassword, setValidPassword] = useState(false);
  const [passwordMatches, setPasswordMatches] = useState(false);
  const [subNumber, setSubNumber] = useState(null);
  const [fullNumber, setFullNumber] = useState(null);
  const [trySave, setTrySave] = useState(false);

  const [modalShow, setModalShow] = React.useState(false);

  const [hidePwd, setHidePwd] = useState(false);
  const [hide, setHide] = useState(false);
  const [hideRepeat, setHideRepeat] = useState(false);

  const [inCorrectEmail, setInCorrectEmail] = useState(false);
  const [pswdMsg, setPswdMsg] = useState(false);
  const [repeatPswdMsg, setRepeatPswdMsg] = useState(false);

  const [privacyCheck, setPrivacy] = useState(true);
  const [offerCheck, setOffer] = useState(true);

  const [ageRange, setAgeRange] = useState([]);
  const lang = localStorage.getItem("lang");

  const navigate = useNavigate();
  const baseURL = "https://backend.1-q.kz/";

  const gender = [
    {
      name: t("registration.male"),
      code: "MALE",
    },
    {
      name: t("registration.female"),
      code: "FEMALE",
    },
  ];

  useEffect(() => {
    setUser(lastLogin);
    let arr = [];
    for (let i = 14; i < 71; i++) {
      let obj = {
        code: i,
      };
      arr.push(obj);
    }
    setAgeRange(arr);
    console.log(ageRange);
    API.auth.getCity().then((response) => setCityId(response.data.data));
    userRef.current.focus();
  }, []);

  useEffect(() => {
    setErrMsg("");
  }, [login, password]);

  const validateEmail = (text) => {
    console.log(text);
    let reg = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w\w+)+$/;
    if (reg.test(text) === false) {
      setLoginRegister(text.toLowerCase());
      setInCorrectEmail(true);
      return false;
    } else {
      setLoginRegister(text.toLowerCase());
      setInCorrectEmail(false);
    }
  };
  const validatePassword = (text) => {
    if (text.length < 8) {
      setValidPassword(false);
      setUserPassword(text);
      setPswdMsg(true);
      return false;
    } else {
      setValidPassword(true);
      setPswdMsg(false);
      setUserPassword(text);
    }

    if (text === repeatPassword) {
      setPasswordMatches(true);
    } else {
      setPasswordMatches(false);
      return false;
    }
  };

  const updateDate = () => {
    setModalShow(false);
  };

  const confirmPassword = (text) => {
    if (text === userPassword) {
      setPasswordMatches(true);
      setRepeatPswdMsg(false);
      setUserRepeatPassword(text);
    } else {
      setRepeatPswdMsg(true);
      setUserRepeatPassword(text);
      setPasswordMatches(false);
      return false;
    }
  };
  useEffect(() => {
    let number = "+77".concat(fullNumber);
    setPhoneNumber(number);
  }, [subNumber, fullNumber]);

  const handleInput = (e) => {
    //const formattedPhoneNumber = formatPhoneNumber(e);
    if (e.length > 9) {
    } else {
      setFullNumber(e);
      let number;
      number = "+77".concat(fullNumber);
      setPhoneNumber(number);
    }
    console.log(phoneNumber);
  };

  const handleSubInput = (value) => {
    //const formattedPhoneNumber = formatPhoneNumber(e);
    setSubNumber(value);
  };

  const register = async (e) => {
    //setErrortext("");
    setTrySave(true);
    e.preventDefault();
    const data = {
      login: loginRegister.toLowerCase(),
      password: userPassword,
      passwordRepeat: userRepeatPassword,
      phoneNumber: phoneNumber,
      name: name,
      age: age,
      countryId: 1,
      cityId: userCityId,
      sex: userGender,
    };

    //setLoading(true);
    await API.auth
      .register(data)
      .then((response) => {
        //setLoading(false);
        if (response.data.httpStatus === "OK") {
          setSuccessRegister(true);
          //setIsRegistraionSuccess(true);
        } else {
          //setErrortext(response.statusText);
        }
      })
      .catch((error) => {
       
       
       if( error.response.data.errors[0][`valueEn`] == 'User already exist'){
        setNotification("error");
         setErrMsg(error.response.data.errors[0][`value${lang}`]);
        }
        setTimeout(() => {
          setNotification("");
        }, 5000);
        //setLoading(false);
      });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const data = {
      login: login.toLowerCase(),
      password: password,
    };
    try {
      axios
        .post(`${baseURL}auth/login`, data, {
          headers: {
            "Content-Type": "application/json;charset=UTF-8",
          },
        })
        .then((res) => {
          console.log(res.data);
          localStorage.setItem("token", res.data.token);
          localStorage.setItem("refreshToken", res.data.refreshToken);
          localStorage.setItem("role", res.data.role);
          localStorage.setItem("login", login);
          localStorage.setItem("name", res.data.name);
          localStorage.setItem("city", res.data.city[`name${lang}`]);
          localStorage.setItem("auth", "true");
          if (
            res.data.role === "SUPER_ADMIN_ROLE" ||
            res.data.role === "ADMIN_ROLE"
          ) {
            navigate("/admin");
          } else {
            navigate("/");
          }
        })
        .catch((err) => {
          if (!err?.response) {
            setErrMsg("No Server Response");
          } else if (err.response?.status === 400) {
            setErrMsg("Missing Username or Password");
          } else if (err.response?.status === 401) {
            setErrMsg("Unauthorized");
          } else {
            setErrMsg("Login Failed");
          }
          errRef.current.focus();
        });
    } catch (err) {}
  };

  return (
    <main>
            {notification === "error" && (
        <div class="myAlert-top alert alert-danger">
          <strong>{errMsg}!</strong>
        </div>
      )}
      <Header />
      <div class="account-area mt-70 mb-70">
        <div class="container">
          <div class="row">
            <div class="col-lg-6">
              <div class="basic-login mb-50">
                <h5>{t("auth.authorization")}</h5>
                {errMsg && (
                  <div
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      color: "red",
                    }}
                  >
                    {t("auth.wrong")}
                  </div>
                )}
                <form onSubmit={handleSubmit}>
                  <label for="email">
                    {t("auth.login")} <span>*</span>
                  </label>
                  <input
                    pattern="[A-Za-z0-9._+-]+@[A-Za-z0-9-]+\.[a-z]{2,}"
                    required
                    id="email"
                    type="email"
                    title="sfds"
                    placeholder={t("auth.loginPlaceholder")}
                    ref={userRef}
                    autoComplete="off"
                    onChange={(e) => setUser(e.target.value)}
                    value={login}
                  />
                  <div class="mb-3 password-container">
                    <label class="form-label">{t("auth.password")}</label>
                    <input
                      type={!hidePwd ? "password" : "text"}
                      class="form-control pswd"
                      value={password}
                      placeholder={t("auth.passwordPlaceholder")}
                      onChange={(event) => setPwd(event.target.value)}
                    />
                    <img
                      onClick={() => setHidePwd(!hidePwd)}
                      className="hide"
                      src={
                        hidePwd
                          ? require("../../assets/icons/hide.png")
                          : require("../../assets/icons/unhide.png")
                      }
                      alt=""
                    />
                  </div>

                  <div class="login-action mb-10 fix">
                    <span class="forgot-login f-right">
                      <a
                        style={{ cursor: "pointer" }}
                        onClick={() => setModalShow(true)}
                      >
                        {t("auth.forgotPassword")}
                      </a>
                    </span>
                    <ChangePasswordModal
                      show={modalShow}
                      onHide={() => {
                        updateDate();
                      }}
                      sendDataToParent={updateDate}
                    />
                  </div>

                  <button class="tp-in-btn w-100">{t("auth.sigIn")}</button>
                </form>
              </div>
            </div>
            {successRegister ? (
              <div class="col-lg-6">
                <div class="basic-login register__success">
                  <h4 className="text-center">
                    {t("registration.registrationSuccess")}
                  </h4>
                  <h4 className="text-center">
                    {t("registration.bonusRegister")}
                  </h4>
                  <h5>{t("registration.info")}</h5>
                </div>
              </div>
            ) : (
              <div class="col-lg-6">
                <div class="basic-login">
                  <h5>{t("registration.registration")}</h5>
                  <form onSubmit={register} action="#">
                    <div class="mb-3">
                      <label class="form-label">
                        {t("registration.login")}
                      </label>
                      <input
                        type="text"
                        class="form-control"
                        style={{
                          marginBottom: "0px",
                        }}
                        value={loginRegister}
                        placeholder={t("registration.loginPlaceholder")}
                        onChange={(event) => validateEmail(event.target.value)}
                      />
                      {inCorrectEmail && (
                        <div
                          style={{
                            color: "red",
                          }}
                        >
                          {t("registration.correctLogin")}
                        </div>
                      )}
                      {!loginRegister && trySave && (
                        <div
                          style={{
                            color: "red",
                          }}
                        >
                          {t("registration.validate")}
                        </div>
                      )}
                    </div>
                    <div class=" password-container">
                      <label class="form-label">
                        {t("registration.password")}
                      </label>
                      <input
                        type={!hide ? "password" : "text"}
                        class="form-control pswd"
                        value={userPassword}
                        style={{
                          marginBottom: "0px",
                        }}
                        placeholder={t("registration.passwordPlaceholder")}
                        onChange={(event) =>
                          validatePassword(event.target.value)
                        }
                      />
                      {validPassword && (
                        <img
                          className="check"
                          src={require("../../assets/icons/check.png")}
                          alt=""
                        />
                      )}
                      <img
                        onClick={() => setHide(!hide)}
                        className="hide"
                        src={
                          hide
                            ? require("../../assets/icons/hide.png")
                            : require("../../assets/icons/unhide.png")
                        }
                        alt=""
                      />
                    </div>
                    {pswdMsg && (
                      <div
                        style={{
                          color: "red",
                        }}
                      >
                        {t("registration.passwordMustBe")}
                      </div>
                    )}
                    {!userPassword && trySave && (
                      <div
                        style={{
                          color: "red",
                        }}
                      >
                        {t("registration.validate")}
                      </div>
                    )}
                    <div class="mb-3"></div>
                    <div class=" password-container">
                      <label class="form-label">
                        {t("registration.confirmPassword")}
                      </label>
                      <input
                        type={!hideRepeat ? "password" : "text"}
                        class="form-control pswd"
                        value={userRepeatPassword}
                        style={{
                          marginBottom: "0px",
                        }}
                        placeholder={t("registration.repeatPassword")}
                        onChange={(event) =>
                          confirmPassword(event.target.value)
                        }
                      />

                      {passwordMatches && (
                        <img
                          className="check"
                          src={require("../../assets/icons/check.png")}
                          alt=""
                        />
                      )}
                      <img
                        onClick={() => setHideRepeat(!hideRepeat)}
                        className="hide"
                        src={
                          hideRepeat
                            ? require("../../assets/icons/hide.png")
                            : require("../../assets/icons/unhide.png")
                        }
                        alt=""
                      />
                    </div>
                    {repeatPswdMsg && (
                      <div
                        style={{
                          color: "red",
                        }}
                      >
                        {t("registration.passwordNotMatches")}
                      </div>
                    )}
                    {!userRepeatPassword && trySave && (
                      <div
                        style={{
                          color: "red",
                        }}
                      >
                        {t("registration.validate")}
                      </div>
                    )}
                    <div class="mb-3"></div>
                    <div class="mb-3">
                      <label class="form-label">{t("registration.name")}</label>
                      <input
                        type="text"
                        class="form-control"
                        style={{
                          marginBottom: "0px",
                        }}
                        value={name}
                        placeholder={t("registration.namePlaceholder")}
                        onChange={(event) => setName(event.target.value)}
                      />
                      {!name && trySave && (
                        <div
                          style={{
                            color: "red",
                          }}
                        >
                          {t("registration.validate")}
                        </div>
                      )}
                    </div>

                    <div class="mb-3">
                      <label class="form-label">
                        {t("registration.number")}
                      </label>
                      <div
                        class="form-control select phoneInput"
                        style={{ display: "flex" }}
                      >
                        <span style={{ width: "50px" }}>+7 7</span>
                        <input
                          type="number"
                          pattern="[0-9]{9}"
                          maxlength="9"
                          onKeyDown={(evt) =>
                            (evt.key === "e" ||
                              evt.key === "." ||
                              evt.key === "-" ||
                              evt.key === "+") &&
                            evt.preventDefault()
                          }
                          style={{
                            borderColor: "white",
                            marginLeft: "-10px",
                            padding: "0px",
                            marginTop: "1px",
                          }}
                          value={fullNumber}
                          placeholder={t("registration.numberPlaceholder")}
                          onChange={(event) => handleInput(event.target.value)}
                        />
                      </div>
                      {!fullNumber && trySave && (
                        <div
                          style={{
                            color: "red",
                          }}
                        >
                          {t("registration.validate")}
                        </div>
                      )}
                    </div>
                    <div class="mb-3">
                      <label class="form-label">{t("registration.city")}</label>
                      <select
                        value={userCityId}
                        onChange={(e) => {
                          setUserCityId(e.target.value);
                        }}
                        class="form-control"
                        id="cars"
                      >
                        <option value=""></option>
                        {cityId.map((city) => (
                          <option value={city.id}>{city[`name${lang}`]}</option>
                        ))}
                      </select>
                      {!userCityId && trySave && (
                        <div
                          style={{
                            color: "red",
                          }}
                        >
                          {t("registration.validate")}
                        </div>
                      )}
                    </div>

                    <div class="mb-3">
                      <label class="form-label">
                        {t("registration.gender")}
                      </label>
                      <select
                        value={userGender}
                        onChange={(e) => {
                          setUserGender(e.target.value);
                        }}
                        class="form-control"
                        id="cars"
                      >
                        <option value=""></option>
                        {gender.map((city) => (
                          <option value={city.code}>{city.name}</option>
                        ))}
                      </select>
                      {!userGender && trySave && (
                        <div
                          style={{
                            color: "red",
                          }}
                        >
                          {t("registration.validate")}
                        </div>
                      )}
                    </div>
                    <div class="mb-3">
                      <label class="form-label">
                        {t("registration.yourAge")}
                      </label>
                      <select
                        value={age}
                        onChange={(e) => {
                          setAge(e.target.value);
                        }}
                        class="form-control"
                        id="age"
                      >
                        <option value=""></option>
                        {ages.map((age) => (
                          <option value={age.code}>{age.code}</option>
                        ))}
                      </select>
                      {!age && trySave && (
                        <div
                          style={{
                            color: "red",
                          }}
                        >
                          {t("registration.validate")}
                        </div>
                      )}
                    </div>

                    <div style={{ display: "flex" }}>
                      {" "}
                      <div style={{ display: "flex" }}>
                        <input
                          style={{ width: "25px", height: "25px" }}
                          type="checkbox"
                          value={offerCheck}
                          checked={offerCheck}
                          onChange={() => {
                            setOffer(!offerCheck);
                          }}
                          id="flexCheckIndeterminate"
                        />
                        <a
                          href={offer}
                          download
                          style={{
                            fontWeight: "600",
                            fontSize: "16px",
                            color: "black",
                            marginLeft: "10px",
                          }}
                        >
                          {t("offer")}
                        </a>
                      </div>
                      <div style={{ display: "flex", marginLeft: "40px" }}>
                        <input
                          style={{ width: "25px", height: "25px" }}
                          type="checkbox"
                          value={privacyCheck}
                          checked={privacyCheck}
                          onChange={() => {
                            setPrivacy(!privacyCheck);
                          }}
                          id="flexCheckIndeterminate"
                        />
                        <a
                          href={privacy}
                          download
                          style={{
                            fontWeight: "600",
                            fontSize: "16px",
                            color: "black",
                            marginLeft: "10px",
                          }}
                        >
                          {t("privacy")}
                        </a>
                      </div>
                    </div>

                    <button
                      style={{
                        backgroundColor:
                          !privacyCheck || !offerCheck ? "grey" : "",
                      }}
                      disabled={!privacyCheck || !offerCheck}
                      class="tp-in-btn w-100"
                    >
                      {t("registration.registration")}
                    </button>
                  </form>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </main>
  );
}

function ChangePasswordModal(props) {
  const [userEmail, setUserEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [notification, setNotification] = React.useState("");
  const { t } = useTranslation();

  const forgotPassword = () => {
    if (!userEmail) {
      //setErrortext(t("e-mailPlaceholder"));
      return;
    }
    setLoading(true);
    let params = {
      email: userEmail.toLowerCase(),
    };

    API.user
      .changePassword(params)
      .then((response) => {
        props.sendDataToParent();
        setUserEmail("");
        setNotification("success");
        setTimeout(() => {
          setNotification("");
        }, 5000);
        setLoading(false);
      })
      .catch((error) => {
        props.sendDataToParent();
        setUserEmail("");
        setNotification("error");
        setTimeout(() => {
          setNotification("");
        }, 5000);
        setLoading(false);
      });
  };

  return (
    <>
      {notification === "success" && (
        <div class="myAlert-top alert alert-success">
          <strong>{t("auth.sendPassword")}</strong>
        </div>
      )}
      {notification === "error" && (
        <div class="myAlert-top alert alert-danger">
          <strong>{t("erro")}</strong>
        </div>
      )}
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {t("auth.forgotPassword")}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div class="mb-3 password-container">
            <label class="form-label">{t("auth.login")}</label>
            <input
              type="text"
              class="form-control"
              value={userEmail}
              placeholder={t("auth.loginPlaceholder")}
              onChange={(event) => setUserEmail(event.target.value)}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          {loading ? (
            <button class="btn btn-primary" type="button" disabled>
              <span
                class="spinner-grow spinner-grow-sm"
                role="status"
                aria-hidden="true"
              ></span>
              {t("auth.send")}
            </button>
          ) : (
            <Button
              onClick={() => {
                forgotPassword();
              }}
            >
              {t("auth.sendPasswordPost")}
            </Button>
          )}
        </Modal.Footer>
      </Modal>
    </>
  );
}
