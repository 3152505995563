import React from "react";
import Header from "../../components/Header";
import "./styles.scss";
import { useTranslation } from "react-i18next";

export default function Payments() {
  const { t } = useTranslation();
  return (
    <>
      <Header />
      <div className="container info">
        <h1>{t("contact")} </h1>
        <div>
        {t("contacts.question")}</div>
        <div>
        {t("contacts.help")}  </div>
      </div>
    </>
  );
}
