import React, { useEffect, useState , useRef} from "react";
import Header from "../../components/Header";
import "./History.scss";
import API from "../../api/apiController";
import {Declination} from '../../utils/helpers'
import { useTranslation } from "react-i18next";

export default function History() {
  const [product, setProduct] = useState(null);
  const [winnerProductsId, setWinnerProductsId] = useState([]);
  const [active, setActive] = useState(false);
  const [loading, setLoading] = useState(false);
  const [type, setType] = useState("shop");
  const [qr, setQr] = useState("");
  const loginRef = useRef(null);
  const [photoArr, setPhotoArr] = useState([]);
  const { t } = useTranslation();
  const lang = localStorage.getItem('lang')

  useEffect(() => {
    setProduct([]);
    setActive(false)
    loginRef.current.value = false;
    type === "stock" ? getParticipateProduct(true) : getParticipateShop(false);
  }, [type]);

  const  test = (number) => {
    let n = Math.abs(number);
    n %= 100;
    if (n >= 5 && n <= 20) {
      return t("points.ballov");
    }
    n %= 10;
    if (n === 1) {
      return t("points.ball");
    }
    if (n >= 2 && n <= 4) {
      return t("points.balla");
    }
    return t("points.ballov");
  };

  const getParticipateProduct = async (active) => {
    setLoading(true);
    const params = {
      active: active,
    };
    await API.user.getDraws(params).then(async (response) => {
      setProduct(response.data.data);
      getStocksPhoto(response.data.data)
      console.log(response.data.data);
      let winnerId = [];
      const login = await localStorage.getItem("login");
      response.data.data.map(async (el) => {
        if (el.winner == login) {
          winnerId.push(el.id);
        }
      });
      setWinnerProductsId(winnerId);
      setLoading(false);
    });
  };

  const seeActiveProduct = async () => {
    setActive(!active);
    if (!active) {
      type === "shop"
        ? await getParticipateShop(true)
        : await getParticipateProduct(false);
    } else {
      type === "shop"
        ? await getParticipateShop(false)
        : await getParticipateProduct(true);
    }
  };

  const getParticipateShop = async (active) => {
    setProduct([]);
    const params = {
      inactive: active,
    };
    await API.shop.getPurchased(params).then(async (response) => {
      setProduct(response.data.data);
      getProductsPhoto(response.data.data);
      setLoading(false);
    });
  };

  const getProductsPhoto = (products) => {
    products.map(list => {

      list.productList.map(product => {
        const params = {
          id: product.id,
        }
        let arr = []
        API.shop.getProductsPhoto(params)
        .then((res) => {
          const obj = {
            id:product.id,
            photo: res.data.data
          }
          arr.push(obj)
          setPhotoArr(arr)
          product.logoPhoto = res.data.data;
        });
      })

    })
  };

  const getStocksPhoto = (products) => {
    console.log('esponse.data.data', products)
    products.map(product => {

        const params = {
          id: product.id,
        }
        let arr = []
        API.user.getStocksPhoto(params)
        .then((res) => {
          const obj = {
            id:product.id,
            photo: res.data.data
          }
          arr.push(obj)
          setPhotoArr(arr)
          product.logoPhoto = res.data.data;
        });

    })
  };

  const formatPrice = (price) => {
    let formattedPrice = new Intl.NumberFormat().format(price);
    return formattedPrice.replaceAll(",", " ");
  };

  return (
    <>
      <Header />
      <div className="container history">
        <h1>{t("history.historyPurchase")}</h1>
        <div className="history__btn">
          <button
            onClick={() => {
              setType("shop");
            }}
            type="button"
            className={
              type === "shop" ? "btn btn-primary" : "btn btn-outline-primary"
            }
          >
            {t("history.product")}
          </button>
          <button
            onClick={() => {
              setType("stock");
            }}
            type="button"
            className={
              type === "stock" ? "btn btn-primary" : "btn btn-outline-primary"
            }
          >
            {t("history.stocks")}
          </button>
        </div>
        <div class="form-check history__active">
          <input
            class="form-check-input big-checkbox"
            type="checkbox"
            value={active}
            checked={active}
            ref={loginRef}
            onChange={() => seeActiveProduct()}
            id="flexCheckIndeterminate"
          />
          <label class="form-check-label" for="flexCheckIndeterminate">
            <p className="history__check-title">{t("history.showNonActive")}</p>
          </label>
        </div>

        {type === "shop" &&
          product?.map((p) => (
            <div className="card category">
              <div className="card-body category__item">
                <div className="user__info">
                  <div>
                    <h4>{t("history.order")} {p.invoiceId}</h4>
                  </div>
                </div>
                <div className="user__btn">
                  <>
                  </>
                </div>
              </div>
              {p?.productList?.map((p) => (
                <div className="">
                  <hr />
                  <div className="history__list">
                    <div className="">
                      <div>
                        <h4>{t("history.nameProduct")} {p[`name${lang}`]}</h4>
                      </div>
                      <div>
                        <h4>{t("history.cost")}: {formatPrice(p.price)} тенге</h4>
                      </div>
                      <div>
                        <h4>{t("history.count")}: {p.purchasedCount}</h4>
                      </div>
                    </div>
                    <img
                      className="d-block"
                      src={p.logoPhoto ? `data:image/jpeg;base64,${p.logoPhoto}` : require('../../assets/notPhoto.jpg')}
                      alt="Second slide"
                    />
                  </div>
                </div>
              ))}
            </div>
          ))}
        {type === "stock" &&
          product?.map((p) => (
            <div className="card category">
              <div className="history__list-stock">
                <div>
                  <h4>{p[`name${lang}`]}</h4>
                  <h4>
                  {t("history.participate")} {p.price} {test(p.price)}
                  </h4>
                  {winnerProductsId.includes(p.id) && (
                    <div className="winners">
                      <h4 className="winners">{t("history.youWinner")}</h4>
                    </div>
                  )}
                </div>
                <img
                  className="d-block"
                  src={p.logoPhoto ? `data:image/jpeg;base64,${p.logoPhoto}` : require('../../assets/notPhoto.jpg')}
                  alt="Second slide"
                />
              </div>
            </div>
          ))}
      </div>
    </>
  );
}
