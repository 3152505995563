import React, {useEffect, useState} from "react";
import axios from "axios";
import {useTranslation} from "react-i18next";
import {useNavigate, useLocation} from "react-router-dom";
import {Modal, Button} from "react-bootstrap";

import Header from "../../components/Header";
import Loader from "../../components/Loader";
import API from "../../api/apiController";
import "./Notifications.scss";

export default function AdminNotifications() {
    const {t} = useTranslation();
    const navigate = useNavigate();
    const {state} = useLocation();
    const baseURL = "https://backend.1-q.kz/";

    const [loading, setLoading] = useState(false);
    const token = localStorage.getItem("token");
    const [notifications, setNotifications] = useState([]);
    const [notification, setNotification] = React.useState("");
    const [currentCategory, setCurrentCategory] = useState(null);
    const [photoArr, setPhotoArr] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize] = useState(5);
    const [totalPages, setTotalPages] = useState(0);
    const [show, setShow] = useState(false);

    const [radioState, setRadioState] = useState('');

    useEffect(() => {
        setNotifications([]);
        setCurrentPage(1)
        setLoading(true);
        getNotifications(1)
        console.log('currentPage', currentPage)
    }, [state]);
    
    const getNotifications = (page) => {
        setNotifications([]);
        setLoading(true);
        setCurrentPage(page);
        const paginationParams = {
            pageNumber: page ? page - 1 : currentPage - 1,
            pageSize: 10,
        };

        API.push.getAllNotifications().then((response) => {
            // setTotalPages(response.data.data.totalPages);
            setNotifications(response.data.data);
            // getPhotoCategories(response.data.data.data.data);
            console.log("push-all", response.data);
            setLoading(false);
        })
    };

    // const filterCategory = (data) => {
    //   const newCategory = data.filter((el) => el.parentId === state?.id);
    //   setNotifications(newCategory);
    // };
    // const getPhotoCategories = (categories) => {
    //   categories.map(category => {
    //     const params = {
    //       id: category.id,
    //     }
    //     let arr = []
    //     API.shop.getPhotoCategory(params)
    //         .then((res) => {
    //           const obj = {
    //             id:category.id,
    //             photo: res.data.data
    //           }
    //           arr.push(obj)
    //           setPhotoArr(arr)
    //           category.photo = res.data.data;
    //         });
    //   })
    // };

    // const filterCatalogsParent = (data) => {
    //   const newCatalogList = data.filter((el) => el.parentId === null);
    //   setCategories(newCatalogList);
    // };

    const handleClose = () => setShow(false);
    
    const handleShow = () => {
        setShow(true);
    };

    const deletePush = async (id) => {
        console.log(id);
        console.log(token);
        await axios
            .post(`${baseURL}push-notification/delete?id=${id}`, {}, {
                headers: {
                    "Content-Type": "application/json;charset=UTF-8",
                    Authorization: "Bearer " + token,
                },
            })
            .then((res) => {
                console.log(res.data.data);
                setNotification("delete");
                setTimeout(() => {
                    setNotification("");
                }, 5000);
            })
            .catch((err) => {
                setShow(false);
                setNotification("error-delete");
                setTimeout(() => {
                    setNotification("");
                }, 5000);
            });
        setShow(false);
        window.location.reload();
    };

    const sendPushToAll = async (e) => {
        e.preventDefault()
        await axios
            .get(`${baseURL}push-notification/send-to-all`, {
                params: {
                    id: radioState,
                    lang: "KZ"
                },
                headers: {
                    "Content-Type": "application/json;charset=UTF-8",
                    Authorization: "Bearer " + token,
                },
            })
            .then((res) => {
                setNotification("success");
                setTimeout(() => {
                    setNotification("");
                }, 5000);
            })
            .catch((err) => {
                setShow(false);
                setNotification("error", err);
                setTimeout(() => {
                    setNotification("");
                }, 5000);
            });
        setShow(false);
    };

    const sendPushSubscribers = async (e) => {
        e.preventDefault()
        await axios
            .get(`${baseURL}push-notification/send-to-subscribers`, {
                params: {
                    id: radioState,
                    lang: "KZ"
                },
                headers: {
                    "Content-Type": "application/json;charset=UTF-8",
                    Authorization: "Bearer " + token,
                },
            })
            .then((res) => {
                setNotification("success");
                setTimeout(() => {
                    setNotification("");
                }, 5000);
            })
            .catch((err) => {
                setShow(false);
                setNotification("error");
                setTimeout(() => {
                    setNotification("");
                }, 5000);
            });
        setShow(false);
    }

    return (
        <>
            {notification === "success" && (
                <div className="myAlert-top alert alert-success">
                    <strong>{t("admin.pushSuccess")}</strong>
                </div>
            )}
            {notification === "delete" && (
                <div className="myAlert-top alert alert-success">
                    <strong>{t("admin.deletePushSuccess")}</strong>
                </div>
            )}
            {notification === "error" && (
                <div className="myAlert-top alert alert-danger">
                    <strong>{t("admin.pushError")}</strong>
                </div>
            )}
            {notification === "error-delete" && (
                <div className="myAlert-top alert alert-success">
                    <strong>{t("admin.deletePushError")}</strong>
                </div>
            )}
            <Header/>
            <div className="container">
                <h1>{t("admin.templatesPush")}</h1>
                <div className="push-btns">
                    <button
                        onClick={() => {
                            navigate("/admin/create-notification", {state: {id: state?.id}});
                        }}
                        type="button"
                        className="btn btn-success"
                    >
                        {t("admin.createTemplatesPush")}
                    </button>
                    <button
                        onClick={() => {
                            state?.type && state?.id ? navigate('/admin/notifications', {
                                state: {type: state?.type, back: 'back'},
                            }) : navigate('/admin');
                        }}
                        type="button"
                        className="btn btn-primary"
                    >
                        {t("returnBack")}
                    </button>
                </div>

                {loading && <Loader/>}
                {!loading && !notifications?.length && (
                    <div className="empty">
                        {" "}
                        <h1>Пустая страница</h1>
                    </div>
                )}
                <div className='push-list'>
                    {notifications.map((item) => (
                        <div className="push-item" key={item.id}>
                            <div className="push-item__check">
                                <input type="radio" name="push-templates" value={radioState} onChange={() => setRadioState(item.id)} />
                            </div>
                            <div className="push-item__table push-table">
                                <div className="push-table__row">
                                    <div className="push-table__item">
                                        <div className="push-table__head">
                                            Заголовок уведомления
                                        </div>
                                        <div className="push-table__text">
                                            {item.titleRu}
                                        </div>
                                    </div>
                                    <div className="push-table__item">
                                        <div className="push-table__head">
                                            Текст уведомления
                                        </div>
                                        <div className="push-table__text">
                                            {item.bodyRu}
                                        </div>
                                    </div>
                                    <div className="push-table__item">
                                        <div className="push-table__head">
                                            Изображение
                                        </div>
                                        <div className="push-table__text">
                                            <img
                                                src={item.photoRu ? `data:image/jpeg;base64,${item.photoRu}` : require('../../assets/notPhoto.jpg')}
                                                className="push-table__img"
                                                alt={item.titleRu}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="push-table__row">
                                    <div className="push-table__item">
                                        <div className="push-table__head">
                                            Хабарлама тақырыбы
                                        </div>
                                        <div className="push-table__text">
                                            {item.titleKz}
                                        </div>
                                    </div>
                                    <div className="push-table__item">
                                        <div className="push-table__head">
                                            Хабарландыру мәтіні
                                        </div>
                                        <div className="push-table__text">
                                            {item.bodyKz}
                                        </div>
                                    </div>
                                    <div className="push-table__item">
                                        <div className="push-table__head">
                                            Сурет
                                        </div>
                                        <div className="push-table__text">
                                            <img
                                                src={item.photoKz ? `data:image/jpeg;base64,${item.photoKz}` : require('../../assets/notPhoto.jpg')}
                                                className="push-table__img"
                                                alt={item.photoKz}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="action-btns">
                                <button
                                    onClick={() => {
                                        navigate("/admin/create-notification", {
                                            state: {
                                                pushId: item.id,
                                                push: item,
                                            },
                                        });
                                    }}
                                    type="button"
                                    className="btn btn-primary action-btns__edit"
                                >
                                    <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M4.7876 24.4919C4.2376 24.4919 3.76676 24.2961 3.3751 23.9044C2.98343 23.5127 2.7876 23.0419 2.7876 22.4919C2.7876 21.9419 2.98343 21.4711 3.3751 21.0794C3.76676 20.6877 4.2376 20.4919 4.7876 20.4919H20.7876C21.3376 20.4919 21.8084 20.6877 22.2001 21.0794C22.5918 21.4711 22.7876 21.9419 22.7876 22.4919C22.7876 23.0419 22.5918 23.5127 22.2001 23.9044C21.8084 24.2961 21.3376 24.4919 20.7876 24.4919H4.7876ZM5.7876 18.4919C5.50426 18.4919 5.26676 18.3961 5.0751 18.2044C4.88343 18.0127 4.7876 17.7752 4.7876 17.4919V15.1669C4.7876 15.0336 4.8126 14.9044 4.8626 14.7794C4.9126 14.6544 4.9876 14.5419 5.0876 14.4419L13.8376 5.69191L17.5876 9.44191L8.8376 18.1919C8.7376 18.2919 8.6251 18.3669 8.5001 18.4169C8.3751 18.4669 8.24593 18.4919 8.1126 18.4919H5.7876ZM18.7126 8.34191L14.9626 4.59191L16.7626 2.79191C16.9459 2.59191 17.1793 2.49607 17.4626 2.50441C17.7459 2.51274 17.9793 2.60857 18.1626 2.79191L20.5126 5.14191C20.6959 5.32524 20.7876 5.55441 20.7876 5.82941C20.7876 6.10441 20.6959 6.34191 20.5126 6.54191L18.7126 8.34191Z" fill="white"/>
                                    </svg>
                                </button>
                                <>
                                    <Button
                                        variant="btn btn-danger"
                                        onClick={() => handleShow()}
                                    >
                                        <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M7.90918 21.6777C7.35918 21.6777 6.88835 21.4819 6.49668 21.0902C6.10501 20.6986 5.90918 20.2277 5.90918 19.6777V6.67773C5.62585 6.67773 5.38835 6.5819 5.19668 6.39023C5.00501 6.19857 4.90918 5.96107 4.90918 5.67773C4.90918 5.3944 5.00501 5.1569 5.19668 4.96523C5.38835 4.77357 5.62585 4.67773 5.90918 4.67773H9.90918C9.90918 4.3944 10.005 4.1569 10.1967 3.96523C10.3883 3.77357 10.6258 3.67773 10.9092 3.67773H14.9092C15.1925 3.67773 15.43 3.77357 15.6217 3.96523C15.8133 4.1569 15.9092 4.3944 15.9092 4.67773H19.9092C20.1925 4.67773 20.43 4.77357 20.6217 4.96523C20.8133 5.1569 20.9092 5.3944 20.9092 5.67773C20.9092 5.96107 20.8133 6.19857 20.6217 6.39023C20.43 6.5819 20.1925 6.67773 19.9092 6.67773V19.6777C19.9092 20.2277 19.7133 20.6986 19.3217 21.0902C18.93 21.4819 18.4592 21.6777 17.9092 21.6777H7.90918ZM9.90918 17.6777H11.9092V8.67773H9.90918V17.6777ZM13.9092 17.6777H15.9092V8.67773H13.9092V17.6777Z" fill="white"/>
                                        </svg>
                                    </Button>

                                    <Modal show={show} onHide={handleClose}>
                                        <Modal.Header closeButton>
                                            <Modal.Title>{t("admin.deletePush")}</Modal.Title>
                                        </Modal.Header>
                                        <Modal.Body>
                                            {t("admin.deletePushQuestion")} «{item?.titleRu}»?
                                        </Modal.Body>
                                        <Modal.Footer>
                                            <Button variant="secondary" onClick={handleClose}>
                                                {t("cancel")}
                                            </Button>
                                            <Button
                                                variant="btn btn-danger"
                                                onClick={() => deletePush(item.id)}
                                            >
                                                {t("delete")}
                                            </Button>
                                        </Modal.Footer>
                                    </Modal>
                                </>
                            </div>
                        </div>
                    ))}
                </div>
                <div className="push-send-btns">
                    <button
                        onClick={sendPushSubscribers}
                        type="button"
                        className="btn btn-success"
                    >
                        {t("admin.pushSubscribers")}
                    </button>
                    <button
                        onClick={sendPushToAll}
                        type="button"
                        className="btn btn-primary"
                    >
                        {t("admin.pushAll")}
                    </button>
                </div>
                {/*{totalPages && (*/}
                {/*    <nav aria-label="Page navigation example">*/}
                {/*      <ul className="pagination">*/}
                {/*        <li className="page-item">*/}
                {/*          <button*/}
                {/*              className="page-link"*/}
                {/*              disabled={currentPage === 1}*/}
                {/*              aria-label="Previous"*/}
                {/*              onClick={() => getCatalogs(currentPage - 1)}*/}
                {/*          >*/}
                {/*            <span aria-hidden="true">&laquo;</span>*/}
                {/*          </button>*/}
                {/*        </li>*/}
                {/*        {categories &&*/}
                {/*            Array.from(Array(totalPages)).map((page, i) => (*/}
                {/*                <li className="page-item">*/}
                {/*                  <button*/}
                {/*                      className="page-link"*/}
                {/*                      style={*/}
                {/*                        currentPage === i + 1*/}
                {/*                            ? { backgroundColor: "blue", color: "white" }*/}
                {/*                            : {}*/}
                {/*                      }*/}
                {/*                      onClick={() => getCatalogs(i + 1)}*/}
                {/*                  >*/}
                {/*                    {i + 1}*/}
                {/*                  </button>*/}
                {/*                </li>*/}
                {/*            ))}*/}
                {/*        <li className="page-item">*/}
                {/*          <button*/}
                {/*              className="page-link"*/}
                {/*              aria-label="Next"*/}
                {/*              disabled={currentPage === totalPages}*/}
                {/*              onClick={() => getCatalogs(currentPage + 1)}*/}
                {/*          >*/}
                {/*            <span aria-hidden="true">&raquo;</span>*/}
                {/*          </button>*/}
                {/*        </li>*/}
                {/*      </ul>*/}
                {/*    </nav>*/}
                {/*)}*/}
            </div>
        </>
    );
}