import React from "react";
import Header from "../../components/Header";
import "./styles.scss";
import { useTranslation } from "react-i18next";

export default function Payments() {
  const { t } = useTranslation();
  return (
    <>
      <Header />
      <div className="container info">
        <h1>{t("footer.deliveryCondition")} </h1>
        <div>
          <ul>
            <li>{t("delivery.delivery-info")} </li>
            <li>{t("delivery.delivery-change")}</li>
            <li>{t("delivery.delivery-time")}</li>
            <li>{t("delivery.delivery-question")}</li>
            <li>{t("delivery.delivery-process")} </li>
          </ul>
          <h3>{t("delivery.delivery")}</h3>
          <ul>
            <li>{t("delivery.delivery-finally")}</li>

            <li>{t("delivery.delivery-ready")} </li>
          </ul>
        </div>
      </div>
    </>
  );
}
