import React, { useState, useEffect } from "react";
import Header from "../../components/Header";
import "./CreateCatalog.scss";
import { useNavigate, useLocation } from "react-router-dom";
import API from "../../api/apiController";
import { useTranslation } from "react-i18next";

export default function EditUser() {
  const { state } = useLocation();
  const { t } = useTranslation();
  const [id, setId] = useState(null);
  const [login, setLogin] = useState("");
  const [age, setAge] = useState(null);
  const [cityId, setCityId] = useState([]);
  const [userCityId, setUserCityId] = useState(null);
  const [blocked, setBlocked] = useState("");
  const [name, setName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");

  const navigate = useNavigate();
  const token = localStorage.getItem("token");
  useEffect(() => {
    console.log(state);
    if (state?.user) {
      let user = state.user;
      setId(user.id);
      setName(user.name);
      setAge(user.age);
      setLogin(user.login);
      setBlocked(user.blocked);
      setPhoneNumber(user.phoneNumber);
      setUserCityId(user.cityId);
    }
    API.auth.getCity().then((response) => setCityId(response.data.data));
    console.log(state?.categoryId);
  }, []);

  const update = async (e) => {
    e.preventDefault();
    const data = {
      id: id,
      login: login.toLowerCase(),
      phoneNumber: phoneNumber,
      name: name,
      countryId: 1,
      age: age,
      blocked: blocked,
      cityId: userCityId,
    };
    await API.admin
      .updateUser(data)
      .then(async (response) => {
        navigate(-1)
      })
      .catch((error) => console.log(error));
  };
  return (
    <>
      <Header />

      <div className="container catalog">
        <div className="banners-btn" styles={{ display: 'flex', justifyContent: 'flex-end' }}>
          <button
            onClick={() => {
              navigate(-1);
            }}
            type="button"
            className="btn btn-primary"
          >
            {t("returnBack")}
          </button>
        </div>
        <form>
          <div class="mb-3">
            <label class="form-label">Электронная почта</label>
            <input
              type="text"
              class="form-control"
              value={login}
              onChange={(event) => setLogin(event.target.value)}
            />
          </div>
          <div class="mb-3">
            <label class="form-label">Номер телефона</label>
            <input
              type="text"
              class="form-control"
              value={phoneNumber}
              onChange={(event) => setPhoneNumber(event.target.value)}
            />
          </div>
          <div class="mb-3">
            <label class="form-label">Имя пользователя</label>
            <input
              type="text"
              class="form-control"
              value={name}
              onChange={(event) => setName(event.target.value)}
            />
          </div>
          <div class="mb-3">
            <label class="form-label">Возраст</label>
            <input
              type="number"
              class="form-control"
              value={age}
              onChange={(event) => setAge(event.target.value)}
            />
          </div>
          <div class="mb-3">
            <label class="form-label">Город</label>
            <select
              value={userCityId}
              onChange={(e) => {
                setUserCityId(e.target.value)
              }}
              class="form-control"
              id="cars"
            >
              <option value=""></option>
              {cityId.map((city) => (<option value={city.id}>{city.nameRu}</option>))}
            </select>
          </div>
          <button onClick={update} class="btn btn-primary">
            Сохранить
          </button>
        </form>
      </div>
    </>
  );
}
