import React, { useEffect, useState } from "react";
import "./Stocks.scss";
import Header from "../../components/Header";
import EmptyBlock from "../../components/EmptyBlock";
import Loader from "../../components/Loader";
import { useNavigate, useLocation } from "react-router-dom";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useTranslation } from "react-i18next";
import {Declination} from "../../utils/helpers"

import API from "../../api/apiController";

export default function Products() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { state } = useLocation();
  const [loading, setLoading] = useState(false);
  const [products, setProducts] = useState([]);
  const [modalShow, setModalShow] = React.useState(false);
  const [notification, setNotification] = React.useState("");
  const [errMsg, setErrMsg] = React.useState("");
  const [favoriteShopsId, setFavoriteShopsId] = useState([]);
  const [currentId, setCurrentId] = useState(null);
  const [flat, setFlat] = useState("");
  const [house, setHouse] = useState("");
  const [street, setStreet] = useState(null);
  const [modal, setModal] = useState(false);
  const [photoArr, setPhotoArr] = useState([]);
  const token = localStorage.getItem("token");
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize] = useState(5);
  const [totalPages, setTotalPages] = useState(0)
  const lang = localStorage.getItem('lang')
  useEffect(() => {
    setLoading(true);
    getFavoritesProduct();
    paginate();
    fetchData();
  }, []);

  const paginate = (page) => {
    console.log("paginate", currentPage);
    setLoading(true);
    setProducts([])
    setCurrentPage(page);
    const paginationParams = {
      pageNumber: page ? page-1 : currentPage-1,
      pageSize: 10
    }

    let params = {
      categoryId: state?.id,
    };
    API.user.getProducts(params,paginationParams).then((response) => {
      setProducts(response.data.data.data.data);
      setTotalPages(response.data.data.totalPages)
      getStocksPhoto(response.data.data.data.data)
      console.log(response.data.data);
      setLoading(false);
    });
  }

  const getStocksPhoto = (products) => {
    console.log('esponse.data.data', products)
    products.map(product => {

        const params = {
          id: product.id,
        }
        let arr = []
        API.user.getStocksPhoto(params)
        .then((res) => {
          const obj = {
            id:product.id,
            photo: res.data.data
          }
          arr.push(obj)
          setPhotoArr(arr)
          product.logoPhoto = res.data.data;
        });

    })
  };

  const fetchData = async () => {
    await API.user.getAddress().then((address) => {
      console.log(address.data.data);
      setStreet(address.data.data.street);
      setFlat(address.data.data.flat);
      setHouse(address.data.data.house);
    });
  };

  const updateDate = () => {
    setModalShow(false);
  };

  const pressLikeStock = (id, event) => {
    event.preventDefault();
    event.stopPropagation();
    let params = {
      productId: id,
    };
    if (favoriteShopsId.includes(id)) {
      API.user
        .deleteFavorites(params)
        .then(async (response) => await getFavoritesProduct());
    } else {
      API.user
        .addFavorites(params)
        .then(async (response) => await getFavoritesProduct());
    }
  };

  const getFavoritesProduct = async () => {
    let favoritesId = [];
    let favorites = [];
    await API.user
      .getFavorites()
      .then(async (response) => (favorites = response.data.data));
    favorites.map((el) => favoritesId.push(el.id));
    setFavoriteShopsId(favoritesId);
  };

  const buyCertificate = async (flat, house, street) => {
    const params = {
      number: 1,
      productId: currentId,
    };

    await API.epay
      .buy(params)
      .then(async (res) => {
        paginate(currentPage);
        setNotification("success");
        setTimeout(() => {
          setNotification("");
        }, 5000);
        console.log("res", res.status);
      })
      .catch((err) => {
        console.log("error", err.response.data.errors[0].valueRu);
        setNotification("error");
        setErrMsg(err.response.data.errors[0][`value${lang}`]);
        setTimeout(() => {
          setNotification("");
        }, 5000);
        console.log("err", err);
      });
  };

  const  test = (number) => {
    let n = Math.abs(number);
    n %= 100;
    if (n >= 5 && n <= 20) {
      return t("points.ballov");
    }
    n %= 10;
    if (n === 1) {
      return t("points.ball");
    }
    if (n >= 2 && n <= 4) {
      return t("points.balla");
    }
    return t("points.ballov");
  };

  return (
    <>
      {notification === "success" && (
        <div class="myAlert-top alert alert-success">
          <strong>{t("youParticipate")}</strong>
        </div>
      )}
      {notification === "error" && (
        <div class="myAlert-top alert alert-danger">
          <strong>{errMsg}</strong>
        </div>
      )}
      <Header />

      <div className="container ">
        <div className="back">
          <button
            onClick={() => {
              navigate("/catalogs-stock", { state: { id: state?.backId } });
            }}
            type="button"
            className="btn btn-primary"
          >
            {t("returnBack")}
          </button>
        </div>
        <div class="row row-cols-1 row-cols-md-5 g-4 products">
          {loading && <Loader />}
          {products &&
            products.map((category) => (
              <div class="card">
                <div
                  class="products__item"
                  onClick={(e) => {
                    navigate("/details", {
                      state: { id: category.id, type: "stock" },
                    });
                  }}
                >
                  <img
                    className="heart"
                    onClick={(event) => {
                      pressLikeStock(category.id, event);
                    }}
                    src={
                      favoriteShopsId.includes(category.id)
                        ? require("../../assets/icons/heart-fill.png")
                        : require("../../assets/icons/heart.png")
                    }
                    alt=""
                  />
                  <img
                    src={category.logoPhoto ? `data:image/jpeg;base64,${category.logoPhoto}` : require('../../assets/notPhoto.jpg')}
                    className="card-img-top products__img"
                    alt="..."
                  />
                  <div class="card-body products__info">
                    <div>
                      <h5 class="card-title text-break">{category[`name${lang}`]}</h5>
                      <h5 class="">
                        {t("history.participate")}: {category.price} {test(category?.price)}
                      </h5>
                    </div>
                  </div>
                </div>

                <div
                  className="products__buy"
                  styles={{ justifyContent: "center" }}
                >
                  {category.userLimit !== category.userLimitRemain && token && (
                    <p className="products__participate">
                     {t("youParticipate")}
                    </p>
                  )}
                  <button
                    onClick={() => {
                      if(token){
                        setModal(true);
                        setCurrentId(category.id);
                      } else {
                        window.location.replace('/#/login')
                      }

                    }}
                    type="button"
                    disabled={
                      category.userLimit !== category.userLimitRemain && token
                    }
                    className="btn btn-primary buy"
                  >
                    {t("favorites.participate")}
                  </button>
                </div>
              </div>
            ))}
          {!loading && !products?.length && <EmptyBlock />}
        </div>
        {totalPages && <nav aria-label="Page navigation example">
          <ul class="pagination">
            <li class="page-item">
              <button class="page-link" disabled={currentPage===1} aria-label="Previous" onClick = {()=> paginate(currentPage-1)}>
                <span aria-hidden="true">&laquo;</span>
              </button>
            </li>
            {products && Array.from(Array(totalPages)).map((page,i) => (
              <li class="page-item">
              <button class="page-link" style={ currentPage === i+1 ? {backgroundColor: 'blue',color:'white'} : {}}  onClick = {()=> paginate(i+1)}>
                {i+1}
              </button>
            </li>
            ))}
            <li class="page-item">
              <button class="page-link"  aria-label="Next" disabled={currentPage===totalPages} onClick = {()=> paginate(currentPage+1)}>
                <span aria-hidden="true">&raquo;</span>
              </button>
            </li>
          </ul>
        </nav>}
      </div>
      <ModalAddress
        show={modal}
        flat={flat}
        street={street}
        house={house}
        onHide={() => setModal(false)}
        sendDataToParent={buyCertificate}
      />
    </>
  );
}

function ModalAddress(props) {
  const [flat, setFlat] = useState(props.flat);
  const [house, setHouse] = useState(props.house);
  const [street, setStreet] = useState(props.street);
  const { t } = useTranslation();

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    await API.user.getAddress().then((address) => {
      console.log(address.data.data);
      setStreet(address.data.data.street);
      setFlat(address.data.data.flat);
      setHouse(address.data.data.house);
    });
  };
  return (
    <>
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
          {t("adress.adress")}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="container">
            <div class="mb-3">
              <label class="form-label">{t("adress.adressDelivery")}</label>
              <input
                type="text"
                class="form-control"
                value={street}
                placeholder={t("adress.adressDeliveryPlaceholder")}
                onChange={(event) => {
                  setStreet(event.target.value);
                }}
              />
            </div>
            <div class="mb-3">
              <label class="form-label">{t("adress.house")}</label>
              <input
                type="text"
                class="form-control"
                value={house}
                placeholder={t("adress.housePlaceholder")}
                onChange={(event) => {
                  setHouse(event.target.value);
                }}
              />
            </div>
            <div class="mb-3">
              <label class="form-label">{t("adress.flat")}</label>
              <input
                type="text"
                class="form-control"
                value={flat}
                placeholder={t("adress.flatPlaceholder")}
                onChange={(event) => {
                  setFlat(event.target.value);
                }}
              />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={() => {
              props.sendDataToParent(flat, house, street);
            }}
            disabled={!house || !street}
          >
            {t("favorites.participate")}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
