import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import LocalShippingOutlinedIcon from "@mui/icons-material/LocalShippingOutlined";
import PaymentOutlinedIcon from "@mui/icons-material/PaymentOutlined";
import QuestionAnswerOutlinedIcon from "@mui/icons-material/QuestionAnswerOutlined";
import CardGiftcardIcon from "@mui/icons-material/CardGiftcard";
import { useNavigate, useLocation } from "react-router-dom";
import Carousel from "react-bootstrap/Carousel";
import { Modal } from "react-bootstrap";
import Header from "../../components/Header";
import API from "../../api/apiController";
import BannersPhoto from "../../utils/banners";
import Loader from "../../components/Loader";
import "./Home.scss";

export default function Home() {
  const [loading, setLoading] = useState(false);
  const [categories, setCategories] = useState(null);
  const [images, setImages] = useState([]);
  const [photoArr, setPhotoArr] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [modalShow, setModalShow] = React.useState(false);
  const [banners, setBanners] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const lang = localStorage.getItem("lang");

  const itemsInfo = [
    {
      id: 0,
      icon: LocalShippingOutlinedIcon,
      text_top: t("delivery.delivery"),
      text_bottom: t("home.allProduct"),
    },
    {
      id: 1,
      icon: PaymentOutlinedIcon,
      text_top: t("home.garant"),
      text_bottom: t("home.garantInfo"),
    },
    {
      id: 2,
      icon: QuestionAnswerOutlinedIcon,
      text_top: t("home.support"),
      text_bottom: t("home.supportInfo"),
    },
  ];

  useEffect(() => {
    window.scrollTo(0, 0);
    setCurrentPage(1);
    setLoading(true);
    const params = {
      lang: lang === "Kz" ? "KZ" : "RU",
      system: "SITE",
    };
    API.admin.getBanner(params).then((response) => {
      setImages(response.data.data);
    });
    paginate();
  }, []);

  useEffect(() => {
    const params = {
      lang: lang === "Kz" ? "KZ" : "RU",
      system: "SITE",
    };
    API.admin.getBanner(params).then((response) => {
      setImages(response.data.data);
    });
  }, [lang]);

  const paginate = (page) => {
    setLoading(true);
    setCategories([]);
    setCurrentPage(page);
    const paginationParams = {
      pageNumber: page ? page - 1 : currentPage - 1,
      pageSize: 10,
    };

    const params = {
      active: true,
    };

    API.shop.getCategory(params, paginationParams).then((res) => {
      setTotalPages(res.data.data.totalPages);
      filterCatalogsParent(res.data.data.data.data);
      setLoading(false);
    });
  };

  const filterCatalogsParent = (data) => {
    //const newCatalogList = data.filter((el) => el.hasProducts);
    // getPhotoCategories(data);
    setCategories(data);
  };

  return (
    <>
      <Header />
      {(images.firstPhoto || images.secondPhoto || images.thirdPhoto) && (
        <div className="container text-center">
          <Carousel>
            <Carousel.Item
              onClick={() => {
                setModalShow(true);
                setBanners(
                  lang == "Kz"
                    ? BannersPhoto.firstBannersKz
                    : BannersPhoto.firstBannersRu
                );
              }}
            >
              <img
                className="d-block w-100 h-50 banners"
                src={`data:image/jpeg;base64,${images.firstPhoto}`}
                alt="First slide"
              />
              <Carousel.Caption></Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item
              onClick={() => {
                setModalShow(true);
                setBanners(
                  lang == "Kz"
                    ? BannersPhoto.secondBannersKz
                    : BannersPhoto.secondBannersRu
                );
              }}
            >
              <img
                className="d-block w-100 h-50 banners"
                src={`data:image/jpeg;base64,${images.secondPhoto}`}
                alt="Second slide"
              />

              <Carousel.Caption></Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item
              onClick={() => {
                navigate("/last-winners");
              }}
            >
              <img
                className="d-block w-100 h-50 banners"
                src={`data:image/jpeg;base64,${images.thirdPhoto}`}
                alt="Third slide"
              />

              <Carousel.Caption></Carousel.Caption>
            </Carousel.Item>
          </Carousel>
          <ShowBanners
            show={modalShow}
            banners={banners}
            onHide={() => setModalShow(false)}
          />
        </div>
      )}
      <section className="container">
        <div className="container custom-conatiner">
          <div className="features-2__lists pt-25 pb-25">
            <div className="row row-cols-xxl-4 row-cols-xl-3 row-cols-lg-3 row-cols-md-2 row-cols-sm-2 row-cols-1 gx-0">
              <div
                onClick={() => navigate("/catalogs-stock")}
                className="col stock"
              >
                <div className="features-2__item">
                  <div className="features-2__icon mr-20">
                    <CardGiftcardIcon
                      sx={{ fontSize: "50px", color: "#fcbe00" }}
                    />
                  </div>
                  <div className="features-2__content">
                    <h5>{t("home.stocks")}</h5>
                    <p>{t("home.stocksInfo")}</p>
                  </div>
                </div>
              </div>
              {itemsInfo.map((list) => (
                <div className="col" key={list?.id}>
                  <div className="features-2__item">
                    <div className="features-2__icon mr-20">
                      <list.icon sx={{ fontSize: "50px", color: "#fcbe00" }} />
                    </div>
                    <div className="features-2__content">
                      <h5>{list.text_top}</h5>
                      <p>{list.text_bottom}</p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>
      <div className="container">
        <div className="row row-cols-1 row-cols-md-5 categories">
          {loading && <Loader />}
          {categories &&
            categories.map((category) => (
              <div
                  key={category?.id}
                className="card categories__item"
                onClick={() => {
                  navigate("/catalogs", { state: { id: category.id } });
                }}
              >
                <div className="card-body categories__body">
                  <h5 className="text-center card-title  text-break">
                    {category[`name${lang}`]}
                  </h5>
                  <img
                    src={`https://backend.1-q.kz/shop/category/image?id=${category.id}`}
                    className="card-img-top categories__img"
                    alt="..."
                  />
                </div>
              </div>
            ))}
        </div>
        {totalPages && (
          <nav aria-label="Page navigation example">
            <ul className="pagination">
              <li className="page-item">
                <button
                    className="page-link"
                  disabled={currentPage === 1}
                  aria-label="Previous"
                  onClick={() => paginate(currentPage - 1)}
                >
                  <span aria-hidden="true">&laquo;</span>
                </button>
              </li>
              {categories &&
                Array.from(Array(totalPages)).map((page, i) => (
                  <li className="page-item" key={i}>
                    <button
                        className="page-link"
                      style={
                        currentPage === i + 1
                          ? { backgroundColor: "blue", color: "white" }
                          : {}
                      }
                      onClick={() => paginate(i + 1)}
                    >
                      {i + 1}
                    </button>
                  </li>
                ))}
              <li className="page-item">
                <button
                    className="page-link"
                  aria-label="Next"
                  disabled={currentPage === totalPages}
                  onClick={() => paginate(currentPage + 1)}
                >
                  <span aria-hidden="true">&raquo;</span>
                </button>
              </li>
            </ul>
          </nav>
        )}
      </div>
    </>
  );
}

function ShowBanners(props) {
  return (
    <Modal {...props} className="center">
      <Carousel>
        {props.banners.map((e, index) => (
          <Carousel.Item key={index}>
            <img
              src={e}
              className="winner__photo"
              alt="..."
              style={{ width: "370px", height: "700px" }}
            />
            <Carousel.Caption></Carousel.Caption>
          </Carousel.Item>
        ))}
      </Carousel>
    </Modal>
  );
}
