import React from "react";
import Header from "../../components/Header";
import "./styles.scss";
import { useTranslation } from "react-i18next";

export default function About() {
  const { t } = useTranslation();
  return (
    <>
      <Header />
      <div className="container info">
        <h2 className="text-center">{t("about.shop")} </h2>
        <div>
          <ul>
            <li>{t("about.requisites")}</li>
            <li>{t("about.name")}</li>
            <li>{t("about.bank")} </li>
            <li>{t("about.account")}</li>
            <li>{t("about.bik")}</li>
            <li>{t("about.bin")}</li>
          </ul>
          <h3></h3>
          <ul>
            <li>{t("about.attention")}</li>
            <li>{t("about.footer")}</li>
          </ul>
        </div>
      </div>
    </>
  );
}
