import { useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import { Modal, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import API from "../../api/apiController";
import Header from "../../components/Header";
import Loader from "../../components/Loader";
import Pagination from "../../components/Pagination"
import "./AdminCatalogs.scss";

export default function AdminUsers() {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [cityId, setCityId] = useState([]);
  const [users, setUsers] = useState([]);
  const [userCity, setUserCity] = useState(null);
  const [login, setLogin] = useState(null);
  const [num, setNum] = useState(null);
  const [filterModal, setFilterModal] = useState(false);
  const [currentUser, setCurrentUser] = useState(null);

  const [currentPage, setCurrentPage] = useState(1);
  const [usersPerPage] = useState(10);

  const navigate = useNavigate();
  useEffect( () => {
     getAllUser().then();
    API.auth.getCity().then((response) => setCityId(response.data.data));
  }, []);

  const indexOfLastUser = currentPage * usersPerPage;
  const indexOfFirstUser = indexOfLastUser - usersPerPage;
  const currentUsers = users.slice(indexOfFirstUser, indexOfLastUser);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const getAllUser = async () => {
    setLoading(true);
    const data = {
      direction: "ASC",
      login: login,
      phoneNumber: null,
      pageNumber: 0,
      pageSize: 10000,
      sortBy: "id",
      countryId: 1,
      age: num,
      cityId: userCity,
    };
    await API.admin.getUsers(data).then((response) => {
      setUsers(response.data.data.data.data);
      setLoading(false);
    });
  };
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = (item) => {
    setCurrentUser(item);
    setShow(true);
  };

  const blockunblockUser = async (id) => {
    const params = {
      id: id,
    };
    await API.admin
      .blockUnblock(params)
      .then(async (response) => {
        getAllUser();
        setShow(false);
      })
      .catch((error) => console.log(error));
  };

  const updateDate = async (login, number, cityId) => {
    console.log(login, number, cityId);
    setLoading(true);
    const data = {
      direction: "ASC",
      login: login,
      phoneNumber: number ? number : null,
      pageNumber: 0,
      pageSize: 10000,
      sortBy: "id",
      countryId: 1,
      age: null,
      cityId: cityId ? Number(cityId) : null,
    };
    await API.admin.getUsers(data).then((response) => {
      setUsers(response.data.data.data.data);
      setLoading(false);
    });
    setFilterModal(false);
  };

  return (
    <>
      <Header />
      <div className="container">
        <h1>{t("admin.controlUsers")}</h1>
        <div className="category__btn">
          <button
            onClick={() => {
              setFilterModal(true);
            }}
            type="button"
            className="btn btn-success"
          >
            Фильтрация
          </button>
          <FilterModal
            show={filterModal}
            onHide={() => {
              updateDate().then();
            }}
            cities={cityId}
            sendDataToParent={updateDate}
          />
          <button
            onClick={() => {
              navigate("/admin");
            }}
            type="button"
            className="btn btn-primary"
          >
            {t("returnBack")}
          </button>
        </div>
        {loading && <Loader />}
        {currentUsers.map((user) => (
          <div className="card user" key={user?.id}>
            <div className="card-body user__item">
              <div className="user__info">
                <div>
                  <h4>{user.login}</h4>
                  {user.blocked && (
                    <h5 className="user__block">{t("admin.blockUser")}</h5>
                  )}
                </div>
              </div>
              <div className="user__btn">
                <>
                  <Button
                    variant="btn btn-danger"
                    onClick={() => handleShow(user)}
                  >
                    {user.blocked ? t("admin.unblock") : t("admin.block")}
                  </Button>

                  <Modal show={show} onHide={handleClose}>
                    <Modal.Header closeButton>
                      <Modal.Title>{t("admin.controlUsers")}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      {currentUser?.blocked
                        ? t("admin.unblockUserQuestion")
                        : t("admin.blockUserQuestion")}{" "}
                      {currentUser?.login}?
                    </Modal.Body>
                    <Modal.Footer>
                      <Button variant="secondary" onClick={handleClose}>
                        {t("cancel")}
                      </Button>
                      <Button
                        variant="btn btn-danger"
                        onClick={() => blockunblockUser(currentUser.id)}
                      >
                        {currentUser?.blocked
                          ? t("admin.unblock")
                          : t("admin.block")}
                      </Button>
                    </Modal.Footer>
                  </Modal>
                </>
                <button
                  onClick={() => {
                    navigate("/admin/edit-user", { state: { user: user } });
                  }}
                  type="button"
                  className="btn btn-primary"
                >
                  {t("edit")}
                </button>
              </div>
            </div>
          </div>
        ))}
      <Pagination
        usersPerPage={usersPerPage}
        totalUsers={users.length}
        paginate={paginate}
      />
      </div>
    </>
  );
}

function FilterModal(props) {
  const [login, setLogin] = useState(null);
  const [number, setNumber] = useState(null);
  const [userCityId, setUserCityId] = useState(null);
  const loginRef = useRef(null);
  const numberRef = useRef(null);
  const userCityIdRef = useRef(null);

  const apply = () => {
    props.sendDataToParent(login, number, userCityId);
  };

  const clearFilter = (event) => {
    loginRef.current.value = null;
    numberRef.current.value = null;
    userCityIdRef.current.value = null;
    setLogin(null);
    setNumber(null);
    setUserCityId(null);
  };
  return (
    <>
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Фильтрация
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="mb-3">
            <label className="form-label">Логин</label>
            <input
              type="text"
              className="form-control"
              value={login}
              ref={loginRef}
              onChange={(event) => setLogin(event.target.value)}
            />
          </div>
          <div className="mb-3">
            <label className="form-label">Телефон</label>
            <input
              type="text"
              className="form-control"
              ref={numberRef}
              value={number}
              onChange={(event) => setNumber(event.target.value)}
            />
          </div>
          <div className="mb-3">
            <label className="form-label">Город</label>
            <select
              value={userCityId}
              ref={userCityIdRef}
              onChange={(e) => {
                setUserCityId(e.target.value);
              }}
              className="form-control"
              id="cars"
            >
              <option value=""></option>
              {props.cities.map((city) => (
                <option value={city.id} key={city.id}>{city.nameRu}</option>
              ))}
            </select>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={(e) => clearFilter(e)}>Очистить</Button>
          <Button
            onClick={() => {
              apply();
            }}
          >
            Применить
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
