import React, { useEffect, useState } from "react";
import Header from "../../components/Header";
import "./Favorite.scss";
import { useNavigate } from "react-router-dom";
import Loader from "../../components/Loader";
import API from "../../api/apiController";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useTranslation } from "react-i18next";
import { Declination } from "../../utils/helpers";

export default function History() {
  const [loading, setLoading] = useState(false);
  const [type, setType] = useState("shop");
  const [favorites, setFavorites] = useState([]);
  const [favoriteProductsId, setFavoriteProductsId] = useState([]);
  const [modalBuyCert, setModalBuyCert] = React.useState(false);
  const [countId, setCountId] = useState(null);
  const [count, setCount] = useState(1);
  const [modalShow, setModalShow] = React.useState(false);
  const [notification, setNotification] = React.useState("");
  const [currentId, setCurrentId] = useState(null);
  const [flat, setFlat] = useState("");
  const [house, setHouse] = useState("");
  const [street, setStreet] = useState(null);
  const [modal, setModal] = useState(false);
  const [photoArr, setPhotoArr] = useState([]);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const lang = localStorage.getItem('lang')

  const deleteFavorites = (id, event) => {
    event.preventDefault();
    event.stopPropagation();
    let params = {
      productId: id,
    };
    console.log(params);
    type === "shop"
      ? API.shop
          .deleteFavorites(params)
          .then(async (response) => await getFavoritesProduct())
      : API.user
          .deleteFavorites(params)
          .then(async (response) => await getFavoritesProduct());
  };

  useEffect(() => {
    fetchData();
    type === "shop" ? getFavoritesProduct() : getFavoritesStock();
  }, [type]);

  const  test = (number) => {
    let n = Math.abs(number);
    n %= 100;
    if (n >= 5 && n <= 20) {
      return t("points.ballov");
    }
    n %= 10;
    if (n === 1) {
      return t("points.ball");
    }
    if (n >= 2 && n <= 4) {
      return t("points.balla");
    }
    return t("points.ballov");
  };

  const fetchData = async () => {
    await API.user.getAddress().then((address) => {
      console.log(address.data.data);
      setStreet(address.data.data.street);
      setFlat(address.data.data.flat);
      setHouse(address.data.data.house);
    });
  };

  const getFavoritesProduct = async () => {
    setLoading(true);
    let favoritesId = [];
    let favorites = [];
    await API.shop.getFavorites().then(async (response) => {
      setFavorites(response.data.data);
      getProductsPhoto(response.data.data)
      favorites = response.data.data;
    });
    favorites.map((el) => favoritesId.push(el.id));
    setFavoriteProductsId(favoritesId);
    setLoading(false);
  };

  const getProductsPhoto = (products) => {
    products.map(product => {
      const params = {
        id: product.id,
      }
      let arr = []
      API.shop.getProductsPhoto(params)
      .then((res) => {
        const obj = {
          id:product.id,
          photo: res.data.data
        }
        arr.push(obj)
        setPhotoArr(arr)
        product.logoPhoto = res.data.data;
      });
    })
  };

  const getStocksPhoto = (products) => {
    console.log('esponse.data.data', products)
    products.map(product => {

        const params = {
          id: product.id,
        }
        let arr = []
        API.user.getStocksPhoto(params)
        .then((res) => {
          const obj = {
            id:product.id,
            photo: res.data.data
          }
          arr.push(obj)
          setPhotoArr(arr)
          product.logoPhoto = res.data.data;
        });

    })
  };

  const getFavoritesStock = async () => {
    setLoading(true);
    let favoritesId = [];
    let favorites = [];
    await API.user.getFavorites().then(async (response) => {
      setFavorites(response.data.data);
      getStocksPhoto(response.data.data)
      favorites = [];
    });
    favorites.map((el) => favoritesId.push(el.id));
    setFavoriteProductsId(favoritesId);
    setLoading(false);
  };

  const changeType = (type) => {
    setFavorites([]);
    setType(type);
    type === "shop" ? getFavoritesProduct() : getFavoritesStock();
  };
  const addCertificate = async (item) => {
    if (item.id !== countId) {
      setCount(1);
      setCountId(item.id);
    } else {
      item.remain > count ? setCount(count + 1) : setCount(count);
    }
  };

  const addShopCart = async (id, enable, count, event) => {
    event.preventDefault();
    event.stopPropagation();
    const params = {
      count: count,
      productId: id,
    };
    console.log(params);
    !enable
      ? await API.shopCart.add(params).then(async (res) => {
          getFavoritesProduct();
          setCount(1);
          setNotification("addCart");
          setTimeout(() => {
            setNotification("");
          }, 5000);
        })
      : await API.shopCart.deleteCart(params).then((res) => {
          getFavoritesProduct();
          setCount(1);
          setNotification("deleteCart");
          setTimeout(() => {
            setNotification("");
          }, 5000);
        });
    console.log(id);
  };

  const minusCertificate = async (item) => {
    if (item.id !== countId) {
      setCount(1);
      setCountId(item.id);
    } else {
      setCount(count - 1);
      if (count <= 1) {
        setCount(1);
      }
    }
  };

  const buyCertificate = async (flat, house, street)  => {
    const params = {
      number: 1,
      productId: currentId,
    };
    const data = {
      flat: flat,
      house: house,
      street: street,
    };
    await API.user.sendAddress(data).then((res) => {
      console.log(res.data);
      setModal(false);
      fetchData();
    });

    await API.epay
      .buy(params)
      .then(async (res) => {
        setCurrentId(null)
        getFavoritesStock();
        setNotification("success");
        setTimeout(() => {
          setNotification("");
        }, 5000);
        console.log("res", res.status);
      })
      .catch((err) => {
        setNotification("error");
        setTimeout(() => {
          setNotification("");
        }, 5000);
        console.log("err", err);
      });
  };

  const formatPrice = (price) => {
    let formattedPrice = new Intl.NumberFormat().format(price);
    return formattedPrice.replaceAll(",", " ");
  };

  return (
    <>
      {notification === "success" && (
        <div class="myAlert-top alert alert-success">
          <strong> {t("youParticipate")}</strong>
        </div>
      )}
      {notification === "error" && (
        <div class="myAlert-top alert alert-danger">
          <strong> {t("error")}</strong>
        </div>
      )}
      {notification === "addCart" && (
        <div class="myAlert-top alert alert-success">
          <strong>{t("notifications.addedCart")}</strong>
        </div>
      )}
      {notification === "deleteCart" && (
        <div class="myAlert-top alert alert-danger">
          <strong>{t("notifications.deltedCart")}</strong>
        </div>
      )}
      <Header />
      <div className="container history">
        <h1>{t("favorites.favorites")}</h1>
        <div className="history__btn">
          <button
            onClick={() => {
              changeType("shop");
            }}
            type="button"
            className={
              type === "shop" ? "btn btn-primary" : "btn btn-outline-primary"
            }
          >
            {t("favorites.favoritesProduct")}
          </button>
          <button
            onClick={() => {
              changeType("stock");
            }}
            type="button"
            className={
              type === "stock" ? "btn btn-primary" : "btn btn-outline-primary"
            }
          >
            {t("favorites.favoritesStocks")}
          </button>
        </div>
        <div class="row row-cols-1 row-cols-md-5 g-4 products">
          {loading && <Loader />}
          {favorites &&
            favorites.map((category) => (
              <div class="card">
                <div
                  class="products__item"
                  onClick={(e) => {
                    navigate("/details", {
                      state: {
                        id: category.id,
                        type: type === "stock" ? type : null,
                      },
                    });
                  }}
                >
                  <img
                    className="heart"
                    onClick={(event) => {
                      deleteFavorites(category.id, event);
                    }}
                    src={require("../../assets/icons/heart-fill.png")}
                    alt=""
                  />
                  <img
                    src={category.logoPhoto ? `data:image/jpeg;base64,${category.logoPhoto}` : require('../../assets/notPhoto.jpg')}
                    className="card-img-top products__img"
                    alt="..."
                  />
                  <div class="card-body products__info">
                    <div>
                      <h5 class="card-title  text-break">{category[`name${lang}`]}</h5>
                      <h5 class="">
                        {type === "shop" ? t("history.cost") : t("history.participate")} :{" "}
                        {formatPrice(category.price)}
                        {type === "shop" ? t("tenge") : test(category.price)}{" "}
                      </h5>
                    </div>
                  </div>
                </div>
                {type === "shop" ? (
                  <div className="products__buy">
                    <div class="input-group  list-group">
                      <button
                        class="btn btn-outline-secondary"
                        type="button"
                        id="button-addon1"
                        onClick={() => minusCertificate(category)}
                      >
                        -
                      </button>
                      <input
                        type="text"
                        class="form-control"
                        value={countId === category.id ? count : 1}
                        onChange={(e) => setCount(e.target.value)}
                        placeholder=""
                        aria-label="Example text with button addon"
                        aria-describedby="button-addon1"
                      />
                      <button
                        class="btn btn-outline-secondary"
                        type="button"
                        id="button-addon1"
                        onClick={() => addCertificate(category)}
                      >
                        +
                      </button>
                    </div>
                    <button
                      onClick={(event) =>
                        addShopCart(category.id, category.inCart, count, event)
                      }
                      disabled={!category.remain}
                      className={
                        category.inCart
                          ? "btn btn-success buy"
                          : "btn btn-primary buy"
                      }
                      type="button"
                    >
                      {category.inCart ? t("cart.inCart"): t("cart.addCart")}
                    </button>
                  </div>
                ) : (
                  <div
                    className="products__buy"
                    styles={{ justifyContent: "center" }}
                  >
                    <button
                      onClick={() => {
                        setModal(true);
                        setCurrentId(category.id);
                      }}
                      type="button"
                      disabled={category.userLimit !== category.userLimitRemain}
                      className="btn btn-primary buy"
                    >
                      {t("favorites.participate")}
                    </button>
                  </div>
                )}
              </div>
            ))}
          {!loading && !favorites?.length && (
            <div className="empty">
              {" "}
              <h1>{t("emptyPage")}</h1>
            </div>
          )}
        </div>
      </div>
      <ModalAddress
        show={modal}
        flat={flat}
        street={street}
        house={house}
        onHide={() => setModal(false)}
        sendDataToParent={buyCertificate}
      />
    </>
  );
}


function ModalAddress(props) {
  const [flat, setFlat] = useState(props.flat);
  const [house, setHouse] = useState(props.house);
  const [street, setStreet] = useState(props.street);
  const { t } = useTranslation();

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    await API.user.getAddress().then((address) => {
      console.log(address.data.data);
      setStreet(address.data.data.street);
      setFlat(address.data.data.flat);
      setHouse(address.data.data.house);
    });
  };
  return (
    <>
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
      
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="container">
            <div class="mb-3">
              <label class="form-label">{t("adress.adressDelivery")}</label>
              <input
                type="text"
                class="form-control"
                value={street}
                placeholder={t("adress.adressDeliveryPlaceholder")}
                onChange={(event) => {
                  setStreet(event.target.value);
                }}
              />
            </div>
            <div class="mb-3">
              <label class="form-label">{t("adress.house")}</label>
              <input
                type="text"
                class="form-control"
                value={house}
                placeholder={t("adress.housePlaceholder")}
                onChange={(event) => {
                  setHouse(event.target.value);
                }}
              />
            </div>
            <div class="mb-3">
              <label class="form-label">{t("adress.flat")}</label>
              <input
                type="text"
                class="form-control"
                value={flat}
                placeholder={t("adress.flatPlaceholder")}
                onChange={(event) => {
                  setFlat(event.target.value);
                }}
              />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={() => {
              props.sendDataToParent(flat, house, street);
            }}
            disabled={!house || !street}
          >
            {t("favorites.participate")}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
