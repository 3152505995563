import {useLocation, Navigate} from 'react-router-dom';

const RequireAuth = ({children}) => {
    const location = useLocation();
    const user = localStorage.getItem('token');
    const auth = localStorage.getItem('auth');

    if (!user || auth === 'false') {
        return <Navigate to='/login' state={{from: location}}/>
    }

    return children;
}

export {RequireAuth};