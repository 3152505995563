import React from "react";
import Header from "../../components/Header";
import "./styles.scss";
export default function Politics() {
    return (
        <>
            <Header />
            <div className="container info">
                <h1>ПОЛИТИКА КОНФИДЕНЦИАЛЬНОСТИ </h1>
                <div style ={{  width: '100%', display: 'inline-block', whiteSpace: 'wrap'}}>
                

                    Настоящая политика конфиденциальности и обработки персональных данных (далее «Политика конфиденциальности») регулирует порядок обработки и использования персональных и иных данных Пользователей Товариществом с ограниченной ответственностью «QTA Company» (далее «Правообладатель»). Действующая редакция настоящей Политики конфиденциальности, постоянно доступна для ознакомления на сайте по адресу: www.1-q.kz.
                    Передавая Правообладателю персональные и иные данные, Пользователь подтверждает свое согласие на использование указанных данных на условиях, изложенных в настоящей Политике конфиденциальности.

                    <br/><br/>ТЕРМИНЫ<br/> <br/>
                    Договор(ы) – любой договор или соглашение с Правообладателем, предлагаемое к заключению и/или заключенное Пользователем на основании любой оферты, размещенной на Сервисе.
                    Персональные данные - персональные данные Пользователя, которые Пользователь предоставляет самостоятельно при регистрации, осуществлении заказа или в процессе использования Приложения, а также данные, которые передаются в автоматическом режиме в зависимости от настроек программного обеспечения Пользователя в обезличенном виде.
                    Пользователь - физическое лицо, подтверждающее свою дееспособность в соответствии с законодательством Республики Казахстан, использующее Приложение и/или Сервис в целях заключения и/или исполнения Договоров и дающее согласие на сбор и обработку своих персональных данных.
                    Правообладатель - Товарищество с ограниченной ответственностью «QTA Company», БИН 210440040244, юридический адрес: 010000, Республика Казахстан, г. Нур-Султан, Есильский район, ул. Сарайшык д.5Г, кв65.
                    Приложение – интегрированный программный комплекс, включающий Сайт, Мобильное приложение и иные программы для ЭВМ и/или базы данных, на основе которых реализуется Сервис.
                    Мобильное приложение «1Q» - программное обеспечение, устанавливаемое (загружаемое) на мобильное устройство (смартфон, планшет и т.п.) на базе платформ IOS и Android, представляющее собой совокупность данных и команд, предназначенных для функционирования на мобильном устройстве или гаджете. Правообладателем Мобильного приложения «1Q.kz» является Товарищество с ограниченной ответственностью «QTA Company».
                    Регистрация - заполнение Пользователем анкеты, расположенной на Сервисе, путем указания необходимых сведений и отправки сканированных документов (если необходимо).
                    Сайт – автоматизированная информационная система, расположенная в сети Интернет по адресу https://www.1-q.kz. Все исключительные права на Сайт и его отдельные элементы (включая программное обеспечение, дизайн) принадлежат Товариществу с ограниченной ответственностью «QTA Company» в полном объеме.
                    Сервис – совокупность программных и аппаратных средств Правообладателя (вычислительные мощности) и контента, к которым Пользователю предоставляется доступ.

                    <br/><br/>ОБЩИЕ ПОЛОЖЕНИЯ<br/><br/>
                    Настоящая Политика конфиденциальности является неотъемлемой частью ссылающихся на нее документов, в том числе размещенных на Сервисе Публичной оферты, Условий использования Интернет-площадки 1-q.kz и/или заключенных на их основе Договоров.
                    Оставляя запрос на предоставление информации, оформляя Заказ и/или заключая Договор вы свободно, своей волей и в своих интересах даете письменное согласие на следующие способы обработки Персональных данных: запись, систематизация, хранение, уточнение (обновление, изменение), извлечение, использование, передача (распространение, предоставление, доступ) третьим лицам, обезличивание, блокирование, удаление, уничтожение в установленных настоящей Политикой конфиденциальности целях с использованием средств автоматизации или без использования таких средств по усмотрению Правообладателя.
                    К настоящей Политике конфиденциальности, включая толкование ее положений и порядок принятия, исполнения, изменения и прекращения, подлежит применению законодательство Республики Казахстан.

                    <br/><br/>ПЕРСОНАЛЬНЫЕ ДАННЫЕ<br/><br/>
                    Под персональными данными в настоящей Политике конфиденциальности понимается:
                    Информация, которую Пользователь предоставляет о себе самостоятельно при размещении заказа, регистрации или авторизации, а также в процессе дальнейшего использования Сервиса, включая персональные данные Пользователя и статистические данные о Заказах.
                    Данные, которые передаются в автоматическом режиме в зависимости от настроек программного обеспечения Пользователя в обезличенном виде.
                    Правообладатель вправе устанавливать требования к составу Персональных данных Пользователя, которая должна обязательно предоставляться для использования Приложения и/или оформления договоров путем указания такой информации в Приложении при регистрации или оформлении заказа. Если определенная информация не помечена Правообладателем как обязательная, ее предоставление или раскрытие осуществляется Пользователем на свое усмотрение.
                    При регистрации Пользователем обязательно указываются имя, адрес электронной почты и пароль.
                    При оформлении Заказа Пользователем в целях заключения и/или исполнения Договора обязательно предоставляются следующие персональные данные о себе: имя, адрес доставки Товаров, имя и номер телефона получателя товаров.
                    Правообладатель ведет статистику взаимодействия с Пользователем, в которую включает следующие данные: общая сумма заказов Пользователя, количество заказов, средний чек, потребительские предпочтения.
                    При оформлении подписки на информационную рассылку Пользователем обязательно указываются имя и адрес электронной почты.
                    При направлении Пользователем обращения Правообладателю обязательно указываются имя и адрес электронной почты или абонентский номер телефона.
                    Дополнительно в целях заполнения профиля Пользователь может предоставить: фамилию, дату рождения, пол.
                    Правообладатель вправе осуществлять записи телефонных разговоров с Пользователем в целях повышения качества обслуживания.
                    Общедоступная информация. Пользователь дает информированное согласие на доступ к данным профиля неограниченного круга лиц. Имя становится общедоступными при опубликовании Пользователем сообщения (например, отзыва) на Сервисе.
                    Правообладатель не осуществляет проверку достоверности предоставляемых Персональных данных и наличия у Пользователя необходимого согласия на их обработку в соответствии с настоящей Политикой конфиденциальности, полагая, что Пользователь действует добросовестно, осмотрительно и прилагает все необходимые усилия к поддержанию такой информации в актуальном состоянии и получению всех необходимых согласий субъектов персональных данных.
                    Пользователь осознает и принимает возможность использования в Приложении Правообладателя программного обеспечения третьих лиц, в результате чего такие лица могут   получать и передавать указанные в п. 3.1.2 данные в обезличенном виде.
                    К указанному программному обеспечению третьих лиц относятся системы сбора статистики посещений Google Analytics и Яндекс.Метрика.
                    Состав и условия сбора обезличенных данных с использованием программного обеспечения третьих лиц определяются непосредственно их правообладателями и могут включать:
                    данные браузера (тип, версия, cookie);
                    данные устройства и место его положения;
                    данные операционной системы (тип, версия, разрешение экрана);
                    данные запроса (время, источник перехода, IP-адрес).
                    Обезличенные данные не объединяются с информацией о Пользователе, указанной в п. 3.1.1 и не используются для идентификации Пользователей.
                    Правообладатель не несет ответственность за порядок использования Персональных данных Пользователя третьими лицами, с которыми Пользователь взаимодействует в рамках использования Сервиса.
                    Пользователь соглашается с тем, что Правообладатель вправе хранить Персональные данные Пользователя, а также персональные данные указанного Пользователем получателя заказа в течение срока использования Пользователем Приложения и не менее трех лет с даты последнего использования Пользователем Приложения.
                    Правообладатель имеет право хранить Персональные данные на серверах вне территории Республики Казахстан.

                    <br/><br/>ЦЕЛИ ОБРАБОТКИ ПЕРСОНАЛЬНОЙ ИНФОРМАЦИИ<br/><br/>
                    Правообладатель осуществляет обработку, в том числе сбор и хранение только той Персональной информации, которая необходима для заключения и исполнения Договоров с Пользователем.
                    Правообладатель вправе использовать Персональную информацию в следующих целях:
                    Оформление Заказов и заключение Договоров с использованием Сервиса.
                    Оформление Заказов и заключение Договоров может осуществляться в дистанционном порядке посредством обмена электронными документами, которые подписываются простой электронной подписью. При этом роль ключа простой электронной подписи Пользователя могут выполнять пара логин – пароль или адрес его электронной почты. Поэтому они запрашиваются при регистрации.
                    Заказы также принимаются по телефону, в том числе, по звонку сотрудника Правообладателя на основании заявки Пользователя, в которой указан номер телефона.
                    Идентификация Пользователя в рамках исполнения обязательств по заключенным с ним Договорам.
                    Личный кабинет Пользователя связан с логином-паролем и адресом электронной почты Пользователя.
                    При доставке Товаров, предоставлении информационной и технической поддержки Пользователь идентифицируется по имени и номеру телефона.
                    Исполнение обязательств по заключенным Договорам, включая предоставление Пользователю доступа к Сервису и технической поддержки, использование Пользователем функциональных возможностей Сервиса, обеспечение доставки Товаров.
                    Заказы на Товар и его доставку, а также обращения в службу поддержки принимаются с использованием программных средств Приложения, электронной почты и номера телефона.
                    Для доставки Товаров используется адрес Пользователя.
                    Статистические данные о заказах Пользователя используются для предоставления такой информации Пользователю в рамках функциональных возможностей Сервиса, а также для применения программы лояльности.
                    Обеспечение безопасности и конфиденциальности Персональной информации Пользователя. В целях проверки подозрительных действий в Приложении собирается информация об используемом Пользователем IP-адресе и браузере.
                    Выставление счетов и возврат остатка денежных средств в случае расторжения заключенных с Пользователем возмездных Договоров.
                    В целях возврата оплаченных по Договору средств Пользователь может быть обязан предоставить в собственноручном заявлении дополнительную информацию в соответствии с требованиями законодательства.
                    Нотификация в рамках информационного обслуживания и/или улучшения качества Сервиса по заключенным Договорам, в том числе с привлечением третьих лиц.
                    Для этого при регистрации запрашивается адрес электронной почты и/или абонентский номер телефона Пользователя.
                    Дата рождения используется для поздравления Пользователя.
                    Проведение маркетинговых, статистических и иных исследований на основе обезличенных данных в целях улучшения качества предоставляемого Сервиса.
                    Данные из Google и Яндекс в обезличенном виде собираются и анализируются, чтобы узнать, об интересах и предпочтениях Пользователей. Использование обезличенных данных для таргетинга рекламных и/или информационных материалов по возрасту, полу, другим признакам.
<br/>
                    <br/>ТРЕБОВАНИЯ К ЗАЩИТЕ ПЕРСОНАЛЬНЫХ ДАННЫХ<br/><br/>
                    Правообладатель осуществляет хранение Персональных данных и обеспечивает их охрану от несанкционированного доступа и распространения в соответствии с внутренними правилами и регламентами.
                    В отношении Персональных данных Пользователя сохраняется их конфиденциальность, за исключением случаев, когда технология предоставляемого Сервиса или настройки используемого Пользователем программного обеспечения предусматривают открытый обмен информацией с иными участниками и пользователями сети Интернет.
                    В целях повышения качества Сервиса, Правообладатель вправе хранить лог-файлы о действиях, совершенных Пользователем в рамках использования Сервиса, а также в связи с заключением и исполнением Пользователем Соглашения и иных Договоров со своей стороны, в течение 5 (пяти) лет.

                    <br/><br/>ПЕРЕДАЧА ИНФОРМАЦИИ<br/><br/>
                    6.1. Правообладатель вправе передать Персональную информацию третьим лицам в следующих случаях:
                    Пользователь выразил свое согласие на такие действия, включая случаи применения Пользователем настроек используемого программного обеспечения, не ограничивающих предоставление определенной информации;
                    Передача необходима в рамках использования Пользователем функциональных возможностей Сервиса;
                    Передача требуется для заключения и исполнения Договоров, заключенных с использованием Сервиса; например, для доставки Товаров привлекаемыми Правообладателем третьими лицами - службами доставки;
                    В связи с передачей Приложения Правообладателя во владение, пользование или собственность такого третьего лица, или уступкой прав по заключенным с Пользователем договорам в пользу третьего лица;
                    По запросу суда или иного уполномоченного государственного органа в рамках установленной законодательством процедуры;
                    Для защиты прав и законных интересов Правообладателя в связи с нарушением заключенных с Пользователем Договоров.

                    <br/><br/>ИЗМЕНЕНИЕ ПЕРСОНАЛЬНЫХ ДАННЫХ<br/><br/>
                    Пользователь вправе в любой момент самостоятельно отредактировать в своем личном кабинете предоставленные им при регистрации или авторизации Персональные данные, за исключением адреса электронной почты и номера телефона, изменение которых производится путем обращения к Правообладателю.
                    В случае прекращения заключенного Договора, Пользователь вправе удалить собственный Личный кабинет обратившись в службу поддержки Правообладателя по адресу электронной почты 1qinfo@gmail.com

                    <br/> <br/>ИЗМЕНЕНИЕ ПОЛИТИКИ КОНФИДЕНЦИАЛЬНОСТИ<br/><br/>
                    Настоящая Политика конфиденциальности может быть изменена или прекращена Правообладателем в одностороннем порядке без предварительного уведомления Пользователя. Новая редакция Политики конфиденциальности вступает в силу с момента ее размещения на Сайте Правообладателя, если иное не предусмотрено новой редакцией Политики конфиденциальности.
                    Действующая	редакция	Политики	конфиденциальности	находится	на	Сайте Правообладателя в сети Интернет по адресу https://1-q.kz
                    Реквизиты Правообладателя:
                    ТОО «QTA Company»
                    БИН: 210440040244
                    010000, Республика Казахстан, г. Нур-Султан, , ул. Сарайшык 5Г, кв65
                    Тел. +7 (777) 431 41 41
                    Служба поддержки: +7 (777) 431 41 41

                    <br/><br/>Действующая редакция Политики конфиденциальности от «01» декабря 2022 г.</div>

            </div>
        </>
    );
}
