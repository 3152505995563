

const firstBannerKz2 = require("../assets/banners/firstBannerKz2.png");
const firstBannerKz1 = require("../assets/banners/firstBannerKz1.png");

const firstBannerRu1 = require("../assets/banners/firstBannerRu1.jpg");
const firstBannerRu2 = require("../assets/banners/firstBannerRu2.jpg");

const secondBannerKz1 = require("../assets/banners/secondBannerKz1.jpg");
const secondBannerKz2 = require("../assets/banners/secondBannerKz2.jpg");
const secondBannerKz3 = require("../assets/banners/secondBannerKz3.jpg");

const secondBannerRu1 = require("../assets/banners/secondBannerRu1.jpg");
const secondBannerRu2 = require("../assets/banners/secondBannerRu2.jpg");
const secondBannerRu3 = require("../assets/banners/secondBannerRu3.jpg");

const firstBannersKz = [
    firstBannerKz1,firstBannerKz2
]

const firstBannersRu = [
    firstBannerRu1,firstBannerRu2
]

const secondBannersKz = [
    secondBannerKz1,secondBannerKz2,secondBannerKz3
]
const secondBannersRu = [
    secondBannerRu1,secondBannerRu2,secondBannerRu3
]

export default {
    firstBannersKz, firstBannersRu, secondBannersKz, secondBannersRu
}