import {useEffect} from "react";
import {Routes, Route, HashRouter} from "react-router-dom";
import './utils/i18next'
import {RequireAuth} from "./hoc/RequireAuth";
import Home from "./pages/Home";
import DetailsProduct from "./pages/DetailsProduct";
import AdminCatalogs from "./pages/AdminCatalogs";
import CreateCatalog from "./pages/CreateCatalog";
import CreateProduct from "./pages/CreateProduct";
import AdminProduct from "./pages/AdminProduct";
import AdminStocks from "./pages/AdminStocks";
import AdminUsers from "./pages/AdminUsers";
import AdminNotifications from "./pages/AdminNotifications";
import CreateNotification from "./pages/CreateNotification";
import History from "./pages/History";
import Cart from "./pages/Cart";
import Banners from "./pages/Banners";
import Personal from "./pages/Personal";
import EditUser from "./pages/EditUser";
import AdminCatalogsStocks from "./pages/AdminCatalogsStocks";
import CreateCatalogStocks from "./pages/CreateCatalogStocks";
import CreateStocks from "./pages/CreateStocks";
import Catalogs from "./pages/Catalogs";
import CatalogsStock from "./pages/CatalogsStock";
import Products from "./pages/Products";
import Stocks from "./pages/Stocks";
import LastWinners from "./pages/LastWinners";
import PersonalData from "./pages/PersonalData";

import Payments from "./pages/InfoPages/Payments";
import About from "./pages/InfoPages/About";
import Contacts from "./pages/InfoPages/Contacts";
import Delivery from "./pages/InfoPages/Delivery";
import ReturnMoney from "./pages/InfoPages/ReturnMoney";
import ReturnProduct from "./pages/InfoPages/ReturnProduct";
import Rules from "./pages/InfoPages/Rules";
import Vacancies from "./pages/InfoPages/Vacancies";
import Politics from "./pages/InfoPages/Politics";

import PaySuccess from "./pages/PayInfo/PaySuccess";
import PaySubscribeSuccess from "./pages/PayInfo/PaySubscribeSuccess";
import PayShopSuccess from "./pages/PayInfo/PayShopSuccess";
import PayError from "./pages/PayInfo/PayError";
import "./scsss/main.scss";
// import Header from "./components/Header";
import Footer from "./components/Footer";
import Login from "./pages/Login";
import Favorite from "./pages/Favorite";
import Admin from "./pages/Admin";

// import AboutUs from "./pages/AboutUs";

function App() {
    useEffect(() => {
        if (!localStorage.getItem("lang")) {
            localStorage.setItem("lang", "Ru")
        }
    }, []);
    return (
        <HashRouter>
            <Routes>
                <Route path="/" element={<Home/>}/>
                <Route path="/details" element={<DetailsProduct/>}/>
                <Route path="/login" element={<Login/>}/>

                <Route path="/catalogs" element={<Catalogs/>}/>
                <Route path="/catalogs-stock" element={<CatalogsStock/>}/>
                <Route path="/personal" element={<Personal/>}/>
                <Route path="/products" element={<Products/>}/>
                <Route path="/stocks" element={<Stocks/>}/>
                <Route path="/history" element={<History/>}/>
                <Route path="/personal-data" element={<PersonalData/>}/>
                <Route path="/last-winners" element={<LastWinners/>}/>
                <Route path="/admin" element={<RequireAuth><Admin/></RequireAuth>}/>
                <Route path="/admin/catalogs" element={<RequireAuth><AdminCatalogs/></RequireAuth>}/>
                <Route path="/admin/notifications" element={<RequireAuth><AdminNotifications/></RequireAuth>}/>
                <Route path="/admin/create-notification" element={<RequireAuth><CreateNotification/></RequireAuth>}/>
                <Route path="/admin/catalogs-stocks" element={<RequireAuth><AdminCatalogsStocks/></RequireAuth>}/>
                <Route path="/admin/create-catalog" element={<RequireAuth><CreateCatalog/></RequireAuth>}/>
                <Route path="/admin/create-catalog-stocks" element={<RequireAuth><CreateCatalogStocks/></RequireAuth>}/>
                <Route path="/admin/create-product" element={<RequireAuth><CreateProduct/></RequireAuth>}/>
                <Route path="/admin/create-stocks" element={<RequireAuth><CreateStocks/></RequireAuth>}/>
                <Route path="/admin/products" element={<RequireAuth><AdminProduct/></RequireAuth>}/>
                <Route path="/admin/banners" element={<RequireAuth><Banners/></RequireAuth>}/>
                <Route path="/admin/edit-user" element={<RequireAuth><EditUser/></RequireAuth>}/>
                <Route path="/admin/users" element={<RequireAuth><AdminUsers/></RequireAuth>}/>
                <Route path="/admin/stocks" element={<RequireAuth><AdminStocks/></RequireAuth>}/>
                <Route path="/payments" element={<Payments/>}/>
                <Route path="/about" element={<About/>}/>
                <Route path="/contacts" element={<Contacts/>}/>
                <Route path="/delivery" element={<Delivery/>}/>
                <Route path="/return-money" element={<ReturnMoney/>}/>
                <Route path="/return-product" element={<ReturnProduct/>}/>
                <Route path="/rules" element={<Rules/>}/>
                <Route path="/vacancies" element={<Vacancies/>}/>
                <Route path="/politics" element={<Politics/>}/>


                <Route path="/favorite" element={<RequireAuth><Favorite/> </RequireAuth>}/>
                <Route path="/cart" element={<RequireAuth><Cart/> </RequireAuth>}/>


                <Route path="/pay-error" element={<PayError/>}/>
                <Route path="/pay-success" element={<PaySuccess/>}/>
                <Route path="/pay-subscribe-success" element={<PaySubscribeSuccess/>}/>
                <Route path="/pay-shop-success" element={<PayShopSuccess/>}/>
            </Routes>
            <Footer/>
        </HashRouter>
    );
}

export default App;
