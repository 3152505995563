import React, { useState, useEffect } from "react";
import Header from "../../components/Header";
import "./CreateCatalog.scss";
import axios from "axios";
import { useNavigate, useLocation } from "react-router-dom";

export default function CreateCatalogStocks() {
  const { state } = useLocation();
  const [img, setImg] = useState(null);
  const [id, setId] = useState(null);
  const [notification, setNotification] = React.useState("");
  const [parentId, setParentId] = useState(null);
  const [nameKz, setNameKz] = useState("");
  const [nameRu, setNameRu] = useState("");
  const [nameEn, setNameEn] = useState("");
  const navigate = useNavigate();
  const token = localStorage.getItem("token");
  const baseURL = "https://backend.1-q.kz/";
  useEffect(() => {
    console.log(state);
    if (state?.category) {
      let category = state.category;
      setNameKz(category.nameKz);
      setNameRu(category.nameRu);
      setNameEn(category.nameEn);
      setImg(category.photo);
      setId(category.id);
      setParentId(category.parentId);
    }
    console.log(state?.categoryId);
  }, []);
  const handleChangeImage = async (e) => {
    let reader = new FileReader();
    reader.readAsDataURL(e.target.files[0]);
    reader.onload = function () {
      const imgElement = document.createElement("img");
      imgElement.src = reader.result;


      imgElement.onload = function (e) {
        const canvas = document.createElement("canvas");
        const MAX_WIDTH = 400;

        const scaleSize = MAX_WIDTH / e.target.width;
        canvas.width = MAX_WIDTH;
        canvas.height = e.target.height * scaleSize;

        const ctx = canvas.getContext("2d");

        ctx.drawImage(e.target, 0, 0, canvas.width, canvas.height);

        const srcEncoded = ctx.canvas.toDataURL(e.target, "image/jpeg").replace(
          /^data:image\/(png|jpg|jpeg|jpeg);base64,/,
          ""
        );
        setImg(srcEncoded)
      };
    };
  };

  const create = (e) => {
    e.preventDefault();
    const params = {
      id: id,
      nameEn: nameEn,
      nameKz: nameKz,
      nameRu: nameRu,
      parentId: parentId ? parentId : state?.id,
      photoBase64: img,
    };

    axios
      .post(`${baseURL}category/save`, params, {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          Authorization: "Bearer " + token,
        },
      })
      .then((res) => {
        console.log(res.data.data);
        navigate("/admin/catalogs-stocks");
      })
      .catch((err) => {
        setNotification("error");
        setTimeout(() => {
          setNotification("");
        }, 5000);
      });
    console.log(state);
  };
  return (
    <>
      {notification === "error" && (
        <div class="myAlert-top alert alert-danger">
          <strong>Заполните все поля!</strong>
        </div>
      )}
      <Header />
      <div className="container catalog">
        <form>
          <div class="mb-3">
            <label class="form-label">Название категории на казахском</label>
            <input
              type="text"
              class="form-control"
              value={nameKz}
              onChange={(event) => setNameKz(event.target.value)}
            />
          </div>
          <div class="mb-3">
            <label class="form-label">Название категории на русском</label>
            <input
              type="text"
              class="form-control"
              value={nameRu}
              onChange={(event) => setNameRu(event.target.value)}
            />
          </div>
          <div class="mb-3">
            <label class="form-label">Название категории на английском</label>
            <input
              type="text"
              class="form-control"
              value={nameEn}
              onChange={(event) => setNameEn(event.target.value)}
            />
          </div>
          <div class="mb-3">
            <label fclass="form-label">Фото</label>
            <input type="file" className="w-100" onChange={handleChangeImage} />
            {img && (
              <img
                className="catalog__img"
                src={`data:image/jpeg;base64,${img}`}
                alt="img"
              />
            )}
          </div>
          <button onClick={create} class="btn btn-primary">
            Сохранить
          </button>
        </form>
      </div>
    </>
  );
}
