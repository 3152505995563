import React from "react";
import Header from "../../components/Header";
import "./styles.scss";
export default function Payments() {
  return (
    <>
      <Header />
      <div className="container info">
        <h1>Возврат денежных средств </h1>
        <h3>Перечисление денежных средств может занять до 30 дней.</h3>
      </div>
    </>
  );
}
