import React from "react";
import { Link } from "react-router-dom";
import "./HeaderIcon.scss";
import { useNavigate } from "react-router-dom";


export default function HeaderIcon({statusImage, textInfo, textIcon , path}) {
  const StatusImage = statusImage;
  const navigate = useNavigate();

  const logout = (e) => {
    e.preventDefault();
    localStorage.removeItem('token');
    localStorage.removeItem('role');
    localStorage.removeItem('login');
    navigate("/login");
  }
  return (
    <Link to={path} className="icon" href="#">
      <StatusImage fontSize="large" />
      <div className="text">
        <span className="text-description">{textIcon}</span>
      </div>
    </Link>
  );
}
