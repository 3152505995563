import React from "react";
import {useTranslation} from "react-i18next";
import "./PopUp.scss";

export const lngTypes = [
    {name: "казақша", sortProperty: "Kz"},
    {name: "русский", sortProperty: "Ru"},
];

export const currencyTypes = [
    {name: "USD", sortProperty: "usd"},
    {name: "Тенге", sortProperty: "tg"},
    {name: "Рубль", sortProperty: "rub"},
];

export default function PopUp({typeSort, typesOption}) {
    const {t, i18n} = useTranslation();
    const [open, setOpen] = React.useState(false);
    const [lang, setLang] = React.useState(localStorage.getItem("lang") == 'Kz' ? lngTypes[0].name : lngTypes[1].name);
    let types = typesOption === "lngTypes" ? lngTypes : currencyTypes;

    const onClickSortItem = (obj) => {
        setOpen(false)
        localStorage.setItem("lang", obj.sortProperty);
        i18n.changeLanguage(obj.sortProperty)
        setLang(obj.name)
        console.log(obj)
    }
    return (
        <div className="sort">
            <div className="sort__label">
                <b className="s-name"> {t("lang")}:</b>
                <span onClick={() => setOpen(!open)}> {lang}</span>
            </div>

            {open && (
                <div className="sort__popup">
                    <ul>
                        {types.map((obj, i) => (
                            <li
                                onClick={() => onClickSortItem(obj)}
                                className={2 === obj.sortProperty ? "active" : ""}
                                key={i}
                            >
                                {obj.name}
                            </li>
                        ))}
                    </ul>
                </div>
            )}
        </div>
    );
}
