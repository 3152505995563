import React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

export default function EmptyBlock() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  return (
    <div className="empty">
      {" "}
      <div className="category__btn backlink">
        <button
          onClick={() => {
            navigate(-1);
          }}
          type="button"
          className="btn btn-primary"
        >
          {t("returnBack")}
        </button>
      </div>
      <h1>Пустая страница</h1>
    </div>
  );
}
