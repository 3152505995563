import React, { useState, useEffect } from "react";
import Header from "../../components/Header";
import Loader from "../../components/Loader";
import EmptyBlock from "../../components/EmptyBlock";
import { useNavigate, useLocation } from "react-router-dom";
import "./CatalogsStock.scss";
import API from "../../api/apiController";
import { useTranslation } from "react-i18next";

export default function CatalogsStock() {
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { state } = useLocation();
  const [photoArr, setPhotoArr] = useState([]);
  const [categories, setCategories] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize] = useState(5);
  const lang = localStorage.getItem('lang')
  const [totalPages, setTotalPages] = useState(0)
  useEffect(() => {
    setCategories([]);
    setLoading(true);
    paginate()
  }, [state]);

  const paginate = (page) => {
    console.log("paginate", currentPage);
    setLoading(true);
    setCategories([])
    setCurrentPage(page);
    const paginationParams = {
      pageNumber: page ? page-1 : currentPage-1,
      pageSize: 10
    }

    const paramsCatalog = {
      active: true
    }

    const params = {
      id: state?.id,
      active: true
    }
    !state?.id ? API.user.getCatalogs(paramsCatalog, paginationParams).then(async (response) => {
       filterCatalogsParent(response.data.data.data.data);
       setTotalPages(response.data.data.totalPages)
      setLoading(false);
    }) : API.user.getSubCatalogs(params,paginationParams).then((response) => {
      setCategories(response.data.data.data.data);
      setTotalPages(response.data.data.totalPages)
      getPhotoCategories(response.data.data.data.data)
      setLoading(false);
    });
  }

  const filterCatalogsParent = (catalogList) => {
    const newCatalogList = catalogList.filter(el => el.hasProducts !== false)
    setCategories(newCatalogList);
    getPhotoCategories(newCatalogList);
  };

  const filterCategoryChildren = (data) => {
    const newCategory = data.filter((el) => el.parentId === state?.id && el.hasProducts);
    setCategories(newCategory);
  };

  const getPhotoCategories = (categories) => {
    categories.map(category => {
      const params = {
        id: category.id,
      }
      let arr = []
      API.user.getPhoto(params)
      .then((res) => {
        const obj = {
          id:category.id,
          photo: res.data.data
        }
        arr.push(obj)
        setPhotoArr(arr)
        category.photo = res.data.data;
      });
    })
  };

  return (
    <>
      <Header />

      <div className="container ">
        <div className="back">
          <button
            onClick={() => {
              state?.id ? navigate('/catalogs-stock') : navigate('/');
            }}
            type="button"
            className="btn btn-primary"
          >
            {t("returnBack")}
          </button>
        </div>
        <div class="row row-cols-1 row-cols-md-5 g-4 categories">
          {loading && <Loader />}
          {categories &&
            categories.map((category) => (
              <div
                class="card categories__item"
                onClick={() => {
                  state?.id
                    ? navigate("/stocks", {
                      state: { id: category.id, backId: state?.id },
                    })
                    : navigate("/catalogs-stock", {
                      state: { id: category.id },
                    });
                }}
              >
                <div class="card-body categories__body">
                  <h5 class="text-center card-title">{category[`name${lang}`]}</h5>
                  <img
                    src={category.photo ? `data:image/jpeg;base64,${category.photo}` : require('../../assets/notPhoto.jpg')}
                    className="card-img-top categories__img"
                    alt="..."
                  />
                </div>
              </div>
            ))}
          {!loading && !categories?.length && <EmptyBlock />}
        </div>
        {totalPages && <nav aria-label="Page navigation example">
          <ul class="pagination">
            <li class="page-item">
              <button class="page-link" disabled={currentPage===1} aria-label="Previous" onClick = {()=> paginate(currentPage-1)}>
                <span aria-hidden="true">&laquo;</span>
              </button>
            </li>
            {categories && Array.from(Array(totalPages)).map((page,i) => (
              <li class="page-item">
              <button class="page-link" style={ currentPage === i+1 ? {backgroundColor: 'blue',color:'white'} : {}} onClick = {()=> paginate(i+1)}>
                {i+1}
              </button>
            </li>
            ))}
            <li class="page-item">
              <button class="page-link"  aria-label="Next" disabled={currentPage===totalPages} onClick = {()=> paginate(currentPage+1)}>
                <span aria-hidden="true">&raquo;</span>
              </button>
            </li>
          </ul>
        </nav>}
      </div>
    </>
  );
}
