import React, { useState, useEffect } from "react";
import Header from "../../components/Header";
import Loader from "../../components/Loader";
import "./CreateCatalog.scss";
import { useNavigate } from "react-router-dom";
import API from "../../api/apiController";
import { useTranslation } from "react-i18next";

export default function Banners() {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [loadingSave, setLoadingSave] = useState(false);

  const [firstSitePhotoKz, setFirstSitePhotoKz] = useState("");
  const [secondSitePhotoKz, setSecondSitePhotoKz] = useState("");
  const [thirdSitePhotoKz, setThirdSitePhotoKz] = useState("");

  const [firstMobilePhotoKz, setFirstMobilePhotoKz] = useState("");
  const [secondMobilePhotoKz, setSecondMobilePhotoKz] = useState("");
  const [thirdMobilePhotoKz, setThirdMobilePhotoKz] = useState("");

  const [firstSitePhotoRu, setFirstSitePhotoRu] = useState("");
  const [secondSitePhotoRu, setSecondSitePhotoRu] = useState("");
  const [thirdSitePhotoRu, setThirdSitePhotoRu] = useState("");

  const [firstMobilePhotoRu, setFirstMobilePhotoRu] = useState("");
  const [secondMobilePhotoRu, setSecondMobilePhotoRu] = useState("");
  const [thirdMobilePhotoRu, setThirdMobilePhotoRu] = useState("");

  const navigate = useNavigate();
  useEffect(() => {
    getBanners();
  }, []);
  const getBanners = async () => {
    setLoading(true);
    const paramsSiteRu = {
      lang: "RU",
      system: "SITE",
    };
    await API.admin.getBanner(paramsSiteRu).then(async (response) => {
      setFirstSitePhotoRu(response.data.data.firstPhoto);
      setSecondSitePhotoRu(response.data.data.secondPhoto);
      setThirdSitePhotoRu(response.data.data.thirdPhoto);
    });

    const paramsSiteKz = {
      lang: "KZ",
      system: "SITE",
    };
    await API.admin.getBanner(paramsSiteKz).then(async (response) => {
      setFirstSitePhotoKz(response.data.data.firstPhoto);
      setSecondSitePhotoKz(response.data.data.secondPhoto);
      setThirdSitePhotoKz(response.data.data.thirdPhoto);
    });

    const paramsMobileRu = {
      lang: "RU",
      system: "MOBILE",
    };
    await API.admin.getBanner(paramsMobileRu).then(async (response) => {
      setFirstMobilePhotoRu(response.data.data.firstPhoto);
      setSecondMobilePhotoRu(response.data.data.secondPhoto);
      setThirdMobilePhotoRu(response.data.data.thirdPhoto);
    });
    const paramsMobilekZ = {
      lang: "KZ",
      system: "MOBILE",
    };
    await API.admin.getBanner(paramsMobilekZ).then(async (response) => {
      setFirstMobilePhotoKz(response.data.data.firstPhoto);
      setSecondMobilePhotoKz(response.data.data.secondPhoto);
      setThirdMobilePhotoKz(response.data.data.thirdPhoto);
      setLoading(false);
    });
  };
  const handleChangeImage = async (e, type) => {
    let reader = new FileReader();
    const imgElement = document.createElement("img");
    reader.readAsDataURL(e.target.files[0]);
    reader.onload = function () {
      switch (type) {
        case "firstSitePhotoRu":
          imgElement.src = reader.result;
          imgElement.onload = function (e) {
            const canvas = document.createElement("canvas");
            const MAX_WIDTH = 1296;
            const scaleSize = MAX_WIDTH / e.target.width;
            canvas.width = MAX_WIDTH;
            canvas.height = e.target.height * scaleSize;
            const ctx = canvas.getContext("2d");
            ctx.drawImage(e.target, 0, 0, canvas.width, canvas.height);
            const srcEncoded = ctx.canvas
              .toDataURL(e.target, "image/jpeg")
              .replace(/^data:image\/(png|jpg|jpeg|jpeg);base64,/, "");
            setFirstSitePhotoRu(srcEncoded);
          };
          break;
        case "secondSitePhotoRu":
          imgElement.src = reader.result;
          imgElement.onload = function (e) {
            const canvas = document.createElement("canvas");
            const MAX_WIDTH = 1296;
            const scaleSize = MAX_WIDTH / e.target.width;
            canvas.width = MAX_WIDTH;
            canvas.height = e.target.height * scaleSize;
            const ctx = canvas.getContext("2d");
            ctx.drawImage(e.target, 0, 0, canvas.width, canvas.height);
            const srcEncoded = ctx.canvas
              .toDataURL(e.target, "image/jpeg")
              .replace(/^data:image\/(png|jpg|jpeg|jpeg);base64,/, "");
            setSecondSitePhotoRu(srcEncoded);
          };
          break;
        case "thirdSitePhotoRu":
          imgElement.src = reader.result;
          imgElement.onload = function (e) {
            const canvas = document.createElement("canvas");
            const MAX_WIDTH = 1296;
            const scaleSize = MAX_WIDTH / e.target.width;
            canvas.width = MAX_WIDTH;
            canvas.height = e.target.height * scaleSize;
            const ctx = canvas.getContext("2d");
            ctx.drawImage(e.target, 0, 0, canvas.width, canvas.height);
            const srcEncoded = ctx.canvas
              .toDataURL(e.target, "image/jpeg")
              .replace(/^data:image\/(png|jpg|jpeg|jpeg);base64,/, "");
            setThirdSitePhotoRu(srcEncoded);
          };
          break;

        case "firstSitePhotoKz":
          imgElement.src = reader.result;
          imgElement.onload = function (e) {
            const canvas = document.createElement("canvas");
            const MAX_WIDTH = 1296;
            const scaleSize = MAX_WIDTH / e.target.width;
            canvas.width = MAX_WIDTH;
            canvas.height = e.target.height * scaleSize;
            const ctx = canvas.getContext("2d");
            ctx.drawImage(e.target, 0, 0, canvas.width, canvas.height);
            const srcEncoded = ctx.canvas
              .toDataURL(e.target, "image/jpeg")
              .replace(/^data:image\/(png|jpg|jpeg|jpeg);base64,/, "");
            setFirstSitePhotoKz(srcEncoded);
          };
          break;
        case "secondSitePhotoKz":
          imgElement.src = reader.result;
          imgElement.onload = function (e) {
            const canvas = document.createElement("canvas");
            const MAX_WIDTH = 1296;
            const scaleSize = MAX_WIDTH / e.target.width;
            canvas.width = MAX_WIDTH;
            canvas.height = e.target.height * scaleSize;
            const ctx = canvas.getContext("2d");
            ctx.drawImage(e.target, 0, 0, canvas.width, canvas.height);
            const srcEncoded = ctx.canvas
              .toDataURL(e.target, "image/jpeg")
              .replace(/^data:image\/(png|jpg|jpeg|jpeg);base64,/, "");
            setSecondSitePhotoKz(srcEncoded);
          };
          break;
        case "thirdSitePhotoKz":
          imgElement.src = reader.result;
          imgElement.onload = function (e) {
            const canvas = document.createElement("canvas");
            const MAX_WIDTH = 1296;
            const scaleSize = MAX_WIDTH / e.target.width;
            canvas.width = MAX_WIDTH;
            canvas.height = e.target.height * scaleSize;
            const ctx = canvas.getContext("2d");
            ctx.drawImage(e.target, 0, 0, canvas.width, canvas.height);
            const srcEncoded = ctx.canvas
              .toDataURL(e.target, "image/jpeg")
              .replace(/^data:image\/(png|jpg|jpeg|jpeg);base64,/, "");
            setThirdSitePhotoKz(srcEncoded);
          };
          break;

        case "firstMobilePhotoRu":
          imgElement.src = reader.result;
          imgElement.onload = function (e) {
            const canvas = document.createElement("canvas");
            const MAX_WIDTH = 1296;
            const scaleSize = MAX_WIDTH / e.target.width;
            canvas.width = MAX_WIDTH;
            canvas.height = e.target.height * scaleSize;
            const ctx = canvas.getContext("2d");
            ctx.drawImage(e.target, 0, 0, canvas.width, canvas.height);
            const srcEncoded = ctx.canvas
              .toDataURL(e.target, "image/jpeg")
              .replace(/^data:image\/(png|jpg|jpeg|jpeg);base64,/, "");
            setFirstMobilePhotoRu(srcEncoded);
          };
          break;
        case "secondMobilePhotoRu":
          imgElement.src = reader.result;
          imgElement.onload = function (e) {
            const canvas = document.createElement("canvas");
            const MAX_WIDTH = 1296;
            const scaleSize = MAX_WIDTH / e.target.width;
            canvas.width = MAX_WIDTH;
            canvas.height = e.target.height * scaleSize;
            const ctx = canvas.getContext("2d");
            ctx.drawImage(e.target, 0, 0, canvas.width, canvas.height);
            const srcEncoded = ctx.canvas
              .toDataURL(e.target, "image/jpeg")
              .replace(/^data:image\/(png|jpg|jpeg|jpeg);base64,/, "");
            setSecondMobilePhotoRu(srcEncoded);
          };
          break;
        case "thirdMobilePhotoRu":
          imgElement.src = reader.result;
          imgElement.onload = function (e) {
            const canvas = document.createElement("canvas");
            const MAX_WIDTH = 1296;
            const scaleSize = MAX_WIDTH / e.target.width;
            canvas.width = MAX_WIDTH;
            canvas.height = e.target.height * scaleSize;
            const ctx = canvas.getContext("2d");
            ctx.drawImage(e.target, 0, 0, canvas.width, canvas.height);
            const srcEncoded = ctx.canvas
              .toDataURL(e.target, "image/jpeg")
              .replace(/^data:image\/(png|jpg|jpeg|jpeg);base64,/, "");
            setThirdMobilePhotoRu(srcEncoded);
          };
          break;

        case "firstMobilePhotoKz":
          imgElement.src = reader.result;
          imgElement.onload = function (e) {
            const canvas = document.createElement("canvas");
            const MAX_WIDTH = 1296;
            const scaleSize = MAX_WIDTH / e.target.width;
            canvas.width = MAX_WIDTH;
            canvas.height = e.target.height * scaleSize;
            const ctx = canvas.getContext("2d");
            ctx.drawImage(e.target, 0, 0, canvas.width, canvas.height);
            const srcEncoded = ctx.canvas
              .toDataURL(e.target, "image/jpeg")
              .replace(/^data:image\/(png|jpg|jpeg|jpeg);base64,/, "");
            setFirstMobilePhotoKz(srcEncoded);
          };
          break;
        case "secondMobilePhotoKz":
          imgElement.src = reader.result;
          imgElement.onload = function (e) {
            const canvas = document.createElement("canvas");
            const MAX_WIDTH = 1296;
            const scaleSize = MAX_WIDTH / e.target.width;
            canvas.width = MAX_WIDTH;
            canvas.height = e.target.height * scaleSize;
            const ctx = canvas.getContext("2d");
            ctx.drawImage(e.target, 0, 0, canvas.width, canvas.height);
            const srcEncoded = ctx.canvas
              .toDataURL(e.target, "image/jpeg")
              .replace(/^data:image\/(png|jpg|jpeg|jpeg);base64,/, "");
            setSecondMobilePhotoKz(srcEncoded);
          };
          break;
        case "thirdMobilePhotoKz":
          imgElement.src = reader.result;
          imgElement.onload = function (e) {
            const canvas = document.createElement("canvas");
            const MAX_WIDTH = 1296;
            const scaleSize = MAX_WIDTH / e.target.width;
            canvas.width = MAX_WIDTH;
            canvas.height = e.target.height * scaleSize;
            const ctx = canvas.getContext("2d");
            ctx.drawImage(e.target, 0, 0, canvas.width, canvas.height);
            const srcEncoded = ctx.canvas
              .toDataURL(e.target, "image/jpeg")
              .replace(/^data:image\/(png|jpg|jpeg|jpeg);base64,/, "");
            setThirdMobilePhotoKz(srcEncoded);
          };
          break;

          break;
        default:
          console.log(e);
      }
    };
  };

  const saveBanner = () => {
    setLoadingSave(true)
    const data = {
      firstMobilePhotoKz: firstMobilePhotoKz,
      secondMobilePhotoKz: secondMobilePhotoKz,
      thirdMobilePhotoKz: thirdMobilePhotoKz,

      firstSitePhotoKz: firstSitePhotoKz,
      secondSitePhotoKz: secondSitePhotoKz,
      thirdSitePhotoKz: thirdSitePhotoKz,

      firstMobilePhotoRu: firstMobilePhotoRu,
      secondMobilePhotoRu: secondMobilePhotoRu,
      thirdMobilePhotoRu: thirdMobilePhotoRu,

      firstSitePhotoRu: firstSitePhotoRu,
      secondSitePhotoRu: secondSitePhotoRu,
      thirdSitePhotoRu: thirdSitePhotoRu,
    };
    API.admin.uploadBanner(data).then((response) => {
      console.log(response);
      setLoadingSave(false);
      navigate("/admin");
    });
  };
  return (
    <>
      <Header />
      {loading ? (
        <Loader />
      ) : (
        <div className="container ">
          <h1>{t("admin.banners")}</h1>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <h5 style={{ color: "gray" }}>
              Для сайта рекомендуются изображения с разрешением 1296 px на 600
              px <br/> 
              Для приложения рекомендуются изображения с разрешением 1296 px
              на 300 px

            </h5>
            <button
              onClick={() => {
                navigate("/admin");
              }}
              type="button"
              className="btn btn-primary"
            >
              {t("returnBack")}
            </button>
          </div>

          <form className="banners_items">
            <div class="row">
              <div class="mb-3 col">
                <label fclass="form-label">
                  {" "}
                  Для сайта – 1 баннер – на русском языке
                </label>
                <input
                  type="file"
                  className="w-100"
                  onChange={(event) =>
                    handleChangeImage(event, "firstSitePhotoRu")
                  }
                />
                {firstSitePhotoRu && (
                  <img
                    className="img-fluid img-thumbnail"
                    src={`data:image/jpeg;base64,${firstSitePhotoRu}`}
                    alt="img"
                  />
                )}
              </div>

              <div class="mb-3 col">
                <label fclass="form-label">
                  {" "}
                  Для сайта – 2 баннер – на русском языке
                </label>
                <input
                  type="file"
                  className="w-100"
                  onChange={(event) =>
                    handleChangeImage(event, "secondSitePhotoRu")
                  }
                />
                {secondSitePhotoRu && (
                  <img
                    className="img-fluid img-thumbnail"
                    src={`data:image/jpeg;base64,${secondSitePhotoRu}`}
                    alt="img"
                  />
                )}
              </div>
              <div class="mb-3 col">
                <label fclass="form-label">
                  {" "}
                  Для сайта – 3 баннер – на русском языке
                </label>
                <input
                  type="file"
                  className="w-100"
                  onChange={(event) =>
                    handleChangeImage(event, "thirdSitePhotoRu")
                  }
                />
                {thirdSitePhotoRu && (
                  <img
                    className="img-img-fluid img-thumbnail"
                    src={`data:image/jpeg;base64,${thirdSitePhotoRu}`}
                    alt="img"
                  />
                )}
              </div>
            </div>
            <div class="row">
              <div class="mb-3 col">
                <label fclass="form-label">
                  {" "}
                  Для сайта – 1 баннер – на казахском языке
                </label>
                <input
                  type="file"
                  className="w-100"
                  onChange={(event) =>
                    handleChangeImage(event, "firstSitePhotoKz")
                  }
                />
                {firstSitePhotoKz && (
                  <img
                    className="img-fluid img-thumbnail"
                    src={`data:image/jpeg;base64,${firstSitePhotoKz}`}
                    alt="img"
                  />
                )}
              </div>

              <div class="mb-3 col">
                <label fclass="form-label">
                  {" "}
                  Для сайта – 2 баннер – на казахском языке
                </label>
                <input
                  type="file"
                  className="w-100"
                  onChange={(event) =>
                    handleChangeImage(event, "secondSitePhotoKz")
                  }
                />
                {secondSitePhotoKz && (
                  <img
                    className="img-fluid img-thumbnail"
                    src={`data:image/jpeg;base64,${secondSitePhotoKz}`}
                    alt="img"
                  />
                )}
              </div>
              <div class="mb-3 col">
                <label fclass="form-label">
                  {" "}
                  Для сайта – 3 баннер – на казахском языке
                </label>
                <input
                  type="file"
                  className="w-100"
                  onChange={(event) =>
                    handleChangeImage(event, "thirdSitePhotoKz")
                  }
                />
                {thirdSitePhotoKz && (
                  <img
                    className="img-img-fluid img-thumbnail"
                    src={`data:image/jpeg;base64,${thirdSitePhotoKz}`}
                    alt="img"
                  />
                )}
              </div>
            </div>
            <div class="row">
              <div class="mb-3 col">
                <label fclass="form-label">
                  {" "}
                  Для мобильного приложения – 1 баннер – на русском языке
                </label>
                <input
                  type="file"
                  className="w-100"
                  onChange={(event) =>
                    handleChangeImage(event, "firstMobilePhotoRu")
                  }
                />
                {firstMobilePhotoRu && (
                  <img
                    className="img-fluid img-thumbnail"
                    src={`data:image/jpeg;base64,${firstMobilePhotoRu}`}
                    alt="img"
                  />
                )}
              </div>

              <div class="mb-3 col">
                <label fclass="form-label">
                  {" "}
                  Для мобильного приложения – 2 баннер – на русском языке
                </label>
                <input
                  type="file"
                  className="w-100"
                  onChange={(event) =>
                    handleChangeImage(event, "secondMobilePhotoRu")
                  }
                />
                {secondMobilePhotoRu && (
                  <img
                    className="img-fluid img-thumbnail"
                    src={`data:image/jpeg;base64,${secondMobilePhotoRu}`}
                    alt="img"
                  />
                )}
              </div>
              <div class="mb-3 col">
                <label fclass="form-label">
                  {" "}
                  Для мобильного приложения – 3 баннер – на русском языке
                </label>
                <input
                  type="file"
                  className="w-100"
                  onChange={(event) =>
                    handleChangeImage(event, "thirdMobilePhotoRu")
                  }
                />
                {thirdMobilePhotoRu && (
                  <img
                    className="img-img-fluid img-thumbnail"
                    src={`data:image/jpeg;base64,${thirdMobilePhotoRu}`}
                    alt="img"
                  />
                )}
              </div>
            </div>
            <div class="row">
              <div class="mb-3 col">
                <label fclass="form-label">
                  {" "}
                  Для мобильного приложения – 1 баннер – на казахском языке
                </label>
                <input
                  type="file"
                  className="w-100"
                  onChange={(event) =>
                    handleChangeImage(event, "firstMobilePhotoKz")
                  }
                />
                {firstMobilePhotoKz && (
                  <img
                    className="img-fluid img-thumbnail"
                    src={`data:image/jpeg;base64,${firstMobilePhotoKz}`}
                    alt="img"
                  />
                )}
              </div>

              <div class="mb-3 col">
                <label fclass="form-label">
                  {" "}
                  Для мобильного приложения – 2 баннер – на казахском языке
                </label>
                <input
                  type="file"
                  className="w-100"
                  onChange={(event) =>
                    handleChangeImage(event, "secondMobilePhotoKz")
                  }
                />
                {secondMobilePhotoKz && (
                  <img
                    className="img-fluid img-thumbnail"
                    src={`data:image/jpeg;base64,${secondMobilePhotoKz}`}
                    alt="img"
                  />
                )}
              </div>
              <div class="mb-3 col">
                <label fclass="form-label">
                  {" "}
                  Для мобильного приложения – 3 баннер – на казахском языке
                </label>
                <input
                  type="file"
                  className="w-100"
                  onChange={(event) =>
                    handleChangeImage(event, "thirdMobilePhotoKz")
                  }
                />
                {thirdMobilePhotoKz && (
                  <img
                    className="img-img-fluid img-thumbnail"
                    src={`data:image/jpeg;base64,${thirdMobilePhotoKz}`}
                    alt="img"
                  />
                )}
              </div>
            </div>
            <button onClick={() => saveBanner()} class="btn btn-primary">
              {t("admin.saveBanners")}
            </button>

            {loadingSave && (
              <div
                style={{ marginLeft: "15px" }}
                class="spinner-border text-primary"
                role="status"
              >
                <span class="visually-hidden">Loading...</span>
              </div>
            )}
          </form>
        </div>
      )}
    </>
  );
}
