import React, { useState, useEffect } from "react";
import Header from "../../components/Header";
import "./Personal.scss";
import { useNavigate } from "react-router-dom";
import API from "../../api/apiController";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { Declination } from "../../utils/helpers";

import { ACTIVE_BACK_LINK } from "../../utils/constants";
import { ACTIVE_FAILURE_LINK } from "../../utils/constants";
import { useTranslation } from "react-i18next";

export default function Personal() {
  const navigate = useNavigate();
  const [balance, setBalance] = useState(0);
  const [points, setPoints] = useState(0);
  const [cards, setCards] = useState([]);
  const login = localStorage.getItem("login");
  const [modalShow, setModalShow] = React.useState(false);
  const [modalShowWallet, setModalShowWallet] = React.useState(false);
  const [modalChangePswd, setModalChangePswd] = React.useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    API.user
      .getBalance((response) => response.json())
      .then((responseJson) => {
        setBalance(responseJson.data.data.shopWalletAmount);
        setPoints(responseJson.data.data.walletAmount);
      });
    API.pay.getCards().then((response) => {
      setCards(response.data.data);
    });
  }, []);

  const updateDate = () => {
    API.user
      .getBalance((response) => response.json())
      .then((responseJson) => {
        setBalance(responseJson.data.data.shopWalletAmount);
        setPoints(responseJson.data.data.walletAmount);
        setModalChangePswd(false);
        setModalShow(false);
        setModalShowWallet(false);
      });
    API.pay.getCards().then((response) => {
      setCards(response.data.data);
    });
  };

  const logout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("role");
    localStorage.removeItem("city");
    navigate("/login");
  };

  const formatPrice = (price) => {
    let formattedPrice = new Intl.NumberFormat().format(price);
    return formattedPrice.replaceAll(",", " ");
  };

  return (
    <>
      <Header />
      <div className="container personal">
        <div class="card mb-3">
          <div class="row g-0">
            <div class="col-md-4 text-center">
              <img
                src={require("../../assets/icons/profile.png")}
                class="img-fluid rounded-start"
                alt="..."
              />
            </div>
            <div class="col-md-8">
              <div class="card-body">
                <p class="card-text">{login}</p>
                <p class="card-text">
                  <small class="text-muted">
                    {t("personal.balance")} <b> {formatPrice(balance)}</b>{" "}
                    {t("tenge")}
                  </small>
                  <span
                    onClick={() => setModalShowWallet(true)}
                    className="personal__buy"
                  >
                    {t("personal.wallet")}
                  </span>
                  <ReplenishWalletModal
                    show={modalShowWallet}
                    onHide={() => setModalShowWallet(false)}
                    cards={cards}
                    balance={balance}
                    sendDataToParent={updateDate}
                  />
                </p>
                <p class="card-text">
                  <small class="text-muted">
                    {t("personal.bonus")} <b>{points}</b> {Declination(points)}
                  </small>
                  {/* <span
                    onClick={() => setModalShow(true)}
                    className="personal__buy"
                  >
                    приобрести подписку
                  </span> */}
                  <BuySubscribeModal
                    show={modalShow}
                    onHide={() => setModalShow(false)}
                    cards={cards}
                    balance={balance}
                    sendDataToParent={updateDate}
                  />
                </p>
                <p class="card-text">
                  <span onClick={() => navigate("/history")}>
                    {t("personal.history")}
                  </span>
                </p>
                <p class="card-text">
                  <span onClick={() => navigate("/personal-data")}>
                    {t("personal.personalData")}
                  </span>
                </p>
                <p onClick={() => setModalChangePswd(true)} class="card-text">
                  <span>{t("personal.changePassword")} </span>
                  <ChangePasswordModal
                    show={modalChangePswd}
                    onHide={() => {
                      updateDate();
                    }}
                    cards={cards}
                    balance={balance}
                    sendDataToParent={updateDate}
                  />
                </p>
                <p class="card-text">
                  <span onClick={logout}>{t("personal.out")}</span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

function BuySubscribeModal(props) {
  const [type, setType] = React.useState("");
  const [typeCart, setTypeCart] = React.useState("");
  const [notification, setNotification] = React.useState("");
  const [errMsg, setErrMsg] = React.useState("");
  const [ID, setID] = useState(null);
  const { t } = useTranslation();
  const lang = localStorage.getItem('lang')

  const gender = [
    {
      name: t("payCard"),
      code: "wallet",
    },
    {
      name: t("payOtherCard"),
      code: "cart",
    },
  ];

  const cartTypes = [
    {
      name: t("paySaveCart"),
      code: "saveCart",
    },
    {
      name: t("payOtherCard"),
      code: "otherCart",
    },
  ];

  const payViaWallet = async () => {
    await API.epay
      .payViaWallet()
      .then(async (res) => {
        console.log("ttt");
        console.log(res);

        props.sendDataToParent();
        setNotification("success");
        setTimeout(() => {
          setNotification("");
        }, 5000);
      })
      .catch((error) => {
        console.log("error", error.response.data.errors[0].valueRu);
        setErrMsg(error.response.data.errors[0][`value${lang}`]);
        props.sendDataToParent();
        setNotification("error");
        setTimeout(() => {
          setNotification("");
        }, 5000);
      });
  };

  const paySaveCards = async () => {
    let data = {
      cardID: ID,
    };
    await API.shopCart
      .payWithSaveCard(data)
      .then((response) => {
        if (response.status == "200") {
          setNotification("success");
          setTimeout(() => {
            setNotification("");
          }, 5000);
        }
      })
      .catch((error) => {
        props.sendDataToParent();
        setErrMsg(error.response.data.errors[0][`value${lang}`]);
        setNotification("error");
        setTimeout(() => {
          setNotification("");
        }, 5000);
      });
  };

  const getDataPay = async () => {
    await API.epay
      .getPayData()
      .then(async (response) => {
        let data = response.data.data.payData;
        let token = response.data.data.token;
        let type = "stock";
        await localStorage.setItem("type", type);
        var createPaymentObject = function () {
          data.auth = token;
          response.data.data.payData.backLink = ACTIVE_BACK_LINK;
          response.data.data.payData.failureBackLink = ACTIVE_FAILURE_LINK;
          return data;
        };

        let a = await window.halyk.pay(await createPaymentObject());
        console.log(a);
      })
      .catch((error) => {
        props.sendDataToParent();
        setErrMsg(error.response.data.errors[0][`value${lang}`]);
        setNotification("error");
        setTimeout(() => {
          setNotification("");
        }, 5000);
      });
  };

  const buySubscribe = () => {
    if (type === "wallet") {
      payViaWallet();
    } else if (type === "cart" && typeCart === "saveCart") {
      console.log("savecart");
      paySaveCards();
    } else if (type === "cart" && typeCart === "otherCart") {
      getDataPay();
      console.log("otherCart");
    }
    props.sendDataToParent();
  };
  return (
    <>
      {notification === "success" && (
        <div class="myAlert-top alert alert-success">
          <strong>Вы успешно приобрели подписку!</strong>
        </div>
      )}
      {notification === "error" && (
        <div class="myAlert-top alert alert-danger">
          <strong>{errMsg}</strong>
        </div>
      )}
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {t("personal.paySubs")}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h4>
            {t("personal.balance")} <b>{props.balance}</b> {t("tenge")}
          </h4>
          <div class="mb-3">
            <label class="form-label"> {t("personal.wayPay")}</label>
            <select
              onChange={(e) => {
                setType(e.target.value);
              }}
              value={type}
              class="form-control"
              id="cars"
            >
              <option value=""></option>
              {gender.map((city) => (
                <option value={city.code}>{city.name}</option>
              ))}
            </select>
          </div>
          {type === "cart" && (
            <div class="mb-3">
              <label class="form-label">{t("personal.chooseCart")}</label>
              <select
                onChange={(e) => {
                  setTypeCart(e.target.value);
                }}
                value={typeCart}
                class="form-control"
                id="cars"
              >
                <option value=""></option>
                {cartTypes.map((city) => (
                  <option value={city.code}>{city.name}</option>
                ))}
              </select>
            </div>
          )}
          {type === "cart" && typeCart === "saveCart" && (
            <div class="mb-3">
              {props.cards?.length ? (
                <>
                  <label class="form-label">{t("personal.chooseCart")}</label>
                  <select
                    onChange={(e) => {
                      setID(e.target.value);
                    }}
                    value={ID}
                    class="form-control"
                    id="cars"
                  >
                    <option value=""></option>
                    {props.cards.map((city) => (
                      <option value={city.ID}>{city.CardMask}</option>
                    ))}
                  </select>
                </>
              ) : (
                <span>{t("personal.chooseCart")}</span>
              )}
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={() => {
              buySubscribe();
            }}
          >
            {t("pay")}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

function ReplenishWalletModal(props) {
  const [amount, setAmount] = useState(null);
  const [data, setData] = useState(null);
  const [notification, setNotification] = React.useState("");
  const [typeCart, setTypeCart] = useState("");
  const [ID, setID] = useState(null);
  const { t } = useTranslation();

  const cartTypes = [
    {
      name: t("paySaveCart"),
      code: "saveCart",
    },
    {
      name: t("payOtherCard"),
      code: "otherCart",
    },
  ];

  const replenishData = async () => {
    if (typeCart === "saveCart") {
      paySaveCards();
      props.sendDataToParent();
    } else {
      getDataPay();
      props.sendDataToParent();
    }
    props.sendDataToParent();
  };

  const paySaveCards = () => {
    let data = {
      cardID: ID,
      amount: amount,
    };
    API.pay
      .replenishWithSavedCard(data)
      .then((response) => {
        setNotification("success");
        setTimeout(() => {
          setNotification("");
        }, 5000);
        props.sendDataToParent();
      })
      .catch((error) => {
        setNotification("error");
        setTimeout(() => {
          setNotification("");
        }, 5000);
      });
  };

  const getDataPay = async () => {
    let params = {
      amount: amount,
    };

    await API.pay.replenishData(params).then(async (response) => {
      await setData(response.data.data.payData);
      localStorage.setItem("type", "shop");
      var createPaymentObject = function () {
        response.data.data.payData.auth = response.data.data.token;
        response.data.data.payData.backLink = ACTIVE_BACK_LINK;
        response.data.data.payData.failureBackLink = ACTIVE_FAILURE_LINK;
        return response.data.data.payData;
      };

      let a = await window.halyk.pay(createPaymentObject());
    });
  };

  return (
    <>
      {notification === "success" && (
        <div class="myAlert-top alert alert-success">
          <strong>{t("personal.successDonat")}</strong>
        </div>
      )}
      {notification === "error" && (
        <div class="myAlert-top alert alert-danger">
          <strong>{t("error")}</strong>
        </div>
      )}
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {t("personal.donatWallet")}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h4>
            {t("personal.balance")} <b>{props.balance}</b> {t("tenge")}
          </h4>
          <div class="mb-3">
            <label class="form-label">{t("personal.amount")}</label>
            <input
              type="number"
              class="form-control"
              value={amount}
              placeholder={t("personal.amountPlaceholder")}
              onChange={(event) => setAmount(event.target.value)}
            />
          </div>

          <div class="mb-3">
            <label class="form-label">{t("personal.chooseCart")}</label>
            <select
              onChange={(e) => {
                setTypeCart(e.target.value);
              }}
              value={typeCart}
              class="form-control"
              id="cars"
            >
              <option value=""></option>
              {cartTypes.map((city) => (
                <option value={city.code}>{city.name}</option>
              ))}
            </select>
          </div>

          {typeCart === "saveCart" && (
            <div class="mb-3">
              {props.cards?.length ? (
                <>
                  <label class="form-label">{t("personal.chooseCart")}</label>
                  <select
                    onChange={(e) => {
                      setID(e.target.value);
                    }}
                    value={ID}
                    class="form-control"
                    id="cars"
                  >
                    <option value=""></option>
                    {props.cards.map((city) => (
                      <option value={city.ID}>{city.CardMask}</option>
                    ))}
                  </select>
                </>
              ) : (
                <span>{t("personal.notSaveCart")}</span>
              )}
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={() => {
              replenishData();
            }}
            disabled={!typeCart}
          >
            {t("personal.replenish")}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

function ChangePasswordModal(props) {
  const [validPassword, setValidPassword] = useState(false);
  const [hide, setHide] = useState(false);
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [notification, setNotification] = React.useState("");
  const { t } = useTranslation();

  const login = localStorage.getItem("login");

  const validatePassword = (text) => {
    if (text.length < 8) {
      setValidPassword(false);
      setNewPassword(text);
      return false;
    } else {
      setValidPassword(true);
      setNewPassword(text);
    }
  };

  const changePassword = () => {
    const data = {
      login: login,
      newPassword: newPassword,
      oldPassword: oldPassword,
    };
    setValidPassword(true);
    API.user
      .changeUserPassword(data)
      .then((response) => {
        props.sendDataToParent();
        setNewPassword("");
        setOldPassword("");
        setNotification("success");
        setTimeout(() => {
          setNotification("");
        }, 5000);
        setValidPassword(false);
      })
      .catch((error) => {
        props.sendDataToParent();
        setNotification("error");
        setNewPassword("");
        setOldPassword("");
        setTimeout(() => {
          setNotification("");
        }, 5000);
        setValidPassword(false);
      });
  };

  return (
    <>
      {notification === "success" && (
        <div class="myAlert-top alert alert-success">
          <strong>{t("personal.changePasswordSuccess")}</strong>
        </div>
      )}
      {notification === "error" && (
        <div class="myAlert-top alert alert-danger">
          <strong>{t("error")}</strong>
        </div>
      )}
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {t("personal.changeTiele")}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div class="mb-3 password-container">
            <label class="form-label">{t("auth.password")}</label>
            <input
              type={hide ? "text" : "text"}
              class="form-control pswd"
              value={oldPassword}
              placeholder={t("personal.oldPassword")}
              onChange={(event) => setOldPassword(event.target.value)}
            />
          </div>
          <div class="mb-3 password-container">
            <label class="form-label">{t("auth.password")}</label>
            <input
              type={hide ? "text" : "text"}
              class="form-control pswd"
              value={newPassword}
              placeholder={t("personal.newPassword")}
              onChange={(event) => validatePassword(event.target.value)}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={() => {
              changePassword();
            }}
          >
            {t("personal.changePassword")}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
