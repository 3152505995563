import React, {useEffect, useState} from "react";
import {useNavigate, useLocation} from "react-router-dom";
import {useTranslation} from "react-i18next";
import Header from "../../components/Header";
import Loader from "../../components/Loader";
import API from "../../api/apiController";
import "./Products.scss";

export default function Products() {
    const [loading, setLoading] = useState(false);
    const {t} = useTranslation();
    const navigate = useNavigate();
    const {state} = useLocation();
    const [products, setProducts] = useState([]);
    const [count, setCount] = useState(1);
    const [modalShow, setModalShow] = React.useState(false);
    const [notification, setNotification] = React.useState("");
    const [countId, setCountId] = useState(null);
    const [favoriteShopsId, setFavoriteShopsId] = useState([]);
    const token = localStorage.getItem("token");
    const auth = localStorage.getItem("auth");
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize] = useState(5);
    const [totalPages, setTotalPages] = useState(0);
    const [photoArr, setPhotoArr] = useState([]);
    const lang = localStorage.getItem("lang");

    useEffect(() => {
        setLoading(true);
        paginate();
    }, []);

    const paginate = (page) => {
        setLoading(true);
        setProducts([]);
        setCurrentPage(page);
        const paginationParams = {
            pageNumber: page ? page - 1 : currentPage - 1,
            pageSize: 10,
        };

        getFavoritesProduct().then();
        let paramsProducts = {
            categoryId: state?.id,
            active: true,
        };
        API.shop.getProducts(paramsProducts, paginationParams).then((response) => {
            setProducts(response.data.data.data.data);
            //getProductsPhoto(response.data.data.data.data);
            setTotalPages(response.data.data.totalPages);
            setLoading(false);
        });
    };

    const updateDate = () => {
        setModalShow(false);
    };

    const getProductsPhoto = (products) => {
        products.map((product) => {
            const params = {
                id: product.id,
            };
            let arr = [];
            API.shop.getProductsPhoto(params).then((res) => {
                const obj = {
                    id: product.id,
                    photo: res.data.data,
                };
                arr.push(obj);
                setPhotoArr(arr);
                product.logoPhoto = res.data.data;
            });
        });
    };

    const pressLike = (id, event) => {
        event.preventDefault();
        event.stopPropagation();
        let params = {
            productId: id,
        };
        if (favoriteShopsId.includes(id)) {
            API.shop
                .deleteFavorites(params)
                .then(async (response) => await getFavoritesProduct());
        } else {
            API.shop
                .addFavorites(params)
                .then(async (response) => await getFavoritesProduct());
        }
    };

    const getFavoritesProduct = async () => {
        let favoritesId = [];
        let favorites = [];
        await API.shop
            .getFavorites()
            .then(async (response) => (favorites = response.data.data));
        favorites.map((el) => favoritesId.push(el.id));
        setFavoriteShopsId(favoritesId);
    };

    const addShopCart = async (id, enable, count, event) => {
        event.preventDefault();
        event.stopPropagation();
        const params = {
            count: count,
            productId: id,
        };
        let paramsProducts = {
            categoryId: state?.id,
        };
        !enable
            ? await API.shopCart.add(params).then(async (res) => {
                paginate(currentPage);
                setNotification("addCart");
                setCount(1);
                setTimeout(() => {
                    setNotification("");
                }, 5000);
            })
            : await API.shopCart.deleteCart(params).then((res) => {
                paginate(currentPage);
                setNotification("deleteCart");
                setCount(1);
                setTimeout(() => {
                    setNotification("");
                }, 5000);
            });
    };

    const addCertificate = async (item) => {
        if (item.id !== countId) {
            item.remain === 1 ? setCount(1) : setCount(2);
            setCountId(item.id);
        } else {
            item.remain > count ? setCount(count + 1) : setCount(count);
        }
    };

    const minusCertificate = async (item) => {
        if (item.id !== countId) {
            setCount(2);
            setCountId(item.id);
        } else {
            setCount(count - 1);
            if (count <= 1) {
                setCount(1);
            }
        }
    };

    const formatPrice = (price) => {
        let formattedPrice = new Intl.NumberFormat().format(price);
        return formattedPrice.replaceAll(",", " ");
    };
    return (
        <>
            {notification === "addCart" && (
                <div className="myAlert-top alert alert-success">
                    <strong>{t("notifications.addedCart")}</strong>
                </div>
            )}
            {notification === "deleteCart" && (
                <div className="myAlert-top alert alert-danger">
                    <strong>{t("notifications.deltedCart")}</strong>
                </div>
            )}
            <Header/>
            <div className="container ">
                <div className="back">
                    <button
                        onClick={() => {
                            navigate("/catalogs", {state: {id: state?.backId}});
                        }}
                        type="button"
                        className="btn btn-primary"
                    >
                        {t("returnBack")}
                    </button>
                </div>
                <div className="row row-cols-1 row-cols-md-5 g-4 products">
                    {loading && <Loader/>}
                    {products &&
                        products.map((category) => (
                            <div className="card" key={category?.id}>
                                <div
                                    className="products__item"
                                    onClick={(e) => {
                                        navigate("/details", {state: {id: category.id}});
                                    }}
                                >
                                    <img
                                        className="heart"
                                        onClick={(event) => {
                                            pressLike(category.id, event);
                                        }}
                                        src={
                                            favoriteShopsId.includes(category.id)
                                                ? require("../../assets/icons/heart-fill.png")
                                                : require("../../assets/icons/heart.png")
                                        }
                                        alt=""
                                    />
                                    <img
                                        src={`https://backend.1-q.kz/shop/product/image?id=${category.id}`}
                                        className="card-img-top products__img"
                                        alt="..."
                                    />
                                    <div className="card-body products__info">
                                        <div>
                                            <h5 className="card-title text-break">{category[`name${lang}`]}</h5>
                                            <div
                                                style={{
                                                    display: "flex",
                                                    flexDirection: "row",
                                                    alignItems: "flex-start",
                                                }}
                                            >
                                                <h5> {t("history.cost")} </h5>
                                                <div
                                                    style={{
                                                        display: "flex",
                                                        flexDirection: "column",
                                                        marginLeft: '5px'
                                                    }}
                                                >
                                                    <h5>{formatPrice(category.price)} ₸</h5>
                                                    {category?.discountPrice && (
                                                        <span
                                                            style={{
                                                                color: "gray",
                                                                textDecoration: "line-through",
                                                                fontSize: "12px",
                                                                marginLeft: "5px",
                                                            }}
                                                        >
                              {formatPrice(category?.discountPrice)} ₸
                            </span>
                                                    )}{" "}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="products__buy">
                                    <div className="input-group list-group">
                                        <button
                                            className="btn btn-outline-secondary"
                                            type="button"
                                            id="button-addon1"
                                            onClick={() => minusCertificate(category)}
                                        >
                                            -
                                        </button>
                                        <input
                                            type="text"
                                            className="form-control"
                                            value={countId === category.id ? count : 1}
                                            onChange={(e) => setCount(e.target.value)}
                                            placeholder=""
                                            aria-label="Example text with button addon"
                                            aria-describedby="button-addon1"
                                        />
                                        <button
                                            className="btn btn-outline-secondary"
                                            type="button"
                                            id="button-addon1"
                                            onClick={() => addCertificate(category)}
                                        >
                                            +
                                        </button>
                                    </div>
                                    <button
                                        onClick={(event) => {
                                            if (token || auth !== 'false') {
                                                addShopCart(category.id, category.inCart, count, event);
                                            } else {
                                                window.location.replace("/#/login");
                                            }
                                        }}
                                        type="button"
                                        disabled={!category.remain}
                                        className={
                                            category.inCart
                                                ? "btn btn-success buy"
                                                : "btn btn-primary buy"
                                        }
                                    >
                                        {category.inCart ? t("cart.inCart") : t("cart.addCart")}
                                    </button>
                                </div>
                            </div>
                        ))}

                    {!loading && !products?.length && (
                        <div className="empty">
                            {" "}
                            <h1>{t("emptyPage")}</h1>
                        </div>
                    )}
                </div>
                {totalPages && (
                    <nav aria-label="Page navigation example">
                        <ul className="pagination">
                            <li className="page-item">
                                <button
                                    className="page-link"
                                    disabled={currentPage === 1}
                                    aria-label="Previous"
                                    onClick={() => paginate(currentPage - 1)}
                                >
                                    <span aria-hidden="true">&laquo;</span>
                                </button>
                            </li>
                            {products &&
                                Array.from(Array(totalPages)).map((page, i) => (
                                    <li className="page-item" key={i}>
                                        <button
                                            className="page-link"
                                            style={
                                                currentPage === i + 1
                                                    ? {backgroundColor: "blue", color: "white"}
                                                    : {}
                                            }
                                            onClick={() => paginate(i + 1)}
                                        >
                                            {i + 1}
                                        </button>
                                    </li>
                                ))}
                            <li className="page-item">
                                <button
                                    className="page-link"
                                    aria-label="Next"
                                    disabled={currentPage === totalPages}
                                    onClick={() => paginate(currentPage + 1)}
                                >
                                    <span aria-hidden="true">&raquo;</span>
                                </button>
                            </li>
                        </ul>
                    </nav>
                )}
            </div>
        </>
    );
}
