import React, { useState, useEffect } from "react";
import Header from "../../components/Header";
import "./CreateProduct.scss";
import axios from "axios";
import API from "../../api/apiController";
import { useNavigate, useLocation } from "react-router-dom";

export default function CreateProduct() {
  const { state } = useLocation();
  const [id, setId] = useState(null);
  const [notification, setNotification] = React.useState("");
  const [errMsg, setErrMsg] = React.useState("");
  const [nameKz, setNameKz] = useState("");
  const [nameRu, setNameRu] = useState("");
  const [nameEn, setNameEn] = useState("");
  const [descriptionKz, setDescriptionKz] = useState("");
  const [descriptionRu, setDescriptionRu] = useState("");
  const [descriptionEn, setDescriptionEn] = useState("");
  const [price, setPrice] = useState(null);
  const [maxCertificateNumber, setMaxCertificateNumber] = useState(null);
  const [userLimit, setUserLimit] = useState(null);
  const [drawDate, setDrawDate] = useState(null);
  const [activationDate, setActivationDate] = useState(null);
  const [firstPhoto, setFirstPhoto] = useState(null);
  const [secondPhoto, setSecondPhoto] = useState(null);
  const [thirdPhoto, setThirdPhoto] = useState(null);
  const [logoPhoto, setLogoPhoto] = useState(null);
  const navigate = useNavigate();
  const token = localStorage.getItem("token");
  const baseURL = "https://backend.1-q.kz/";

  useEffect(() => {
    console.log(state);
    fetchData()

    console.log(state?.categoryId);
  }, []);

  const fetchData = async () => {
    if (state?.product) {
      
      let params = {
        id: state?.product.id,
      };
      await API.user.getDetailsProduct(params).then((response) => {
        let product = response.data.data;
        setId(product.id);
        setNameKz(product.nameKz);
        setNameRu(product.nameRu);
        setNameEn(product.nameEn);
        setDescriptionRu(product.descriptionRu);
        setDescriptionKz(product.descriptionKz);
        setDescriptionEn(product.descriptionEn);
        setMaxCertificateNumber(product.maxCertificateNumber);
        setUserLimit(product.userLimit);
        setPrice(product.price);
        setActivationDate(product.activationDate);
        setDrawDate(product.drawDate);
        setFirstPhoto(product.firstPhoto);
        setSecondPhoto(product.secondPhoto);
        setThirdPhoto(product.thirdPhoto);
        setLogoPhoto(product.logo)
      });

    }
  };

  const handleChangeImage = async (e, type) => {
    console.log(e, type);
    console.log(state);
    let reader = new FileReader();
    reader.readAsDataURL(e.target.files[0]);
    const imgElement = document.createElement("img");
    reader.onload = function () {
      switch (type) {
        case "firstPhoto":
          imgElement.src = reader.result;
          imgElement.onload = function (e) {
            const canvas = document.createElement("canvas");
            const MAX_WIDTH = 400;

            const scaleSize = MAX_WIDTH / e.target.width;
            canvas.width = MAX_WIDTH;
            canvas.height = e.target.height * scaleSize;

            const ctx = canvas.getContext("2d");

            ctx.drawImage(e.target, 0, 0, canvas.width, canvas.height);

            const srcEncoded = ctx.canvas.toDataURL(e.target, "image/jpeg").replace(
              /^data:image\/(png|jpg|jpeg|jpeg);base64,/,
              ""
            );
            setLogoPhoto(srcEncoded)
            setFirstPhoto(
              srcEncoded
            );
          };

          break;
        case "secondPhoto":
          imgElement.src = reader.result;
          imgElement.onload = function (e) {
            const canvas = document.createElement("canvas");
            const MAX_WIDTH = 400;

            const scaleSize = MAX_WIDTH / e.target.width;
            canvas.width = MAX_WIDTH;
            canvas.height = e.target.height * scaleSize;

            const ctx = canvas.getContext("2d");

            ctx.drawImage(e.target, 0, 0, canvas.width, canvas.height);

            const srcEncoded = ctx.canvas.toDataURL(e.target, "image/jpeg").replace(
              /^data:image\/(png|jpg|jpeg|jpeg);base64,/,
              ""
            );
            setSecondPhoto(
              srcEncoded
            );
          };

          break;
        case "thirdPhoto":
          imgElement.src = reader.result;
          imgElement.onload = function (e) {
            const canvas = document.createElement("canvas");
            const MAX_WIDTH = 400;

            const scaleSize = MAX_WIDTH / e.target.width;
            canvas.width = MAX_WIDTH;
            canvas.height = e.target.height * scaleSize;

            const ctx = canvas.getContext("2d");

            ctx.drawImage(e.target, 0, 0, canvas.width, canvas.height);

            const srcEncoded = ctx.canvas.toDataURL(e.target, "image/jpeg").replace(
              /^data:image\/(png|jpg|jpeg|jpeg);base64,/,
              ""
            );
            setThirdPhoto(
              srcEncoded
            );
          };

          break;
        default:
          console.log(e);
      }
    };
  };

  const setPriceValidate = (num) => {
    console.log(num)
    if (num >= 0) {
      setPrice(num)}
  }

  const setValidateUserLimit = (num) => {
    const min = 1;
    const max = 1;
    const value = Math.max(min, Math.min(max, Number(num)));
    setUserLimit(value);
  }

  const create = (e) => {
    e.preventDefault();
    let newActvtDate = new Date(activationDate);
    let newDrwDate = new Date(drawDate);

    let actvtDate = new Date(
      newActvtDate.getTime() - newActvtDate.getTimezoneOffset() * 60000
    ).toISOString();
    let drwDate = new Date(
      newDrwDate.getTime() - newDrwDate.getTimezoneOffset() * 60000
    ).toISOString();

    const params = {
      id: id,
      categoryId: state?.id,
      nameEn: nameEn,
      nameKz: nameKz,
      nameRu: nameRu,
      descriptionKz: descriptionKz,
      descriptionRu: descriptionRu,
      descriptionEn: descriptionEn,
      price: price,
      activationDate: actvtDate,
      drawDate: drwDate,
      maxCertificateNumber: maxCertificateNumber,
      userLimit: userLimit,
      firstPhoto: firstPhoto,
      secondPhoto: secondPhoto,
      thirdPhoto: thirdPhoto,
      logoPhoto: logoPhoto ? logoPhoto : firstPhoto,
    };

    if (userLimit > maxCertificateNumber) {
      setErrMsg("error");
      setTimeout(() => {
        setErrMsg("");
      }, 5000);
    } else {
      axios
        .post(`${baseURL}product/save`, params, {
          headers: {
            "Content-Type": "application/json;charset=UTF-8",
            Authorization: "Bearer " + token,
          },
        })
        .then((res) => {
          console.log(res.data.data);
          navigate(-1);
        })
        .catch((err) => {
          setNotification("error");
          setTimeout(() => {
            setNotification("");
          }, 5000);
        });
    }
    console.log(params);
  };
  return (
    <>
      {notification === "error" && (
        <div class="myAlert-top alert alert-danger">
          <strong>Заполните все поля!</strong>
        </div>
      )}
      {errMsg === "error" && (
        <div class="myAlert-top alert alert-danger">
          <strong>Максимальное количество для пользователя не может быть больше чем максимальное количество сертификатов!</strong>
        </div>
      )}
      <Header />
      <div className="container catalog">
        <form>
          <div class="mb-3">
            <label class="form-label">Название продукта на казахском</label>
            <input
              type="text"
              class="form-control"
              value={nameKz}
              onChange={(event) => setNameKz(event.target.value)}
            />
          </div>
          <div class="mb-3">
            <label class="form-label">Название продукта на русском</label>
            <input
              type="text"
              class="form-control"
              value={nameRu}
              onChange={(event) => setNameRu(event.target.value)}
            />
          </div>
          <div class="mb-3">
            <label class="form-label">Название продукта на английском</label>
            <input
              type="text"
              class="form-control"
              value={nameEn}
              onChange={(event) => setNameEn(event.target.value)}
            />
          </div>
          <div class="mb-3">
            <label class="form-label">Описание продукта на казахском</label>
            <textarea
              type="text"
              rows="4"
              class="form-control"
              value={descriptionKz}
              onChange={(event) => setDescriptionKz(event.target.value)}
            />
          </div>
          <div class="mb-3">
            <label class="form-label">Описание продукта на русском</label>
            <textarea
              type="text"
              rows="4"
              class="form-control"
              value={descriptionRu}
              onChange={(event) => setDescriptionRu(event.target.value)}
            />
          </div>
          <div class="mb-3">
            <label class="form-label">Описание продукта на английском</label>
            <textarea
              type="text"
              rows="4"
              class="form-control"
              value={descriptionEn}
              onChange={(event) => setDescriptionEn(event.target.value)}
            />
          </div>
          <div class="mb-3">
            <label class="form-label">Максимальное количество </label>
            <input
              type="number"
              class="form-control"
              value={maxCertificateNumber}
              onChange={(event) => setMaxCertificateNumber(event.target.value)}
            />
          </div>
          <div class="mb-3">
            <label class="form-label">
              Максимальное количество для пользователя
            </label>
            <input
              type="number"
              class="form-control"
              value={userLimit}
              onChange={(event) => setValidateUserLimit(event.target.value)}
            />
          </div>
          <div class="mb-3">
            <label class="form-label">Дата активации</label>
            <input
              type="datetime-local"
              id="party"
              name="partydate"
              class="form-control"
              value={activationDate}
              onChange={(event) => setActivationDate(event.target.value)}
            />
          </div>
          <div class="mb-3">
            <label class="form-label">Дата розыгрыша</label>
            <input
              type="datetime-local"
              id="party"
              name="partydate"
              class="form-control"
              value={drawDate}
              onChange={(event) => setDrawDate(event.target.value)}
            />
          </div>
          <div class="mb-3">
            <label class="form-label">Стоимость</label>
            <input
              type="number"
              class="form-control"
              value={price}
              onChange={(event) => setPriceValidate(event.target.value)}
            />
          </div>
          <div class="row">
            <div class="mb-3 col">
              <label fclass="form-label">Первое фото</label>
              <input
                type="file"
                className="w-100"
                onChange={(event) => handleChangeImage(event, "firstPhoto")}
              />
              {firstPhoto && (
                <img
                  className="img-fluid img-thumbnail"
                  src={`data:image/jpeg;base64,${firstPhoto}`}
                  alt="img"
                />
              )}
            </div>

            <div class="mb-3 col">
              <label fclass="form-label">Второе фото</label>
              <input
                type="file"
                className="w-100"
                onChange={(event) => handleChangeImage(event, "secondPhoto")}
              />
              {secondPhoto && (
                <img
                  className="img-fluid img-thumbnail"
                  src={`data:image/jpeg;base64,${secondPhoto}`}
                  alt="img"
                />
              )}
            </div>
            <div class="mb-3 col">
              <label fclass="form-label">Третье фото</label>
              <input
                type="file"
                className="w-100"
                onChange={(event) => handleChangeImage(event, "thirdPhoto")}
              />
              {thirdPhoto && (
                <img
                  className="img-img-fluid img-thumbnail"
                  src={`data:image/jpeg;base64,${thirdPhoto}`}
                  alt="img"
                />
              )}
            </div>
          </div>
          <button onClick={create} class="btn btn-primary">
            Сохранить
          </button>
        </form>
      </div>
    </>
  );
}
