import React, { useEffect, useState } from "react";
import Header from "../../components/Header";
import axios from "axios";
import "./AdminProduct.scss";
import API from "../../api/apiController";
import Loader from "../../components/Loader";
import { Modal, Button } from "react-bootstrap";
import { useNavigate, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

export default function AdminProduct() {
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const token = localStorage.getItem("token");
  const [products, setProducts] = useState([]);
  const [notification, setNotification] = React.useState("");
  const [currentProduct, setCurrentProduct] = useState([]);
  const [inActive, setInActive] = useState(false);
  const navigate = useNavigate();
  const { state } = useLocation();
  const [photoArr, setPhotoArr] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize] = useState(5);
  const [totalPages, setTotalPages] = useState(0);
  const baseURL = "https://backend.1-q.kz/";
  useEffect(() => {
    getProducts(false, currentPage-1);
  }, [state]);
  const [show, setShow] = useState(false);
  const getProducts = async (disabled, page) => {
    setProducts([])
    setLoading(true);

    let params = {
      categoryId: state?.id,
      active: disabled ? false : true
    }
    setCurrentPage(page);
    const paginationParams = {
      pageNumber: page ? page - 1 : currentPage - 1,
      pageSize: 10,
    };
    API.shop.getProducts(params,paginationParams).then(res => {
      setTotalPages(res.data.data.totalPages);
      getProductsPhoto(res.data.data.data.data)
      setProducts(res.data.data.data.data)
      setLoading(false)
    });
  };

  const getProductsPhoto = (products) => {
    console.log('response.data.data.infoList', products)
    products.map(product => {
      const params = {
        id: product.id,
      }
      let arr = []
      API.shop.getProductsPhoto(params)
      .then((res) => {
        const obj = {
          id:product.id,
          photo: res.data.data
        }
        arr.push(obj)
        setPhotoArr(arr)
        product.logoPhoto = res.data.data;
      });
    })
  };

  const seeActiveProduct = async () => {
    setInActive(!inActive);
    if (!inActive) {
      await getProducts(true,1);
    } else {
      await getProducts(false,1);
    }
  };

  const handleClose = () => {
    setShow(false);
    setCurrentProduct(null);
  };
  const handleShow = (item) => {
    console.log(item);
    setCurrentProduct(item);
    setShow(true);
  };
  const deleteProduct = async (id) => {
    console.log(id);
    console.log(token);
    await axios
      .get(`${baseURL}shop/product/delete`, {
        params: {
          productId: id,
        },
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          Authorization: "Bearer " + token,
        },
      })
      .then(async (res) => {
        console.log(res.data.data);
        setCurrentProduct(null);
        setShow(false);
        setShow(false);
        setNotification("success");
        setTimeout(() => {
          setNotification("");
        }, 5000);
        await getProducts(currentPage);
      })
      .catch((err) => {
        setShow(false);
        setNotification("error");
        setTimeout(() => {
          setNotification("");
        }, 5000);
      });
  };

  const formatPrice = (price) => {
    let formattedPrice = new Intl.NumberFormat().format(price);
    return formattedPrice.replaceAll(",", " ");
  };
  return (
    <>
      {notification === "success" && (
        <div class="myAlert-top alert alert-success">
          <strong>Товар удален!</strong>
        </div>
      )}
      {notification === "error" && (
        <div class="myAlert-top alert alert-danger">
          <strong>Этот товар нельзя удалить!</strong>
        </div>
      )}
      <Header />
      <div className="container">
        <h1>Товары и Услуги</h1>
        <div className="category__btn">
          <button
            onClick={() => {
              navigate("/admin/create-product", { state: { id: state?.id } });
            }}
            type="button"
            className="btn btn-success"
          >
            Создать товар
          </button>
          <button
            onClick={() => {
              navigate('/admin/catalogs',{
                state: { type: state?.type, id: state?.backId },
              });
            }}
            type="button"
            className="btn btn-primary"
          >
            {t("returnBack")}
          </button>
        </div>
        {loading && <Loader />}
        <div class="form-check history__active">
          <input
            class="form-check-input big-checkbox"
            type="checkbox"
            value={inActive}
            onChange={() => { seeActiveProduct() }}
            id="flexCheckIndeterminate"
          />
          <label class="form-check-label" for="flexCheckIndeterminate">
            <p className="history__check-title">Показать не активные товары</p>
          </label>
        </div>
        {products.map((category) => (
          <div className="card category">
            <div className="card-body category__item">
              <div className="category__info">
                <img
                  src={category.logoPhoto ? `data:image/jpeg;base64,${category.logoPhoto}` : require('../../assets/notPhoto.jpg')}
                  className="card-img-top category__img"
                  alt="..."
                />
                <div>
                  <h3 className="card-title">{category.nameRu}</h3>
                  <h4>Стоимость:  {formatPrice(category.price)} тенге</h4>
                  <h4>Количество : {category.count}</h4>
                </div>
              </div>

              <div className="category__edit">
                <button
                  onClick={() =>
                    navigate("/admin/create-product", {
                      state: { id: state?.id, product: category },
                    })
                  }
                  type="button"
                  className="btn btn-primary"
                >
                  Редактировать
                </button>
                <>
                  <Button
                    variant="btn btn-danger"
                    onClick={() => handleShow(category)}
                  >
                    Удалить
                  </Button>

                  <Modal show={show} onHide={handleClose}>
                    <Modal.Header closeButton>
                      <Modal.Title>Удаление товара</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      Удалить продукт {currentProduct?.nameRu} ?
                    </Modal.Body>
                    <Modal.Footer>
                      <Button variant="secondary" onClick={handleClose}>
                        Отмена
                      </Button>
                      <Button
                        variant="btn btn-danger"
                        onClick={() => {
                          deleteProduct(currentProduct.id);
                        }}
                      >
                        Удалить
                      </Button>
                    </Modal.Footer>
                  </Modal>
                </>
              </div>
            </div>
          </div>
        ))}
        {!loading && !products?.length && (
          <div className="empty">
            {" "}
            <h1>Пустая страница</h1>
          </div>
        )}
              {totalPages && (
          <nav aria-label="Page navigation example">
            <ul class="pagination">
              <li class="page-item">
                <button
                  class="page-link"
                  disabled={currentPage === 1}
                  aria-label="Previous"
                  onClick={() => getProducts(inActive,currentPage - 1)}
                >
                  <span aria-hidden="true">&laquo;</span>
                </button>
              </li>
              {products &&
                Array.from(Array(totalPages)).map((page, i) => (
                  <li class="page-item">
                    <button
                      class="page-link"
                      style={
                        currentPage === i + 1
                          ? { backgroundColor: "blue", color: "white" }
                          : {}
                      }
                      onClick={() => getProducts(inActive,i + 1)}
                    >
                      {i + 1}
                    </button>
                  </li>
                ))}
              <li class="page-item">
                <button
                  class="page-link"
                  aria-label="Next"
                  disabled={currentPage === totalPages}
                  onClick={() => getProducts(inActive, currentPage + 1)}
                >
                  <span aria-hidden="true">&raquo;</span>
                </button>
              </li>
            </ul>
          </nav>
        )}
      </div>

    </>
  );
}
