import axios from 'axios';
// import jwt_decode from "jwt-decode";
// import dayjs from 'dayjs'
// import { useLocation, Navigate } from 'react-router-dom';

const baseURL = 'https://backend.1-q.kz/';

// let authToken = localStorage.getItem('token') ? localStorage.getItem('token') : '';
// let refreshToken = localStorage.getItem('refreshToken') ? localStorage.getItem('refreshToken') : null;


const axiosInstance = axios.create({
  baseURL,
})

axiosInstance.interceptors.response.use((response) => {
  return response
}, async (error) => {
  const originalRequest = error.config;
  if (error.response.status === 401 && error.config && !error.config._isRetry) {
    originalRequest._isRetry = true;
    try {
      console.log("redirect log")
      localStorage.setItem('auth', "false")
      return axiosInstance.request(originalRequest)
    } catch(e) {
      console.log("Не авторизован")
    }
  }
  throw error;
});

// axiosInstance.interceptors.request.use(async req => {
//   if (!authToken) {
//     authToken = localStorage.getItem('token') ? localStorage.getItem('token') : null
//     req.headers.Authorization = `Bearer ${authToken}`
//   }
//   console.log('token', authToken)
//   const user = authToken ? jwt_decode(authToken) : null
//   const isExpired = user ? dayjs.unix(user?.exp).diff(dayjs()) < 1 : true;
//   console.log('req', req)
//   if (!req) {
//     return req
//   } else {
//     localStorage.clear();
//     return window.location.replace('/#/login');
//   }
// })

export default axiosInstance;