import './Loader.scss'

export default function Loader() {
    return (
        <div className="overlay-layer card-rounded  bg-opacity-5 text-center overlay">
            <div className="spinner-border text-primary" role="status">
                <span className="visually-hidden">Loading...</span>
            </div>
        </div>
    );
}
