import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import kz from "../locale/kz.json"
import ru from "../locale/ru.json"


const lang = JSON.stringify(localStorage.getItem("lang"));
console.log(lang)
if (lang === "Ru" && lang === "Kz") {
}

i18n.use(initReactI18next).init({
  compatibilityJSON: "v3",
  resources: {
    Kz: {
      translation: kz,
    },
    Ru: {
      translation: ru,
    },
  },
  lng: lang === "Ru" && lang === "Kz" && lang === "En" ? lang : "Ru",
});

export default i18n;
