import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import Header from "../../components/Header";
import "./CreateNotification.scss";
export default function CreateNotification() {
  const navigate = useNavigate();
  const { state } = useLocation();
  const token = localStorage.getItem("token");
  const baseURL = "https://backend.1-q.kz/";

  const [notification, setNotification] = React.useState("");
  const [imgKz, setImgKz] = useState(null);
  const [imgRu, setImgRu] = useState(null);
  const [id, setId] = useState(null);
  const [nameKz, setNameKz] = useState("");
  const [nameRu, setNameRu] = useState("");
  const [textKz, setTextKz] = useState("");
  const [textRu, setTextRu] = useState("");
  
  useEffect(() => {
    if (state?.push) {
      let {push} = state;
      setNameKz(push.titleKz);
      setNameRu(push.titleRu);
      setTextKz(push.bodyKz);
      setTextRu(push.bodyRu);
      setImgKz(push.photoKz);
      setImgRu(push.photoRu);
      setId(push.id);
    }
  }, []);
  const handleChangeImageKz = async (e) => {
    let reader = new FileReader();
    reader.readAsDataURL(e.target.files[0]);
    reader.onload = function () {
      const imgElement = document.createElement("img");
      imgElement.src = reader.result;
      imgElement.onload = function (e) {
        const canvas = document.createElement("canvas");
        const MAX_WIDTH = 400;
        const scaleSize = MAX_WIDTH / e.target.width;
        canvas.width = MAX_WIDTH;
        canvas.height = e.target.height * scaleSize;
        const ctx = canvas.getContext("2d");
        ctx.drawImage(e.target, 0, 0, canvas.width, canvas.height);
        const srcEncoded = ctx.canvas.toDataURL(e.target, "image/jpeg").replace(
          /^data:image\/(png|jpg|jpeg|jpeg);base64,/,
          ""
        );
        setImgKz(srcEncoded)
      };
    };
  };
  const handleChangeImageRu = async (e) => {
    let reader = new FileReader();
    reader.readAsDataURL(e.target.files[0]);
    reader.onload = function () {
      const imgElement = document.createElement("img");
      imgElement.src = reader.result;
      imgElement.onload = function (e) {
        const canvas = document.createElement("canvas");
        const MAX_WIDTH = 400;
        const scaleSize = MAX_WIDTH / e.target.width;
        canvas.width = MAX_WIDTH;
        canvas.height = e.target.height * scaleSize;
        const ctx = canvas.getContext("2d");
        ctx.drawImage(e.target, 0, 0, canvas.width, canvas.height);
        const srcEncoded = ctx.canvas.toDataURL(e.target, "image/jpeg").replace(
            /^data:image\/(png|jpg|jpeg|jpeg);base64,/,
            ""
        );
        setImgRu(srcEncoded)
      };
    };
  };

  const create = (e) => {
    e.preventDefault();
    const params = {
      id: id,
      titleKz: nameKz,
      titleRu: nameRu,
      bodyKz: textKz,
      bodyRu: textRu,
      photoKz: imgKz,
      photoRu: imgRu,
    };
    axios
      .post(`${baseURL}push-notification/save`, params, {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          Authorization: "Bearer " + token,
        },
      })
      .then((res) => {
        navigate("/admin/notifications");
      })
      .catch((err) => {
        setNotification("error");
        setTimeout(() => {
          setNotification("");
        }, 5000);
      });
  };

  return (
    <>
      {notification === "error" && (
        <div className="myAlert-top alert alert-danger">
          <strong>Заполните все поля!</strong>
        </div>
      )}
      <Header />
      <div className="container catalog">
        <form>
          <div className="mb-3">
            <label className="form-label">Заголовок уведомления (KAZ)</label>
            <input
              type="text"
              className="form-control"
              value={nameKz}
              onChange={(event) => setNameKz(event.target.value)}
            />
          </div>
          <div className="mb-3">
            <label className="form-label">Текст уведомления (KAZ)</label>
            <input
              type="text"
              className="form-control"
              value={textKz}
              onChange={(event) => setTextKz(event.target.value)}
            />
          </div>
          <div className="mb-3">
            <label fclass="form-label">Фото (KAZ)</label>
            <input type="file" className="w-100" onChange={handleChangeImageKz}/>
            {imgKz && (
                <img
                    className="catalog__img"
                    src={`data:image/jpeg;base64,${imgKz}`}
                    alt="imgKz"
                />
            )}
          </div>

          <div className="mb-3">
            <label className="form-label">Заголовок уведомления (RUS)</label>
            <input
                type="text"
                className="form-control"
                value={nameRu}
                onChange={(event) => setNameRu(event.target.value)}
            />
          </div>
          <div className="mb-3">
            <label className="form-label">Текст уведомления (RUS)</label>
            <input
                type="text"
                className="form-control"
                value={textRu}
                onChange={(event) => setTextRu(event.target.value)}
            />
          </div>
          <div className="mb-3">
            <label className="form-label">Фото (RUS)</label>
            <input type="file" className="w-100" onChange={handleChangeImageRu}/>
            {imgRu && (
                <img
                    className="catalog__img"
                    src={`data:image/jpeg;base64,${imgRu}`}
                    alt="imgRu"
                />
            )}
          </div>

          <button onClick={create} className="btn btn-primary">
            Сохранить
          </button>
        </form>
      </div>
    </>
  );
}
