import React from "react";
import {useNavigate, useLocation} from 'react-router-dom';
import "./Pagination.scss";

const Pagination = ({usersPerPage, totalUsers, paginate}) => {
    const navigate = useNavigate();
    const location = useLocation();
    const [active, setActive] = React.useState("");
    const [activePage, setActivePage] = React.useState("");
    const pageNumbers = [];

    for (let i = 1; i <= Math.ceil(totalUsers / usersPerPage); i++) {
        pageNumbers.push(i);
    }

    React.useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        setActivePage(searchParams.get("page"))
    }, [active, activePage])

    React.useEffect(() => {
        const bottomElement = document.querySelector('.pagination');
        if (bottomElement) {
            bottomElement.scrollIntoView({behavior: 'smooth'});
            setTimeout(() => {
                const element = document.querySelector('.page-link__active');
                if (element) {
                    element.scrollIntoView({behavior: 'smooth', block: 'nearest', inline: 'start'});
                }
            }, 1000);
        }
    }, []);

    const selectPage = (number) => {
        setActive(number)
        paginate(number)
        const searchParams = new URLSearchParams(location.search);
        searchParams.set("page", number);
        navigate({pathname: location.pathname, search: searchParams.toString()});
    }

    return (
        <nav className="pagination-wrapper">
            <ul className="pagination">
                {pageNumbers.length > 0 && pageNumbers.map((number) => (
                    <li key={number} className="page-item">
                        <button
                            onClick={() => selectPage(number)}
                            className={`page-link page-link--long ${+activePage === number ? 'page-link__active' : ''}`}
                        >
                            {number}
                        </button>
                    </li>
                ))}
            </ul>
        </nav>
    );
};

export default Pagination;
