import { useTranslation } from "react-i18next";


 const  Declination = (number) => {
    const { t } = useTranslation();
    let n = Math.abs(number);
    n %= 100;
    if (n >= 5 && n <= 20) {
      return t("points.ballov");
    }
    n %= 10;
    if (n === 1) {
      return t("points.ball");
    }
    if (n >= 2 && n <= 4) {
      return t("points.balla");
    }
    return t("points.ballov");
  };

  export  {Declination};